// Components
import Input from "../input/Input";
import SelectInput from "../select-input/SelectInput";
import AutoComplete from "../auto-complete/AutoComplete";
import BootstrapDateRangePicker from "../bootstrap-date-range-picker/BootstrapDateRangePicker";

// Component constnats
import { booleanSelectOptions, datePickerStyleObject, inputType } from "./FormConstants";
import Textarea from "../textarea/Textarea";

export default function FormInput({
  input = {},
  formik,
  isReadMode = false,
  showError = true,
  isTabularForm = false,
  isFormDisabled = false,
}) {
  // Formik
  const { values, handleBlur, errors, touched } = formik;

  // Input
  const {
    key,
    disabled = false,
    inputClassName = "",
    type = "text",
    placeholder,
    options = [],
    datePickerOptions = {},
  } = input;

  // If its add form it will show placeholder, otherwise it will check in the data for value, if value is not there, it will give "--"
  const placeholderText = isTabularForm ? "" : isReadMode ? "--" : placeholder;

  // Error
  const error = showError ? touched[key] && errors[key] : "";

  // Class name for inputs
  const className = isTabularForm ? `${inputClassName} form-control-sm form-select-sm border-0` : inputClassName;

  const isInputDisabled = isFormDisabled || isReadMode || disabled;

  // Onchange Function
  function onChange(e) {
    const { name, value } = e.target;

    // Ensure the value is always treated as a string
    formik.setFieldValue(name, value);
  }

  // If type is select, it will render select component
  // If type is boolean select, options are taken form boolean select options constant
  if (type === inputType.select || type === inputType.booleanSelect) {
    return (
      <SelectInput
        className={className}
        name={key}
        value={values[key]}
        placeholder={placeholderText}
        onChange={onChange}
        onBlur={handleBlur}
        options={type === inputType.booleanSelect ? booleanSelectOptions : options}
        isEdit={isReadMode}
        disabled={isInputDisabled}
        error={error}
      />
    );
  }

  // If type is data list, it will render data list component
  if (type === inputType.autoComplete) {
    return (
      <AutoComplete
        id={key}
        className={`form-control shadow-none ${className}`}
        name={key}
        value={values[key]}
        options={options}
        onChange={onChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        error={error}
        disabled={isInputDisabled}
        isEdit={isReadMode}
      />
    );
  }

  // If type is date, it will render bootstrap date range picker
  if (type === inputType.date) {
    // Getting Date Options
    const { initialStartDate = "", initialEndDate = new Date().getTime() } = datePickerOptions;

    return (
      <BootstrapDateRangePicker
        id={key}
        onBlur={handleBlur}
        disabled={isInputDisabled}
        isEdit={isReadMode}
        error={error}
        initialStartDate={initialStartDate}
        initialEndDate={initialEndDate}
        onApplyDates={onChange}
        styleObject={datePickerStyleObject}
        isSingleDatePicker={true}
      />
    );
  }

  // If type is text-area, it will render text area
  if (type === inputType.textarea) {
    return (
      <Textarea
        className={`shadow-none ${className}`}
        name={key}
        type={type}
        value={values[key] || ""}
        onChange={onChange}
        onBlur={handleBlur}
        error={error}
        placeholder={placeholderText}
        disabled={isInputDisabled}
        title={values[key]}
      />
    );
  }

  // If type is other than select, then it will render input component
  return (
    // Input
    <Input
      className={`shadow-none ${className}`}
      name={key}
      type={type}
      value={values[key] || ""}
      onChange={onChange}
      onBlur={handleBlur}
      error={error}
      placeholder={placeholderText}
      disabled={isInputDisabled}
      title={values[key]}
    />
  );
}
