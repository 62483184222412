import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantComponentListActions } from "../../redux-slice/plant/ComponentListSlice";
import { PlantComponentActions } from "../../redux-slice/plant/ComponentSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Utils
import { saveFile } from "../../utils/FileUtils";

// APIs
import {
  addPlantComponentByIdApi,
  decommissionComponentApi,
  downloadPlantComponentImageByIdApi,
  updatePlantComponentByIdApi,
  uploadPlantComponentImageByIdApi,
} from "../../api/plant/ComponentAPI";

import { getPlantComponentListApi } from "../../api/plant/ComponentListAPI";

// Add Plant Component By Id
function* addPlantComponentById(action) {
  try {
    const { componentData, plantId, componentTypeId, pageNumber, pageSize = 100 } = action.payload;

    yield addPlantComponentByIdApi(componentData, plantId);

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));

    yield put(PlantComponentActions.addPlantComponentSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.addPlantComponentFailure({ error }));
  }
}

// Update Plant Component By Id
function* updatePlantComponentById(action) {
  try {
    const { componentData, componentId, plantId, pageNumber, pageSize = 100 } = action.payload;
    const { componentTypeId } = componentData;

    yield updatePlantComponentByIdApi(componentData, componentId, plantId);

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));

    yield put(PlantComponentActions.updatePlantComponentSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.updatePlantComponentFailure({ error }));
  }
}

// Upload Plant Component Image By Id
function* uploadPlantComponentImageById(action) {
  try {
    const { formData, componentTypeId, componentId, plantId } = action.payload;

    yield uploadPlantComponentImageByIdApi(formData, plantId, componentId);

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId);

    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));

    yield put(PlantComponentActions.uploadPlantComponentImageSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.uploadPlantComponentImageFailure({ error }));
  }
}

// Download Plant Component Image By Id
function* downloadPlantComponentImageById(action) {
  const { componentId = "", name = "", apiUrl = "" } = action.payload;

  try {
    const data = yield downloadPlantComponentImageByIdApi(apiUrl);

    // Save Image
    saveFile(name, data);

    // Dispatching Action
    yield put(PlantComponentActions.downloadPlantComponentImageSuccess({ componentId }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.downloadPlantComponentImageFailure({ error, componentId }));
  }
}
// Decommission Component
function* decommissionComponent(action) {
  const { componentId = "", plantId = "", componentTypeId = "", pageNumber = "", pageSize = 100 } = action.payload;

  try {
    yield decommissionComponentApi(plantId, componentId);

    const plantComponentList = yield getPlantComponentListApi(plantId, componentTypeId, pageNumber, pageSize);

    yield put(PlantComponentListActions.getPlantComponentListSuccess({ plantComponentList }));

    yield put(PlantComponentActions.decommissionComponentSuccess({ componentId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantComponentActions.decommissionComponentFailure({ error, componentId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantComponentActions.addPlantComponent.type, addPlantComponentById),
    takeLatest(PlantComponentActions.updatePlantComponent.type, updatePlantComponentById),
    takeLatest(PlantComponentActions.uploadPlantComponentImage.type, uploadPlantComponentImageById),
    takeLatest(PlantComponentActions.downloadPlantComponentImage.type, downloadPlantComponentImageById),
    takeLatest(PlantComponentActions.decommissionComponent.type, decommissionComponent),
  ]);
}
