import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Actions
import { AuditComponentActions } from "../../../../redux-slice/audit/ComponentSlice";

// Constants
import { ValueTypes } from "../../../../constants/GeneralConstants";

// Utils
import { getFormattedDateTime } from "../../../../utils/DateUtils";

// CSS
import "./report.scss";

// Sections
import AbbreviationsPage from "./report-components/AbbreviationsPage";
import AuditorsInfoPage from "./report-components/AuditorsInfoPage";
import ComponentData from "./report-components/ComponentData";
import MainPage from "./report-components/MainPage";
import ReportInfoPage from "./report-components/ReportInfoPage";
import StaticContent from "./report-components/StaticContent";
import ThermalBillListTable from "./report-components/ThermalBillListTable";
import ElectricityBillListTable from "./report-components/ElectricityBillListTable";

// Page Constants
const DATE_FORMAT = "Do MMMM YYYY";

/**
 * Report
 */
export default function Report() {
  const dispatch = useDispatch();

  // Report Data Selector State
  const reportData = useSelector((state) => state.auditReports.reportData);

  // Report Data
  const { plantInfo = {}, thermalBills = [], powerBills = [], auditInfo = {}, components = [] } = reportData || {};

  // Audit Information
  const { auditors = [], auditOrgName = "", startTimestamp = "" } = auditInfo;

  // Audit Duration
  const auditStartedDate = getFormattedDateTime(ValueTypes.DURATION, startTimestamp, DATE_FORMAT);

  // Adding 5 days to the audit started timestamp
  const endTimeStamp = moment(startTimestamp).add(5, "days"); // TODO
  const auditEndDate = getFormattedDateTime(ValueTypes.DURATION, endTimeStamp, DATE_FORMAT);

  useEffect(() => {
    return () => dispatch(AuditComponentActions.resetAllApiSuccessState());
  }, [dispatch]);

  return (
    <div id="report" className="border border-1 shadow-lg">
      {/* Main Page */}
      <MainPage partnerName={auditOrgName} />

      {/* Report Info Page*/}
      <ReportInfoPage
        auditors={auditors}
        plantInfo={plantInfo}
        reportNo={"EAR 2024-4"} // TODO
        fromDate={auditStartedDate}
        toDate={auditEndDate}
        reportDate={"18/04/2024"} // TODO
      />

      {/* Static Content */}
      <StaticContent />

      {/* Abbreviations Page */}
      <AbbreviationsPage />

      {/* Auditors Info Page */}
      <AuditorsInfoPage auditors={auditors} />

      {/* Component Data */}
      <ComponentData components={components} />

      {/* Thermal Bill List Table */}
      <ThermalBillListTable thermalBills={thermalBills} />

      {/* Electricity Bill List Table */}
      <ElectricityBillListTable electricityBills={powerBills} />
    </div>
  );
}
