import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.addPlantComponentSuccess = false;
  state.updatePlantComponentSuccess = false;
  state.uploadPlantComponentImageSuccess = false;
  state.decommissionComponentSuccess = {};
}

// Initial State
const initialState = {
  // Add plant component
  addPlantComponentLoading: false,
  addPlantComponentSuccess: false,
  addPlantComponentError: "",

  // Upload plant component
  updatePlantComponentLoading: false,
  updatePlantComponentSuccess: false,
  updatePlantComponentError: "",

  // Upload plant component image
  uploadPlantComponentImageLoading: false,
  uploadPlantComponentImageSuccess: false,
  uploadPlantComponentImageError: "",

  // Download plant component image
  downloadPlantComponentImageLoading: {},
  downloadPlantComponentImageError: "",

  // Decommision Component
  decommissionComponentLoading: {},
  decommissionComponentSuccess: {},
  decommissionComponentError: "",

  // Reset API Success State
  resetApiSuccessState,
};

// Plant Component Slice
const plantComponentSlice = createSlice({
  name: "PlantComponent",
  initialState,
  reducers: {
    // Add plant component
    addPlantComponent: (state) => {
      state.addPlantComponentSuccess = false;
      state.addPlantComponentLoading = true;
    },

    addPlantComponentSuccess: (state) => {
      state.addPlantComponentSuccess = true;
      state.addPlantComponentLoading = false;
    },

    addPlantComponentFailure: (state, action) => {
      state.addPlantComponentLoading = false;
      state.addPlantComponentError = action.payload.error;
    },

    // Update plant component
    updatePlantComponent: (state) => {
      state.updatePlantComponentSuccess = false;
      state.updatePlantComponentLoading = true;
    },

    updatePlantComponentSuccess: (state) => {
      state.updatePlantComponentSuccess = true;
      state.updatePlantComponentLoading = false;
    },

    updatePlantComponentFailure: (state, action) => {
      state.updatePlantComponentLoading = false;
      state.updatePlantComponentError = action.payload.error;
    },

    // Upload plant component image
    uploadPlantComponentImage: (state) => {
      state.uploadPlantComponentImageSuccess = false;
      state.uploadPlantComponentImageLoading = true;
    },

    uploadPlantComponentImageSuccess: (state) => {
      state.uploadPlantComponentImageSuccess = true;
      state.uploadPlantComponentImageLoading = false;
    },

    uploadPlantComponentImageFailure: (state, action) => {
      state.uploadPlantComponentImageLoading = false;
      state.uploadPlantComponentImageError = action.payload.error;
    },

    // Download plant component image
    downloadPlantComponentImage: (state, action) => {
      const { componentId = "" } = action.payload;
      state.downloadPlantComponentImageLoading[componentId] = true;
      state.downloadPlantComponentImageError = "";
    },

    downloadPlantComponentImageSuccess: (state, action) => {
      const { componentId = "" } = action.payload;
      delete state.downloadPlantComponentImageLoading[componentId];
    },

    downloadPlantComponentImageFailure: (state, action) => {
      const { componentId = "" } = action.payload;
      delete state.downloadPlantComponentImageLoading[componentId];
      state.downloadPlantComponentImageError = action.payload.error;
    },

    // Decommission Component
    decommissionComponent: (state, action) => {
      const { componentId = "" } = action.payload;

      state.decommissionComponentSuccess[componentId] = false;
      state.decommissionComponentLoading[componentId] = true;
    },

    decommissionComponentSuccess: (state, action) => {
      const { componentId = "" } = action.payload;

      state.decommissionComponentSuccess[componentId] = true;
      delete state.decommissionComponentLoading[componentId];
    },

    decommissionComponentFailure: (state, action) => {
      const { componentId = "", error = {} } = action.payload;

      state.decommissionComponentSuccess[componentId] = false;
      delete state.decommissionComponentLoading[componentId];
      state.decommissionComponentError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantComponentReducer = plantComponentSlice.reducer;

// Actions
export const PlantComponentActions = plantComponentSlice.actions;
