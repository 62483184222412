import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLoaderData, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditActions } from "../../redux-slice/audit/AuditSlice";
import { AuditComponentActions } from "../../redux-slice/audit/ComponentSlice";

// Constants
import { AuditLayoutSideBar, SIDEBAR_HEADER_CONSTANTS } from "../../constants/LayoutConstants";

// Utils
import { getOrgType } from "../../utils/AuthOrgUtil";
import { constructSideBarUrls } from "../../utils/LayoutUtils";
import URLs from "../../app/utils/URLs";

// URLs
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../button/Button";
import PageHeader from "../page-header/PageHeader";

// Layouts
import Layout from "../../app/layout/Layout";
import SidebarMenu from "./SidebarMenu";
import SiderbarFooter from "./SidebarFooter";

// TODO: Find the better way to configure this.
function constructBackButtonConfig(urlPathname) {
  // NOTE : At present we are adding back button config only for the Report Page
  // For ReportPage match count should be 3 and second entry will the the auditId
  const reportPageUrlPattern = /\/audit\/([^/]+)\/report\/([^/]+)/;
  const match = urlPathname.match(reportPageUrlPattern) || [];

  if (match.length === 3) {
    const auditId = match[1];
    return { redirectUrl: RedirectTo.AuditReportsListPageUrl.replace(":auditId", auditId) };
  }

  return {};
}

// Page Components
function PageHeaderSection({ auditInfo, auditId }) {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  const location = useLocation();
  const { pathname } = location;

  // Audit Pre Assessment Selector State
  const startAuditPreAssessmentLoading = useSelector((state) => state.audit.startAuditPreAssessmentLoading);
  const startAuditPreAssessmentSuccess = useSelector((state) => state.audit.startAuditPreAssessmentSuccess);

  // Pre Audit Selector State
  const startPreAuditLoading = useSelector((state) => state.audit.startPreAuditLoading);
  const startPreAuditSuccess = useSelector((state) => state.audit.startPreAuditSuccess);

  // Getting Audit Information
  const { industryOrgId = "", industryOrgName = "", plantId = "", plantName = "", auditStatus = "" } = auditInfo || {};
  const orgType = getOrgType();

  // Content
  const pageTitle = `Energy Audit :: ${industryOrgName || ""} - ${plantName || ""}`;
  const pageActions = <div className="btn-cont"></div>;

  // Audit Status Button
  const auditStatusButton = {
    AUDIT_INIT: {
      label: "Start Pre Assessment",
      action: AuditActions.startAuditPreAssessment,
      allowedOrgs: ["PLATFORM", "INDUSTRY"],
      loading: startAuditPreAssessmentLoading,
    },
    QUESTIONNAIRE: {
      label: "Start Walk Through",
      action: AuditActions.startPreAudit,
      allowedOrgs: ["PLATFORM", "AUDIT"],
      loading: startPreAuditLoading,
    },
  };

  // Getting organizations allowed to see this button
  const { allowedOrgs, label, action, loading } = auditStatusButton[auditStatus] || {};

  // Checking whether that organization has access to audit status button
  const isOrgAllowed = allowedOrgs?.includes(orgType);

  // backbutton config
  const backButtonConfig = constructBackButtonConfig(pathname);

  useEffect(() => {
    if (startAuditPreAssessmentSuccess) {
      toast.success("Started Audit Pre Assessment Successfully");

      const url = URLs.format(RedirectTo.PlantSetupPageUrl, { orgId: industryOrgId, plantId });
      navigate(url);
    }
  }, [startAuditPreAssessmentSuccess]);

  useEffect(() => {
    if (startPreAuditSuccess) {
      toast.success("Started Pre Audit Successfully");

      const url = URLs.format(RedirectTo.PlantSetupPageUrl, { orgId: industryOrgId, plantId });
      navigate(url);
    }
  }, [startPreAuditSuccess]);

  // If allowed, then corresponding button is dispalyed
  if (isOrgAllowed) {
    const pageActions = (
      // TODO :: Enable after fully integrating
      <Button
        className="d-none"
        label={label}
        onClick={() => dispatch(action({ auditId }))}
        loading={loading}
        disabled={loading}
      />
    );

    return <PageHeader title={pageTitle} actions={pageActions} backButtonConfig={backButtonConfig} />;
  }

  return <PageHeader title={pageTitle} actions={pageActions} backButtonConfig={backButtonConfig} />;
}

/**
 * Layout Page
 */
export default function AuditLayout() {
  // Dispatch
  const dispatch = useDispatch();

  // Get saved info from the loader
  const { auditInfo } = useLoaderData();

  // Params
  const params = useParams();
  const { auditId } = params;

  // Sidebar Configuration
  const { sideBarElements = [], backButtonConfig = {} } = AuditLayoutSideBar || {};

  // replace auditId param in all the sidebar urls..
  const paramMap = { auditId: auditId };

  const updatedSideBarElements = constructSideBarUrls(sideBarElements, paramMap);

  // use Effect
  useEffect(() => {
    dispatch(AuditComponentActions.clearAuditComponentInfo());
  }, [auditId]);

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: (
      <div className="d-flex align-items-center justify-content-between">
        {/* Header */}
        <PageHeaderSection auditId={auditId} auditInfo={auditInfo} />
      </div>
    ),
  };

  // Sidebar Config
  const { headerTitle, logoHeight, logoURL, collapsedLogoHeight, collapsedLogoURL } = SIDEBAR_HEADER_CONSTANTS;
  const sidebarConfig = {
    menuComponent: <SidebarMenu sidebarMenuList={updatedSideBarElements} backButtonConfig={backButtonConfig} />,
    footerComponent: <SiderbarFooter />,
    headerLogo: { height: logoHeight, logoURL },
    collapsedHeaderLogo: { height: collapsedLogoHeight, logoURL: collapsedLogoURL },
    redirectURL: RedirectTo.DashboardPageUrl,
    title: headerTitle,
  };

  return <Layout sidebarCollapsible={true} topbarConfig={topbarConfig} sidebarConfig={sidebarConfig} />;
}
