import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { ComponentTypeActions } from "../../../redux-slice/component-type/ComponentTypeSlice";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { OutlineButton } from "../../../components/button/Button";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableLoader from "../../../components/table/TableLoader";
import ToggleButton from "../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Id",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Is Part",
    },
  },
  {
    title: {
      displayName: "Sub Types",
    },
  },
  {
    title: {
      displayName: "Data",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function ComponentTypeListDataCell({ componentTypeId, isActive }) {
  // Navigate
  const navigate = useNavigate();

  // Action Butttons
  const actionButtons = [
    {
      label: "Parameters",
      redirectUrl: URLs.format(RedirectTo.ParameterListPageUrl, { componentTypeId }),
    },
    {
      label: "Observation Codes",
      redirectUrl: URLs.format(RedirectTo.ObservationCodeListPageurl, { componentTypeId }),
    },
    {
      label: "Recommendation Codes",
      redirectUrl: URLs.format(RedirectTo.RecommendationCodeListPageUrl, { componentTypeId }),
    },
  ];

  return (
    <td>
      <div className="btn-cont">
        {actionButtons.map((btn, idx) => {
          const { label = "", redirectUrl = "" } = btn || {};

          return (
            <OutlineButton key={idx} size="sm" label={label} onClick={() => navigate(redirectUrl)} disabled={!isActive}>
              <i className="fas fa-eye"></i>
            </OutlineButton>
          );
        })}
      </div>
    </td>
  );
}

function ComponentTypeListActionCell({ componentTypeId, isActive }) {
  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const updateComponentTypeStatusLoading = useSelector(
    (state) => state.componentType.updateComponentTypeStatusLoadingMap[componentTypeId]
  );
  const updateComponentTypeStatusSuccess = useSelector(
    (state) => state.componentType.updateComponentTypeStatusSuccessMap[componentTypeId]
  );

  // Component Type List Selectot state
  const componentTypeListPagination = useSelector((state) => state.componentTypeList.componentTypeListPagination);

  const { pageNo, pageSize } = componentTypeListPagination;

  // Changing Status
  const status = !isActive;

  function updateComponentTypeStatus() {
    dispatch(ComponentTypeActions.updateComponentTypeStatus({ componentTypeId, status, pageNumber: pageNo, pageSize }));
  }

  useEffect(() => {
    if (updateComponentTypeStatusSuccess) {
      toast.success("Status Changed Successfully");
    }
  }, [updateComponentTypeStatusSuccess]);

  return (
    <td>
      <ToggleButton
        isActive={isActive}
        loading={updateComponentTypeStatusLoading}
        onClick={updateComponentTypeStatus}
      />
    </td>
  );
}

function ComponentTypeListTableRow({ serialNo, componentType }) {
  const { id, code, name, description, isPart, subTypeCodes = [], isActive } = componentType;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{code}</td>
      <td>{name}</td>
      <td>{description}</td>
      <td>{isPart ? "YES" : ""}</td>
      <td>{subTypeCodes.join(", ")}</td>

      {/* Component Type List Data Cell */}
      <ComponentTypeListDataCell componentTypeId={id} isActive={isActive} />

      {/* Component Type List Action Cell */}
      <ComponentTypeListActionCell componentTypeId={id} isActive={isActive} />
    </tr>
  );
}

function ComponentTypeListTableBody({ pageNumber, pageSize, componentTypeList, componentTypeListLoading }) {
  //Loader
  if (componentTypeListLoading) {
    return <TableLoader colSpan={8} />;
  }

  //if no component type present
  if (componentTypeList.length === 0) {
    return <TableDataNotFound message="No component type present" colSpan={8} />;
  }

  return (
    <>
      {componentTypeList.map((componentType, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        // Component Type List Table Row
        return <ComponentTypeListTableRow key={componentType.id} serialNo={serialNo} componentType={componentType} />;
      })}
    </>
  );
}

/**
 * Component Type List Table
 */
export default function ComponentTypeListTable({
  componentTypeList = [],
  componentTypeListLoading = false,
  componentTypeListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageNo, pageSize } = componentTypeListPagination;

  return (
    <>
      <table className="table table-bordered">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Component Type list Table body */}
          <ComponentTypeListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            componentTypeList={componentTypeList}
            componentTypeListLoading={componentTypeListLoading}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
