import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";

// Actions
import { PlantComponentPartsActions } from "../../../redux-slice/plant/PartsSlice";

// Constants
import { ModalKeys } from "../../../constants/ModalConstants";

// Utils
import { getComponentParts, getComponentTypeParameters } from "../../../utils/DataPreLoadUtils";
import {
  DefaultInputRows,
  RemarksInputRow,
  constructFormDataObject,
  constructParameterValuesArray,
  getParametersFormInputs,
} from "../../../utils/ComponentUtils";

// Components
import { DefaultFormValidationSchema } from "../../../components/form/Form";
import TabularForm from "../../../components/form/TabularForm";

// Section
import ViewComponentModal from "./ViewComponentModal";
import { toast } from "react-toastify";

/**
 * View Plant Component Modal
 */
export default function ViewPlantComponentPartModal({
  componentTypeId,
  selectedComponentIndex = "",
  selectedPartId = "",
  tableHeaders = [],
  showViewPartModal = false,
  setShowViewPartModal = () => {},
  showAddPartModal = false,
  setShowAddPartModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Plant Component List Selector State
  const plantComponentList = useSelector((state) => state.plantComponentList.plantComponentList);

  // Add Plant Component Part Selector State
  const addPlantComponentPartLoading = useSelector((state) => state.plantComponentParts.addPlantComponentPartLoading);
  const addPlantComponentPartSuccess = useSelector((state) => state.plantComponentParts.addPlantComponentPartSuccess);

  // Update Plant Component Part Selector State
  const updatePlantComponentPartLoading = useSelector(
    (state) => state.plantComponentParts.updatePlantComponentPartLoading
  );
  const updatePlantComponentPartSuccess = useSelector(
    (state) => state.plantComponentParts.updatePlantComponentPartSuccess
  );

  // Getting parts for that component (Default)
  const parts = getComponentParts(componentTypeId) || [];

  // Getting part info (Default)
  const { id: partId = "", name = "" } = parts.find(({ id = "" }) => id === parseInt(selectedPartId)) || {};

  // Plant Component Information
  const { component: plantComponent = {}, parts: addedParts = [] } = plantComponentList[selectedComponentIndex] || {};
  const { id: componentId = "", name: componentName = "", internalId = "" } = plantComponent;

  // Finding component and properties for the selected part
  // Here componentTypeId refers to part id (From plant component list)
  const selectedPartInfo =
    addedParts.find(({ component }) => parseInt(component?.id ?? 0) === parseInt(selectedPartId)) || {};

  // Selected Part Properties Information (Added parts)
  const { component = {}, properties = [] } = selectedPartInfo || {};
  const {
    componentTypeId: componentPartId = "",
    id = "",
    name: partName = "",
    internalId: partInternalId = "",
  } = component;
  const viewPartModalTitle = `${partName} (${partInternalId})`;

  // Getting property list for the selected part or new part
  // For view or editing the part, component part id is used
  const propertyList = useMemo(() => {
    return getComponentTypeParameters(componentPartId || partId) || [];
  }, [componentPartId, partId]);

  // Add parts form data
  const addPartsFormData = useMemo(() => {
    return constructFormDataObject(propertyList);
  }, [propertyList]);

  // Form Inputs
  const formInputItems = useMemo(() => {
    // Table Config
    const tableConfig = {
      showUnits: false,
      isInputPresent: true,
      isUnitConversionPresent: true,
    };

    const formInputRows = getParametersFormInputs(propertyList, properties, tableConfig, id, true) || [];
    return [...DefaultInputRows, ...formInputRows, RemarksInputRow];
  }, [id, properties, propertyList]);

  // Adding part to component form submit
  function addPlantComponentPartFormSubmit(partFormData) {
    const { name = "", internalId = "", remarks = "" } = partFormData;

    // Constructing parts properties
    const propertiesArray = constructParameterValuesArray(propertyList, partFormData);

    // Component object
    const partData = { componentTypeId: partId, name, internalId, remarks, properties: propertiesArray };

    // Dispatch
    dispatch(PlantComponentPartsActions.addPlantComponentPart({ partData, plantId, componentId, componentTypeId }));
  }

  useEffect(() => {
    if (addPlantComponentPartSuccess) {
      toast.success("Added Plant Component Part Successfully");
      setShowAddPartModal(false);
    }
  }, [addPlantComponentPartSuccess]);

  useEffect(() => {
    if (updatePlantComponentPartSuccess) {
      toast.success("Updated Plant Component Part Successfully");
      setShowViewPartModal(false);
    }
  }, [updatePlantComponentPartSuccess]);

  return (
    <>
      {/* View Part Modal */}
      <ViewComponentModal
        title={viewPartModalTitle}
        componentInfo={selectedPartInfo}
        tableHeaders={tableHeaders}
        loading={updatePlantComponentPartLoading}
        dispatchAtn={PlantComponentPartsActions.updatePlantComponentPart}
        formInputItems={formInputItems}
        partId={id}
        isPart={true}
        showModal={showViewPartModal}
        setShowModal={setShowViewPartModal}
      />

      {/* Add Part Modal */}
      <Modal
        title={`${componentName} (${internalId}) - Add ${name}`}
        open={showAddPartModal}
        width={800}
        footer={null}
        setShowAddPartModal={setShowAddPartModal}
        onCancel={() => setShowAddPartModal(false)}
      >
        <div className="mt-3">
          <TabularForm
            tableHeaders={tableHeaders}
            loading={addPlantComponentPartLoading}
            formInputItems={formInputItems}
            formValidationSchema={DefaultFormValidationSchema}
            data={addPartsFormData}
            formSubmit={addPlantComponentPartFormSubmit}
            showModal={showAddPartModal}
          />
        </div>
      </Modal>
    </>
  );
}
