// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Urls
import {
  createPlantThermalBillUrl,
  getPlantThermalBillListByIdUrl,
  updatePlantThermalBillByIdUrl,
  uploadPlantThermalBillByIdUrl,
} from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// App Constants
import { ContentType } from "../../app/http/HttpConstants";

// Utils
import URLs from "../../app/utils/URLs";

// Get Plant Thermal Bill List
export async function getPlantThermalBillListByIdApi(plantId, pageNumber, pageSize, searchText) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
  };

  if (searchText) {
    params.q = searchText;
  }

  const url = URLs.format(getPlantThermalBillListByIdUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(url, params);
}

// Create Plant Thermal Bill
export async function createPlantThermalBillApi(formData, plantId) {
  const url = URLs.format(createPlantThermalBillUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData);
}

// Update Plant Thermal Bill By Id
export async function updatePlantThermalBillByIdApi(formData, plantId, thermalBillId) {
  const url = URLs.format(updatePlantThermalBillByIdUrl, { plantId, thermalBillId });
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, {}, formData);
}

// Upload Plant Thermal Bill By Id
export async function uploadPlantThermalBillByIdApi(formData, plantId, thermalBillId) {
  const url = URLs.format(uploadPlantThermalBillByIdUrl, { plantId, thermalBillId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.APP_FORM_DATA);
}

// Download Plant Thermal Bill By Id
export async function downloadPlantThermalBillByIdApi(url) {
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadPublicFile(url);
}
