import * as yup from "yup";

// Constants
import { OrgType } from "../../constants/OrgTypeConstants";

// Form Schema
import { UserFormSchema, UserFormValidationSchema } from "../../form-schema/UserFormSchema";

// Constructing options array for select plant input
function constructPlantOptionsArray(plantList = []) {
  return plantList.map((eachPlant) => {
    const { id = "", name = "" } = eachPlant || {};

    return { id, value: name };
  });
}

function addPassowrdInputFieldAndValidation(inputField = {}, clonedUserFormValidationSchema = {}) {
  const passwordInputField = {
    label: "Password",
    key: "password",
    placeholder: "Enter password...",
    isMandotary: true,
  };

  inputField.inputItems.push(passwordInputField);
  clonedUserFormValidationSchema.password = yup
    .string()
    .required("Please fill the password")
    .min(8, "Minimum character should be 8");
}

function addSelectPlantInputField(plantList, clonedUserFormValidationSchema, clonedUserFormInputs) {
  const selectPlantFormInput = {
    containerClassName: "row mt-4",
    inputItems: [
      {
        label: "Plants",
        key: "plantId",
        type: "select",
        options: constructPlantOptionsArray(plantList),
        placeholder: "Select plant...",
        isMandotary: true,
      },
    ],
  };

  // Form validation for plant select input
  clonedUserFormValidationSchema.plantId = yup.string().required("Please select plant");

  // Updating form inputs
  clonedUserFormInputs.push(selectPlantFormInput);
}

// Form schema for industry user
export function getIndustryUserFormSchema(orgType = "", plantList = [], isPasswordFieldPresent = false) {
  // Deep Cloning
  const clonedUserFormInputs = structuredClone(UserFormSchema);
  const clonedUserFormValidationSchema = { ...UserFormValidationSchema };

  const inputField = {
    containerClassName: "row mt-4",
    inputItems: [
      {
        label: "Email",
        key: "emailId",
        type: "email",
        placeholder: "Enter email...",
        isMandotary: true,
      },
    ],
  };

  if (isPasswordFieldPresent) {
    addPassowrdInputFieldAndValidation(inputField, clonedUserFormValidationSchema);
  }

  // For industry, one more field for selecting plant is added
  if (orgType === OrgType.INDUSTRY) {
    addSelectPlantInputField(plantList, clonedUserFormValidationSchema, clonedUserFormInputs);
  }

  clonedUserFormInputs.push(inputField);

  return { formInputItems: clonedUserFormInputs, formValidationSchema: yup.object(clonedUserFormValidationSchema) };
}
