import moment from "moment";

// Constants
import { DateFormat, ValueTypes } from "../constants/GeneralConstants";

// Converts month number to month name . Example "12" => "December"
export function getMonthName(month) {
  const date = new Date();

  date.setMonth(month - 1);

  return date.toLocaleString("en-US", {
    month: "long",
  });
}
// Generate years from given year to current year
export function generateYears(startYear) {
  const currentYear = new Date().getFullYear();

  const years = [];

  for (let i = startYear; i <= currentYear; i++) {
    years.push({ value: i });
  }

  return years;
}

// Convert Date object to timestamp
export function toTimestamp(date) {
  if (!date) {
    return null;
  }

  return Date.parse(date) || "";
}

/**
 * Function to get formatted date and time
 * @param {*} valueType
 * @param {*} value
 * @param {*} format
 */
export function getFormattedDateTime(valueType = "", value = "", format = "") {
  // It it's a date
  if (valueType === ValueTypes.DATE) {
    return moment(value).format(DateFormat.DATE_MONTH_YEAR);
  }

  // If it's a time
  if (valueType === ValueTypes.TIME) {
    return moment(value).format(format);
  }

  // If it's a Duration - timestamp
  if (valueType === ValueTypes.DURATION) {
    return moment(value).format(format);
  }

  return value;
}
