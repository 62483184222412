import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";

// Utils
import { bcForOrganizationViewPage } from "../../../utils/BreadcrumbUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { OutlineButton } from "../../../components/button/Button";
import Loader from "../../../components/loader/Loader";
import Form from "../../../components/form/Form";
import PageHeader from "../../../components/page-header/PageHeader";

// Form Schema
import { OrganizationFormSchema, OrganizationFormValidationSchema } from "../../../form-schema/OrganizationFormSchema";

// Page Components
function PageHeaderSection({ organizationInfo }) {
  const { orgType, name: orgName } = organizationInfo || {};
  return <PageHeader breadcrumbList={bcForOrganizationViewPage(orgName, orgType)} className="w-100 mt-2" />;
}

function ViewPlantsAndUsers({ organizationInfo }) {
  const navigate = useNavigate();

  const { id: orgId, orgType } = organizationInfo;
  const isIndustryOrg = orgType === "INDUSTRY";

  const userListPageURL = URLs.format(RedirectTo.UserListPageUrl, { orgId });
  const plantListPageURL = URLs.format(RedirectTo.PlantListPageUrl, { orgId });

  return (
    <div className="btn-cont my-3 text-end">
      {/** View Plants for Industry Type ORG */}
      {isIndustryOrg && (
        <OutlineButton label="View Plants" onClick={() => navigate(plantListPageURL)}>
          <i className="fas fa-industry"></i>
        </OutlineButton>
      )}

      <OutlineButton label="View Users" onClick={() => navigate(userListPageURL)}>
        <i className="fas fa-users"></i>
      </OutlineButton>
    </div>
  );
}

function OrganizationCard({ formSubmit, data, organizationInfoLoading, updateOrganizationLoading, formInputItems }) {
  const updateOrganizationSuccess = useSelector((state) => state.organization.updateOrganizationSuccess);

  return (
    <div className="card">
      <div className="card-body">
        {/* Organization View Page Card Title */}
        <h5 className="card-title d-flex justify-content-between">
          <span>Basic Information</span>
        </h5>

        {/* Loader */}
        {organizationInfoLoading && <Loader containerClassName="m-5" />}

        {/** Card Body */}
        {!organizationInfoLoading && (
          <Form
            loading={updateOrganizationLoading}
            formInputItems={formInputItems}
            data={data}
            formSubmit={formSubmit}
            formValidationSchema={OrganizationFormValidationSchema}
            changeToReadMode={updateOrganizationSuccess}
          />
        )}
      </div>
    </div>
  );
}

/**
 * Organization View Page
 */
export default function OrganizationViewPage() {
  const dispatch = useDispatch();

  // Page Params
  const params = useParams();
  const { orgId, orgType: orgName = "" } = params;
  const orgType = orgName.toUpperCase();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Organization Info Selector State
  const organizationInfo = useSelector((state) => state.organization.organizationInfo);
  const organizationInfoLoading = useSelector((state) => state.organization.organizationInfoLoading);

  // Update Organization Selector State
  const updateOrganizationLoading = useSelector((state) => state.organization.updateOrganizationLoading);
  const upsertOrganizationSuccesss = useSelector((state) => state.organization.upsertOrganizationSuccesss);

  // Updating default input value of the first input item according to organization type from search params
  const clonedOrganizationInputItems = [...OrganizationFormSchema];
  clonedOrganizationInputItems[0].inputItems[0] =
    {
      ...(clonedOrganizationInputItems[0].inputItems[0] || {}),
      defaultInputValue: orgType || "",
    } || {};

  // useEffect
  useEffect(() => {
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
  }, [dispatch, orgId]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection organizationInfo={organizationInfo} />);
  }, [organizationInfo]);

  useEffect(() => {
    if (upsertOrganizationSuccesss && orgId) {
      toast.success("Organization Updated Successfully");
    }
  }, [dispatch, orgId, upsertOrganizationSuccesss]);

  // Formik onSubmit Function
  function formSubmit(organizationData) {
    dispatch(OrganizationActions.updateOrganization({ orgId, organizationData }));
  }

  return (
    <div className="page-content">
      {/* View Plant or Users Buttons */}
      <ViewPlantsAndUsers organizationInfo={organizationInfo} />

      {/* Organization View Page Card */}
      <OrganizationCard
        formInputItems={clonedOrganizationInputItems}
        organizationInfoLoading={organizationInfoLoading}
        updateOrganizationLoading={updateOrganizationLoading}
        data={organizationInfo}
        formSubmit={formSubmit}
      />
    </div>
  );
}
