import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.auditListSuccess = false;
}

// Initial State
const initialState = {
  // Get Audit List
  auditList: [],
  auditListPagination: {},
  auditListLoading: false,
  auditListSuccess: false,
  auditListError: "",

  // Get Audit List By Auditor
  auditListByAuditor: [],
  auditListByAuditorLoading: false,
  auditListByAuditorError: "",
};

// Audit List Slice
const auditListSlice = createSlice({
  // Name
  name: "AuditList",

  // Initial  State
  initialState,

  // Reducers
  reducers: {
    // Get Audit List
    getAuditList: (state) => {
      state.auditListSuccess = false;
      state.auditListLoading = true;
    },

    getAuditListSuccess: (state, action) => {
      state.auditListLoading = false;
      state.auditList = action.payload.auditList;
      state.auditListPagination = action.payload.auditListPagination;
      state.auditListSuccess = true;
    },

    getAuditListFailure: (state, action) => {
      state.auditListLoading = false;
      state.auditListError = action.payload.error;
    },

    // Get Audit List By Auditor
    getAuditListByAuditor: (state) => {
      state.auditListByAuditorLoading = true;
    },

    getAuditListByAuditorSuccess: (state, action) => {
      state.auditListByAuditorLoading = false;
      state.auditListByAuditor = action.payload.auditListByAuditor;
    },

    getAuditListByAuditorFailure: (state, action) => {
      state.auditListByAuditorLoading = false;
      state.auditListError = action.payload.error;
    },

    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditListReducer = auditListSlice.reducer;

// Actions
export const AuditListActions = auditListSlice.actions;
