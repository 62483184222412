import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.upsertFileSuccess = false;
  state.deleteFileSuccess = {};
}

// Initial State
const initialState = {
  // Get Plant File List
  fileList: [],
  fileListPagination: {},
  fileListLoading: false,
  fileListError: "",

  // Create Plant File
  createFileLoading: false,
  createFileError: {},

  // Update Plant File By Id
  updateFileLoading: false,
  updateFileError: {},

  upsertFileSuccess: false,

  // Download Plant File By Id
  downloadFileLoading: {},
  downloadFileError: {},

  // Delete Plant File By Id
  deleteFileLoading: {},
  deleteFileSuccess: {},
  deleteFileError: {},
};

// Plant File Slice
const plantFileSlice = createSlice({
  name: "PlantFile",
  initialState,
  reducers: {
    // Get Plant File List
    getPlantFileList: (state) => {
      state.fileListLoading = true;
    },

    getPlantFileListSuccess: (state, action) => {
      state.fileListLoading = false;
      state.fileList = action.payload.fileList;
      state.fileListPagination = action.payload.fileListPagination;
    },

    getPlantFileListFailure: (state, action) => {
      state.fileListLoading = false;
      state.fileListError = action.payload.error;
    },

    // Create Plant File
    createPlantFile: (state) => {
      state.upsertFileSuccess = false;
      state.createFileLoading = true;
    },

    createPlantFileSuccess: (state) => {
      state.upsertFileSuccess = true;
      state.createFileLoading = false;
    },

    createPlantFileFailure: (state, action) => {
      state.createFileLoading = false;
      state.createFileError = action.payload.error;
    },

    // Update Plant File By Id
    updatePlantFileById: (state) => {
      state.upsertFileSuccess = false;
      state.updateFileLoading = true;
    },

    updatePlantFileByIdSuccess: (state) => {
      state.upsertFileSuccess = true;
      state.updateFileLoading = false;
    },

    updatePlantFileByIdFailure: (state, action) => {
      state.updateFileLoading = false;
      state.updateFileError = action.payload.error;
    },

    // Download Plant File By Id
    downloadPlantFileById: (state, action) => {
      const { fileId = "" } = action.payload;
      state.downloadFileLoading[fileId] = true;
      state.downloadFileError = "";
    },

    downloadPlantFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;
      delete state.downloadFileLoading[fileId];
    },

    downloadPlantFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;
      delete state.downloadFileLoading[fileId];
      state.downloadFileError = error;
    },

    // Delete Plant File By Id
    deletePlantFileById: (state, action) => {
      const { fileId = "" } = action.payload;
      state.deleteFileSuccess[fileId] = false;
      state.deleteFileLoading[fileId] = true;
      state.deleteFileError = "";
    },

    deletePlantFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;
      state.deleteFileSuccess[fileId] = true;
      delete state.deleteFileLoading[fileId];
    },

    deletePlantFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;
      delete state.deleteFileLoading[fileId];
      state.deleteFileError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantFileReducer = plantFileSlice.reducer;

// Actions
export const PlantFileActions = plantFileSlice.actions;
