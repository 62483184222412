// Audit File Type
export const AuditFileTypes = {
  COMPONENT_IMAGE: "Component Images",
  COMPONENT_IMAGE_THERMAL: "Component Thermal Images",
  COMPONENT_VIDEO: "Component Videos",
  INSTRUMENT_DATA: "Instrument Datas",
  AUDIT_REPORT: "Audit Reports",
  OTHER: "Others",
};

// Plant File Type
export const PlantFileTypes = {
  PLANT_CERT: "Plant Certificates",
  PLANT_PROCESS_DIAGRAM: "Plant Process Diagrams",
  PLANT_THERMAL_BILL: "Plant Thermal Bills",
  PLANT_POWER_BILL: "Plant Power Bills",
  PLANT_IMAGE: "Plant Images",
  PLANT_VIDEO: "Plant Videos",
  PLANT_CONNECTED_LOAD_DATA_FILE: "Plant Connected Load Data",
  COMPONENT_IMAGE: "Component Images",
  COMPONENT_VIDEO: "Component Videos",
  OTHER: "Others",
};
