/***
 * Function to replace the pathParams with the actual values..
 * 
 * Parameters:
 * sideBarElements  ==> [
    {
        title: "Audit View",
        items: [
            {
                itemName: "Overview",
                itemIconCls: "far fa-list-alt",
                itemLink: /api/v1/audit/:auditId/plant/:plantId,
            }
        ],
    }
] 

* paramMap ==> {"auditId": 12, "plantId": 1}

NOTE : keys of paramMap should match with the pathParams in the Url (itemLink)

resultant sideBarElements will be  ==>  [
    {
        title: "Audit View",
        items: [
            {
                itemName: "Overview",
                itemIconCls: "far fa-list-alt",
                itemLink: /api/v1/audit/12/plant/1,
            }
        ],
    }
] 
 */

export function constructSideBarUrls(sideBarElements = [], paramMap = {}) {
  // Cloning sidebar elements (deep copy)
  const cloneSideBar = structuredClone(sideBarElements);

  return cloneSideBar.map((eachMenu) => {
    const { items = [] } = eachMenu || {};

    const updatedLink = items.map((eachElement) => {
      const { label, redirectUrl } = eachElement;
      let itemLinkValue = redirectUrl;
      let itemLabel = label;

      for (const paramKey in paramMap) {
        const paramValue = paramMap[paramKey];
        itemLinkValue = itemLinkValue.replace(`:${paramKey}`, paramValue);
        itemLabel = itemLabel.replace(`:${paramKey}`, paramValue);
      }

      return { ...eachElement, redirectUrl: itemLinkValue, label: itemLabel };
    });

    return { ...eachMenu, items: updatedLink };
  });
}
