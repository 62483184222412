import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal } from "antd";
import { toast } from "react-toastify";

// Actions
import { PlantComponentActions } from "../../../redux-slice/plant/ComponentSlice";

// Utils
import { getComponentTypeParameters } from "../../../utils/DataPreLoadUtils";
import {
  DefaultInputRows,
  RemarksInputRow,
  constructFormDataObject,
  constructParameterValuesArray,
  getParametersFormInputs,
} from "../../../utils/ComponentUtils";

// Components
import { Button } from "../../../components/button/Button";
import { DefaultFormValidationSchema } from "../../../components/form/Form";
import Input from "../../../components/input/Input";
import TabularForm from "../../../components/form/TabularForm";

// Sections
import ComponentTypeListCardView from "./ComponentTypeListCardView";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Design Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

// Page Component
function ComponentTypeListPageHeader({ title = "", setSelectedComponentIndex, setShowModal = () => {} }) {
  // Open Component Type Modal Function
  function openComponentTypeModal() {
    setSelectedComponentIndex("");
    setShowModal(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        {/* Input */}
        <Input className="d-none" placeholder="Search plant components..." />
      </div>

      {/* Button */}
      <Button label={`Add ${title}`} color="dark" onClick={openComponentTypeModal}>
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}

/**
 * Component Type List
 */
export default function ComponentTypeList({ componentTypeId = "", componentTypes = [] }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // State
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  // Plant Component List Selector State
  const plantComponentList = useSelector((state) => state.plantComponentList.plantComponentList);

  // Add Plant Component Selector State
  const addPlantComponentLoading = useSelector((state) => state.plantComponent.addPlantComponentLoading);
  const addPlantComponentSuccess = useSelector((state) => state.plantComponent.addPlantComponentSuccess);

  // Selected Component Type Name
  const { name: selectedComponentTypeName = "" } =
    componentTypes.find(({ id = "" }) => id === parseInt(componentTypeId)) || {};
  const title = `Add ${selectedComponentTypeName}`;

  // Getting component property list
  const propertyList = getComponentTypeParameters(componentTypeId);
  const addComponentFormData = constructFormDataObject(propertyList);

  // Getting component info
  const { component = {}, properties = [] } = plantComponentList[selectedComponentIndex] || {};
  const { id = "" } = component;

  useEffect(() => {
    if (addPlantComponentSuccess) {
      toast.success("Added Plant Component Successfully");
      setShowModal(false);
    }
  }, [addPlantComponentSuccess]);

  // Form inputs for view and add component
  const componentFormInputs = useMemo(() => {
    // Table Config
    const tableConfig = { showUnits: false, isInputPresent: true, isUnitConversionPresent: true };

    const formInputs = getParametersFormInputs(propertyList, properties, tableConfig, id);

    return [...DefaultInputRows, ...formInputs, RemarksInputRow];
  }, [propertyList, properties, id]);

  // Add component form submit function
  function addComponentTypeFormSubmit(componentPropertyData) {
    const { name = "", internalId = "", remarks = "" } = componentPropertyData;

    const propertiesArray = constructParameterValuesArray(propertyList, componentPropertyData);

    const componentData = { componentTypeId, name, internalId, remarks, isParent: true, properties: propertiesArray };

    dispatch(PlantComponentActions.addPlantComponent({ componentData, plantId, componentTypeId }));
  }

  return (
    <>
      {/* Component Type List Page Header */}
      <ComponentTypeListPageHeader
        setSelectedComponentIndex={setSelectedComponentIndex}
        setShowModal={setShowModal}
        title={title}
      />

      {/* Component Type List Card View */}
      <ComponentTypeListCardView
        componentTypeId={componentTypeId}
        tableHeaders={tableHeaders}
        formInputItems={componentFormInputs}
        selectedComponentIndex={selectedComponentIndex}
        setSelectedComponentIndex={setSelectedComponentIndex}
      />

      {/* Add Component Modal */}
      <Modal title={title} width={1000} open={showModal} onCancel={() => setShowModal(false)} footer={null}>
        <div className="mt-3">
          <TabularForm
            tableHeaders={tableHeaders}
            loading={addPlantComponentLoading}
            formInputItems={componentFormInputs}
            formValidationSchema={DefaultFormValidationSchema}
            data={addComponentFormData}
            formSubmit={addComponentTypeFormSubmit}
            showModal={showModal}
          />
        </div>
      </Modal>
    </>
  );
}
