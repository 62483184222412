import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useOutletContext } from "react-router-dom";

// Actions
import { AuditListActions } from "../../redux-slice/audit/AuditListSlice";

// Utils
import { getIndustryOrAuditOrgId } from "../../utils/AuthOrgUtil";
import { LocalStorageKeys, getItem } from "../../app/LocalStorage";

// Urls
import { URL_ORG_LIST_AUDIT_PARTNERS, URL_ORG_LIST_INDUSTRIES } from "../../urls/page-urls/RedirectURL";

// Components
import PageHeader from "../../components/page-header/PageHeader";
import AuditInitiateButton from "../../components/audit/AuditInitiateButton";

// Page Components
import AuditListTable from "../../page-components/audit-list/AuditListTable";

// Page Components
function RecentAuditsCard() {
  // Dispatch
  const dispatch = useDispatch();

  // query params
  const orgId = getIndustryOrAuditOrgId();

  // Selector State
  const auditList = useSelector((state) => state.auditList.auditList);
  const auditListLoading = useSelector((state) => state.auditList.auditListLoading);
  const auditListPagination = useSelector((state) => state.auditList.auditListPagination);

  //use Effect
  useEffect(() => {
    dispatch(AuditListActions.getAuditList({ orgId, pageSize: 5, pageNumber: 1 }));
  }, [dispatch]);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-3">
        <h3 className="m-0">Recent Audits</h3>

        {/* Audit Initiate Button */}
        <AuditInitiateButton />
      </div>

      <AuditListTable items={auditList} pagination={auditListPagination} loading={auditListLoading} />
    </>
  );
}

function QuickAccessLinksCard() {
  return (
    <>
      <div className="card mb-4 d-none">
        <div className="card-body">
          {/* Audit Initiate Button */}
          <AuditInitiateButton />
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Quick Access</h5>

          <ul className="mt-4 list-unstyled">
            <li className="">
              <Link to={URL_ORG_LIST_INDUSTRIES}>Industries</Link>
            </li>
            <li>
              <Link to={URL_ORG_LIST_AUDIT_PARTNERS}>Audit Partners</Link>
            </li>
            <li>Unit Conversion Tool</li>
          </ul>
        </div>
      </div>
    </>
  );
}

/**
 * Page
 */
export default function DashboardPage() {
  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Get Authenticated User from local storage
  const user = JSON.parse(getItem(LocalStorageKeys.AUTH_USER)) || {};
  const { firstName } = user;

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeader title={`Welcome ${firstName} !!!`} />);
  }, []);

  return (
    <div className="page-content">
      <div className="row">
        <div>
          {/* Recent Activity */}
          <RecentAuditsCard />
        </div>

        {/* Uncomment when needed */}
        <div className="d-none">
          {/* Quick Access */}
          {/* <QuickAccessLinksCard /> */}
        </div>
      </div>
    </div>
  );
}
