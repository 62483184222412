// Constants
import { SIDEBAR_HEADER_CONSTANTS, UserLayoutSideBar } from "../../constants/LayoutConstants";

// URLs
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Layouts
import Layout from "../../app/layout/Layout";
import SidebarMenu from "./SidebarMenu";

/**
 * Layout Page
 */
export default function UserLayout() {
  // Sidebar Configuration
  const { sideBarElements = [], backButtonConfig = {} } = UserLayoutSideBar || {};

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: <></>,
  };

  // Sidebar Config
  const { headerTitle, logoHeight, logoURL, collapsedLogoHeight, collapsedLogoURL } = SIDEBAR_HEADER_CONSTANTS;
  const sidebarConfig = {
    menuComponent: <SidebarMenu sidebarMenuList={sideBarElements} backButtonConfig={backButtonConfig} />,
    // footerComponent: <SiderbarFooter />,
    headerLogo: { height: logoHeight, logoURL },
    collapsedHeaderLogo: { height: collapsedLogoHeight, logoURL: collapsedLogoURL },
    redirectURL: RedirectTo.DashboardPageUrl,
    title: headerTitle,
  };

  return <Layout sidebarCollapsible={true} topbarConfig={topbarConfig} sidebarConfig={sidebarConfig} />;
}
