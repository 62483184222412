import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.upsertOrganizationSuccesss = false;
  state.updateOrganizationStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Add organization
  addOrganizationLoading: false,
  addOrganizationError: "",

  // Update organization
  updateOrganizationLoading: false,
  updateOrganizationError: "",

  upsertOrganizationSuccesss: false,

  // Get organization information
  organizationInfo: {},
  organizationInfoLoading: false,
  organizationInfoError: "",

  // Update organization status
  updateOrganizationStatusLoadingMap: {},
  updateOrganizationStatusSuccessMap: {},
  updateOrganizationStatusError: "",
};

// Organization Slice
const organizationSlice = createSlice({
  name: "Organization",
  initialState,
  reducers: {
    // Add organization
    addOrganization: (state) => {
      state.upsertOrganizationSuccesss = false;
      state.addOrganizationLoading = true;
    },

    addOrganizationSuccess: (state) => {
      state.upsertOrganizationSuccesss = true;
      state.addOrganizationLoading = false;
    },

    addOrganizationFailure: (state, action) => {
      state.addOrganizationLoading = false;
      state.addOrganizationError = action.payload.error;
    },

    // Update organization
    updateOrganization: (state) => {
      state.upsertOrganizationSuccesss = false;
      state.updateOrganizationLoading = true;
    },

    updateOrganizationSuccess: (state) => {
      state.upsertOrganizationSuccesss = true;
      state.updateOrganizationLoading = false;
    },

    updateOrganizationFailure: (state, action) => {
      state.updateOrganizationLoading = false;
      state.updateOrganizationStatusError = action.payload.error;
    },

    // Get organization information
    getOrganizationInfo: (state) => {
      state.organizationInfoLoading = true;
    },

    getOrganizationInfoSuccess: (state, action) => {
      state.organizationInfoLoading = false;
      state.organizationInfo = action.payload.organizationInfo;
    },

    getOrganizationInfoFailure: (state, action) => {
      state.organizationInfoLoading = false;
      state.organizationInfoError = action.payload.error;
    },

    // Update organization status (Enable/Disable)
    updateOrganizationStatus: (state, action) => {
      const { orgId } = action.payload;
      state.updateOrganizationStatusSuccessMap[orgId] = false;
      state.updateOrganizationStatusLoadingMap[orgId] = true;
      state.updateOrganizationStatusError = "";
    },

    updateOrganizationStatusSuccess: (state, action) => {
      const { orgId } = action.payload;
      state.updateOrganizationStatusSuccessMap[orgId] = true;
      delete state.updateOrganizationStatusLoadingMap[orgId];
    },

    updateOrganizationStatusFailure: (state, action) => {
      const { orgId, error } = action.payload;
      delete state.updateOrganizationStatusLoadingMap[orgId];
      state.updateOrganizationStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const OrganizationReducer = organizationSlice.reducer;

// Actions
export const OrganizationActions = organizationSlice.actions;
