import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
import { PlantElectricityBillActions } from "../../../../redux-slice/plant/ElectricityBillSlice";

// Utils
import { getMonthName } from "../../../../utils/DateUtils";
import tableSerialNumber from "../../../../utils/TableUtils";

// Components
import { Button } from "../../../../components/button/Button";
import TableHeaders from "../../../../components/table/TableHeader";
import TablePagination from "../../../../components/table/TablePagination";
import TableLoader from "../../../../components/table/TableLoader";
import TableDataNotFound from "../../../../components/table/TableDataNotFound";

// Sections
import PlantBillsTableActionButton from "../PlantBillsTableActionButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Serial No",
    },
  },
  {
    title: {
      displayName: "Date",
    },
  },
  {
    title: {
      displayName: "Demand Sanctioned",
    },
  },
  {
    title: {
      displayName: "Demand Recorded",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( Solar )",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( IEX )",
    },
  },
  {
    title: {
      displayName: "Units Consumed ( Electricity Board )",
    },
  },
  {
    title: {
      displayName: "Units Consumed",
    },
  },
  {
    title: {
      displayName: "Unit Cost",
    },
  },
  {
    title: {
      displayName: "Total Cost",
    },
  },
  {
    title: {
      displayName: "Power Factor",
    },
  },
  {
    title: {
      displayName: "",
    },
  },
];

// Page Components
function ElectricityBillListTableActionCell({
  setSelectedElectricityBill,
  electricityBill = {},
  setShowViewElectricityBillModal = () => {},
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId } = params;

  // Electricity Bill Information
  const { id: electricityBillId = "", fileUrl = "" } = electricityBill;

  // Upload Electricity Bill Selector State
  const uploadPlantElectricityBillLoading = useSelector(
    (state) => state.plantElecBill.uploadPlantElectricityBillLoading[electricityBillId]
  );
  const uploadPlantElectricityBillSuccess = useSelector(
    (state) => state.plantElecBill.uploadPlantElectricityBillSuccess[electricityBillId]
  );

  // Download Electricity Bill Selector State
  const downloadPlantElectricityBillLoading = useSelector(
    (state) => state.plantElecBill.downloadPlantElectricityBillLoading[electricityBillId]
  );

  // Checking whether file is present or not
  const isFilePresent = fileUrl || "";

  // After selecting the file dispatch is called
  function uploadFile(file) {
    // Creating form data
    const formData = new FormData();
    formData.append("file", file);

    // Dispatch
    dispatch(PlantElectricityBillActions.uploadPlantElectricityBill({ formData, plantId, electricityBillId }));
  }

  // Download Electricity Bill Function
  function downloadPlantElectiricityBill() {
    dispatch(PlantElectricityBillActions.downloadPlantElectricityBill({ electricityBillId, apiUrl: fileUrl }));
  }

  // Open Electricity Bill Edit Modal Function
  function openElectricityBillEditModal() {
    setSelectedElectricityBill(electricityBill);
    setShowViewElectricityBillModal(true);
  }

  useEffect(() => {
    if (uploadPlantElectricityBillSuccess) {
      toast.success("Electricity Bill Uploaded Successfully");
    }
  }, [uploadPlantElectricityBillSuccess]);

  return (
    <td>
      {/* Button */}
      <Button label="View" size="sm" onClick={openElectricityBillEditModal}>
        <i className="fa fa-eye me-2" />
      </Button>

      {/* Plant Bills Table Action Button */}
      <PlantBillsTableActionButton
        uploadFile={uploadFile}
        isFilePresent={isFilePresent}
        fileUploadLoadingStatus={uploadPlantElectricityBillLoading}
      />

      {/* Download Button */}
      {isFilePresent && (
        <Button
          label="Download"
          color="dark"
          size="sm"
          onClick={downloadPlantElectiricityBill}
          loading={downloadPlantElectricityBillLoading}
          disabled={downloadPlantElectricityBillLoading}
        >
          <i className="fa fa-download" />
        </Button>
      )}
    </td>
  );
}

function ElectricityBillListTableRow({
  electricityBill = {},
  serialNo,
  setSelectedElectricityBill = [],
  setShowViewElectricityBillModal = () => {},
}) {
  const {
    year = "",
    month = "",
    demandSanctioned = "",
    demandRecorded = "",
    unitsConsumedSolar = "",
    unitsConsumedIEX = "",
    unitsConsumedElectricityBoard = "",
    unitsConsumed = "",
    unitCost = "",
    totalCost = "",
    powerFactor = "",
  } = electricityBill;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{`${getMonthName(month)} , ${year}`}</td>
      <td>{demandSanctioned}</td>
      <td>{demandRecorded}</td>
      <td>{unitsConsumedSolar}</td>
      <td>{unitsConsumedIEX}</td>
      <td>{unitsConsumedElectricityBoard}</td>
      <td>{unitsConsumed}</td>
      <td>{unitCost}</td>
      <td>{totalCost}</td>
      <td>{powerFactor}</td>

      {/* Electricity Bill List Table Action Cell */}
      <ElectricityBillListTableActionCell
        setSelectedElectricityBill={setSelectedElectricityBill}
        electricityBill={electricityBill}
        setShowViewElectricityBillModal={setShowViewElectricityBillModal}
      />
    </tr>
  );
}

function ElectricityBillListTableBody({
  plantElectricityBillListLoading,
  plantElectricityBillList,
  setSelectedElectricityBill,
  pageNumber,
  pageSize,
  setShowViewElectricityBillModal = () => {},
}) {
  // Loader
  if (plantElectricityBillListLoading) {
    return <TableLoader colSpan={16} />;
  }

  // If there is no electricity bill list
  if (plantElectricityBillList.length === 0) {
    return <TableDataNotFound message="No electricity bills present" colSpan={16} />;
  }

  // Electricity bill list table row
  return (
    <>
      {plantElectricityBillList.map((electricityBill, index) => {
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return (
          <ElectricityBillListTableRow
            key={electricityBill.id}
            electricityBill={electricityBill}
            serialNo={serialNo}
            setSelectedElectricityBill={setSelectedElectricityBill}
            setShowViewElectricityBillModal={setShowViewElectricityBillModal}
          />
        );
      })}
    </>
  );
}

/**
 * Plant Electricity Bill List Table
 */
export default function PlantElectricityBillListTable({
  setSelectedElectricityBill,
  setShowViewElectricityBillModal = () => {},
}) {
  // Thermal Bill Selector State
  const plantElectricityBillList = useSelector((state) => state.plantElecBill.plantElectricityBillList);
  const plantElectricityBillListLoading = useSelector((state) => state.plantElecBill.plantElectricityBillListLoading);
  const plantElectricityBillListPagination = useSelector(
    (state) => state.plantElecBill.plantElectricityBillListPagination
  );

  const { totalCount, pageNo, pageSize } = plantElectricityBillListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Electricity Bill List Table Body */}
          <ElectricityBillListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            plantElectricityBillListLoading={plantElectricityBillListLoading}
            plantElectricityBillList={plantElectricityBillList}
            setSelectedElectricityBill={setSelectedElectricityBill}
            setShowViewElectricityBillModal={setShowViewElectricityBillModal}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
