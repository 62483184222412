import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Custom Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { AuditObservationAndRecommendationActions } from "../../../../redux-slice/audit/ObservationAndRecommendationSlice";

// Constants
import { DataPreLoadKeys, QueryParamsKeys } from "../../../../constants/GeneralConstants";

// Utils
import { getItem } from "../../../../app/LocalStorage";

// Utils
import DecimalUtils from "../../../../utils/DecimalUtils";

// Components
import TableDataNotFound from "../../../../components/table/TableDataNotFound";
import TableHeaders from "../../../../components/table/TableHeader";
import Input from "../../../../components/input/Input";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Recommendation Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Energy Savings",
    },
  },
  {
    title: {
      displayName: "Cost Savings (INR)",
    },
  },
  {
    title: {
      displayName: "Investment (INR)",
    },
  },
  {
    title: {
      displayName: "Investment Returns in (Months)",
    },
  },
];

// Page Components
function AuditSavingsInvestmentActionCell({ investment = "", id = "" }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // State
  const [investmentVal, setInvestmentVal] = useState(investment);

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  const updateRecInvestmentLoading =
    useSelector((state) => state.auditObsAndRec.updateRecInvestmentLoading[id]) || false;
  const updateRecInvestmentSuccess =
    useSelector((state) => state.auditObsAndRec.updateRecInvestmentSuccess[id]) || false;

  // Component Info
  const { id: selectedComponentId = "" } = componentInfo;

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) || "";
  const pageSize = searchParams.get(QueryParamsKeys.pageSize) || "";

  useEffect(() => {
    if (updateRecInvestmentSuccess) {
      toast.success("Investment Updated Successfully");
    }
  }, [updateRecInvestmentSuccess]);

  // Submit Investment
  function submitInvestment() {
    dispatch(
      AuditObservationAndRecommendationActions.updateAuditRecInvestment({
        auditId,
        recommendationId: id,
        investment: investmentVal,
        componentId: selectedComponentId,
        pageNumber,
        pageSize,
      })
    );
  }

  return (
    <td>
      {/* Investment Input */}
      <Input
        value={investmentVal}
        onChange={({ target }) => setInvestmentVal(target.value)}
        onBlur={submitInvestment}
        placeholder="Enter Investment Amount"
        loading={updateRecInvestmentLoading}
        disabled={updateRecInvestmentLoading}
        spinnerClassName="spinner-border-sm"
      />
    </td>
  );
}

function AuditSavingsCalculationTableRow({ recommendation = {} }) {
  const {
    id = "",
    code = "",
    description = "",
    savingsEnergy = "--",
    savingsCost = "--",
    investment = "",
    investmentReturns = "--",
    energySavingsFormulaStr = "",
  } = recommendation;

  return (
    <tr>
      <td>
        {code}
        {energySavingsFormulaStr && (
          <i className="fa-solid fa-circle-info fa-sm ms-2" title={energySavingsFormulaStr}></i>
        )}
      </td>
      <td>{description}</td>
      <td>{DecimalUtils.fixDecimal(savingsEnergy, 0)}</td>
      <td>{DecimalUtils.fixDecimal(savingsCost, 0)}</td>

      {/* Audit Savings Investment Action Cell */}
      <AuditSavingsInvestmentActionCell investment={investment} id={id} />

      <td>{DecimalUtils.fixDecimal(investmentReturns, 0)}</td>
    </tr>
  );
}

function AuditSavingsCalculationTableBody({ recommendationList = [] }) {
  // If there is no recommendation
  if (recommendationList.length === 0) {
    return <TableDataNotFound message="No recommendations found !!" colSpan={5} />;
  }

  return recommendationList.map((recommendation) => {
    return <AuditSavingsCalculationTableRow key={recommendation?.id} recommendation={recommendation} />;
  });
}

/**
 * Audit Savings Recommendation List Table
 */
export default function AuditSavingsRecommendationListTable() {
  const dispatch = useDispatch();

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Audit Observation And Recommendation List Selector State
  const auditObsAndRecList = useSelector((state) => state.auditObsAndRecList.auditObsAndRecList);

  // Recommendation Codes Data
  const { recommendationCodesByComponent = {} } =
    JSON.parse(getItem(DataPreLoadKeys.RECOMMENDATION_CODES || "{}")) || {};

  // Component Info
  const { id: selectedComponentId = "", componentTypeId: selectedComponentTypeId = "" } = componentInfo;

  // use Memo
  const recCodeList = useMemo(() => {
    return recommendationCodesByComponent[selectedComponentTypeId] || [];
  }, [selectedComponentTypeId]);

  // use Memo
  const selectedRecommendationList = useMemo(() => {
    // For each observation there will be recommendations
    // All recommendations are grouped
    const allRecommendations = auditObsAndRecList.reduce((acc, eachObservation) => {
      const { recommendations = [] } = eachObservation || {};

      return [...acc, ...recommendations];
    }, []);

    return allRecommendations.map((eachRecommendation) => {
      const { codeId = "" } = eachRecommendation || {};

      const { description = "", energySavingsFormulaStr = "" } =
        recCodeList.find((recommendation) => recommendation.id === codeId) || {};

      return { ...eachRecommendation, description, energySavingsFormulaStr };
    });
  }, [auditObsAndRecList, recCodeList, selectedComponentId, selectedComponentTypeId]);

  useEffect(() => {
    return () => dispatch(AuditObservationAndRecommendationActions.resetAllApiSuccessState());
  }, [dispatch]);

  return (
    <div className="table-responsive">
      <table className="table">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Audit Savings Calculation Table Body */}
          <AuditSavingsCalculationTableBody recommendationList={selectedRecommendationList} />
        </tbody>
      </table>
    </div>
  );
}
