import * as yup from "yup";

// Utils
import { generateYears } from "../utils/DateUtils";

// Validation Schema
export const ElectricityBillFormValidationSchema = yup.object({
  serviceNumber: yup.string().required("Please enter service number !!"),
  year: yup.string().required("Please select year !!"),
  month: yup.string().required("Please select month !!"),
  unitsConsumed: yup.string().required("Please fill the units consumed !!"),
  totalCost: yup.string().required("Please fill the total cost !!"),
  powerFactor: yup.string().required("Please fill the power factor !!"),
});

// Form Inputs
export const ElectricityBillFormSchema = [
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Service Number",
        key: "serviceNumber",
        placeholder: "Enter service number...",
        isMandotary: true,
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Year",
        key: "year",
        type: "select",
        options: generateYears(2020),
        placeholder: "Enter year...",
        isMandotary: true,
      },
      {
        label: "Month",
        key: "month",
        type: "select",
        options: [...Array(12).fill(null)].map((_, index) => ({ value: index + 1 })), // For 12 months
        placeholder: "Select month...",
        isMandotary: true,
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Demand Sanctioned (kVA)",
        key: "demandSanctioned",
        placeholder: "Select demand sanctioned...",
      },
      {
        label: "Demand Recorded (kVA)",
        key: "demandRecorded",
        placeholder: "Enter demand recorded...",
      },
    ],
  },
  {
    containerClassName: "row",
    title: "Consumption",
    titleClassName: "fw-bold",
    inputItems: [
      {
        label: "Units Consumed - Electricity Board (kWh)",
        key: "unitsConsumedElectricityBoard",
        placeholder: "Enter units consumed electricity board...",
      },
      {
        label: "Units Consumed - Solar (kWh)",
        key: "unitsConsumedSolar",
        placeholder: "Enter units consumed wind...",
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Units Consumed - IEX (kWh)",
        key: "unitsConsumedIEX",
        placeholder: "Enter units consumed iex...",
      },
      {
        label: "Units Adjusted For Wind (kWh)",
        key: "unitsAdjustedForWind",
        placeholder: "Enter units adjusted for wind...",
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Total Units Consumed (kWh)",
        key: "unitsConsumed",
        placeholder: "Enter units consumed...",
        isMandotary: true,
      },
      {
        label: "Total Cost (INR)",
        key: "totalCost",
        placeholder: "Enter total cost...",
        isMandotary: true,
      },
    ],
  },
  {
    containerClassName: "row",
    title: "Generation",
    titleClassName: "fw-bold",
    inputItems: [
      {
        label: "Units Generated - Solar (kWh)",
        key: "unitsGeneratedSolar",
        placeholder: "Enter units generated solar...",
      },
      {
        label: "Units Generated - Genset (kWh)",
        key: "unitsGeneratedGenset",
        placeholder: "Enter units generated genset...",
      },
      {
        label: "Units Generated - Captive (kWh)",
        key: "unitsGeneratedCaptive",
        placeholder: "Enter units generated captive...",
      },
      {
        label: "Power Exported",
        key: "isPowerExported",
        placeholder: "Enter power exported...",
        type: "boolean-select",
      },
      {
        label: "Units Exported",
        key: "unitsExported",
        placeholder: "Enter units exported...",
      },
    ],
  },
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "Power Factor",
        key: "powerFactor",
        placeholder: "Enter power factor...",
        isMandotary: true,
      },
    ],
  },
];
