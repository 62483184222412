import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.upsertPlantElectricityBillSucess = false;
  state.uploadPlantElectricityBillSuccess = {};
}

// Initial State
const initialState = {
  // Get plant Electricity bill list
  plantElectricityBillList: [],
  plantElectricityBillListPagination: {},
  plantElectricityBillListLoading: false,
  plantElectricityBillListError: "",

  // Upsert plant electricty bill
  upsertPlantElectricityBillLoading: false,
  upsertPlantElectricityBillSucess: false,
  upsertPlantElectricityBillError: {},

  // Upload plant electricty bill
  uploadPlantElectricityBillLoading: {},
  uploadPlantElectricityBillSuccess: {},
  uploadPlantElectrictyBillError: "",

  // Download plant electricty bill
  downloadPlantElectricityBillLoading: {},
  downloadPlantElectrictyBillError: "",
};

// Plant Electricity Bill Slice
const plantElectricityBill = createSlice({
  name: "PlantElectricityBill",
  initialState,
  reducers: {
    // Get plant Electricity bill list
    getPlantElectricityBillList: (state) => {
      state.plantElectricityBillListLoading = true;
    },

    getPlantElectricityBillListSuccess: (state, action) => {
      state.plantElectricityBillListLoading = false;
      state.plantElectricityBillList = action.payload.plantElectricityBillList;
      state.plantElectricityBillListPagination = action.payload.plantElectricityBillListPagination;
    },

    getPlantElectricityBillListFailure: (state, action) => {
      state.plantElectricityBillListLoading = false;
      state.plantElectricityBillListError = action.payload.error;
    },

    // Create plant electricty bill
    createPlantElectricityBill: (state) => {
      state.upsertPlantElectricityBillSucess = false;
      state.upsertPlantElectricityBillLoading = true;
    },

    createPlantElectricityBillSuccess: (state) => {
      state.upsertPlantElectricityBillSucess = true;
      state.upsertPlantElectricityBillLoading = false;
    },

    createPlantElectricityBillFailure: (state, action) => {
      state.upsertPlantElectricityBillLoading = false;
      state.upsertPlantElectricityBillError = action.payload.error;
    },

    // Update plant electricty bill
    updatePlantElectricityBill: (state) => {
      state.upsertPlantElectricityBillSucess = false;
      state.upsertPlantElectricityBillLoading = true;
    },

    updatePlantElectricityBillSuccess: (state) => {
      state.upsertPlantElectricityBillSucess = true;
      state.upsertPlantElectricityBillLoading = false;
    },

    updatePlantElectricityBillFailure: (state, action) => {
      state.upsertPlantElectricityBillLoading = false;
      state.upsertPlantElectricityBillError = action.payload.error;
    },

    // Upload plant electricty bill
    uploadPlantElectricityBill: (state, action) => {
      const { electricityBillId } = action.payload;
      state.uploadPlantElectricityBillSuccess[electricityBillId] = false;
      state.uploadPlantElectricityBillLoading[electricityBillId] = true;
      state.uploadPlantElectrictyBillError = "";
    },

    uploadPlantElectricityBillSuccess: (state, action) => {
      const { electricityBillId } = action.payload;
      state.uploadPlantElectricityBillSuccess[electricityBillId] = true;
      delete state.uploadPlantElectricityBillLoading[electricityBillId];
    },

    uploadPlantElectricityBillFailure: (state, action) => {
      const { electricityBillId, error } = action.payload;
      delete state.uploadPlantElectricityBillLoading[electricityBillId];
      state.uploadPlantElectrictyBillError = error;
    },

    // Download plant electricty bill
    downloadPlantElectricityBill: (state, action) => {
      const { electricityBillId = "" } = action.payload;
      state.downloadPlantElectricityBillLoading[electricityBillId] = true;
      state.uploadPlantElectrictyBillError = "";
    },

    downloadPlantElectricityBillSuccess: (state, action) => {
      const { electricityBillId = "" } = action.payload;
      delete state.downloadPlantElectricityBillLoading[electricityBillId];
    },

    downloadPlantElectricityBillFailure: (state, action) => {
      const { electricityBillId = "", error } = action.payload;
      delete state.downloadPlantElectricityBillLoading[electricityBillId];
      state.uploadPlantElectrictyBillError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantElectricityBillReducer = plantElectricityBill.reducer;

// Actions
export const PlantElectricityBillActions = plantElectricityBill.actions;
