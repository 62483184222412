// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Utils
import URLs from "../../app/utils/URLs";

// Urls
import { getAuditListByAuditorUrl, getAuditListUrl } from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Get Audit-List
export async function getAuditListApi(orgId, plantId, pageNumber, pageSize, filters = {}) {
  const params = {
    pn: pageNumber || DefaultPagination.pageNumber,
    ps: pageSize || DefaultPagination.pageSize,
    org: orgId,
  };

  if (plantId) {
    params.plant = plantId;
  }

  const isFiltersPresent = Object.keys(filters).length > 0;

  if (isFiltersPresent) {
    Object.assign(params, filters);
  }

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.get(getAuditListUrl, params);
}

// Get Audit-List By Auditor
export async function getAuditListByAuditorApi(userId) {
  const url = URLs.format(getAuditListByAuditorUrl, { userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}
