import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.updateProductStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Update Product Status (Enable/Disable)
  updateProductStatusLoadingMap: {},
  updateProductStatusSuccessMap: {},
  updateProductStatusError: "",
};

// Product Slice
const productSlice = createSlice({
  name: "Product",
  initialState,
  reducers: {
    // Update Product Status (Enable/Disable)
    updateProductStatus: (state, action) => {
      const { productId } = action.payload;
      state.updateProductStatusSuccessMap[productId] = false;
      state.updateProductStatusLoadingMap[productId] = true;
      state.updateProductStatusError = "";
    },

    updateProductStatusSuccess: (state, action) => {
      const { productId } = action.payload;
      state.updateProductStatusSuccessMap[productId] = true;
      delete state.updateProductStatusLoadingMap[productId];
    },

    updateProductStatusFailure: (state, action) => {
      const { productId, error } = action.payload;
      delete state.updateProductStatusLoadingMap[productId];
      state.updateProductStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const ProductReducer = productSlice.reducer;

// Actions
export const ProductActions = productSlice.actions;
