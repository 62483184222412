import { combineReducers } from "redux";

// Auth Reducer
import { AuthReducer } from "./auth/AuthSlice";

// Audit Reducer - Audit
import { AuditReducer } from "./audit/AuditSlice";
import { AuditListReducer } from "./audit/AuditListSlice";

// Audit Reducer - Component
import { AuditComponentReducer } from "./audit/ComponentSlice";
import { AuditComponentListReducer } from "./audit/ComponentListSlice";

// Audit Reducer - Instrument
import { AuditInstrumentReducer } from "./audit/InstrumentSlice";
import { AuditInstrumentListReducer } from "./audit/InstrumentListSlice";

// Audit Reducer - Observation and Recommendation Code
import { AuditObservationAndRecommendationReducer } from "./audit/ObservationAndRecommendationSlice";
import { AuditObservationAndRecommendationListReducer } from "./audit/ObservationAndRecommendationListSlice";

// Audit Reducer - Parameter Value
import { AuditParameterValuesReducer } from "./audit/ParameterValuesSlice";

// Audit Reducer - Data
import { AuditDataReducer } from "./audit/DataSlice";

// Audit Reducer - Reports
import { AuditReportsReducer } from "./audit/ReportsSlice";
import { AuditReportsListReducer } from "./audit/ReportsListSlice";

// Audit Reducer - File
import { AuditFileReducer } from "./audit/FileSlice";

// Component Reducer
import { ComponentTypeReducer } from "./component-type/ComponentTypeSlice";
import { ComponentTypeListReducer } from "./component-type/ComponentTypeListSlice";

// Measurement Reducer
import { MeasurementTypeReducer } from "./measurement-type/MeasurementTypeSlice";
import { MeasurementTypeListReducer } from "./measurement-type/MeasurementTypeListSlice";

// Instrument Reducer
import { InstrumentReducer } from "./instrument/InstrumentSlice";
import { InstrumentListReducer } from "./instrument/InstrumentListSlice";

// Job Reducer
import { JobReducer } from "./job/JobSlice";
import { JobListReducer } from "./job/JobListSlice";

// Material Reducer
import { MaterialReducer } from "./material/MaterialSlice";
import { MaterialListReducer } from "./material/MaterialListSlice";

// Organization Reducer
import { OrganizationReducer } from "./organization/OrganizationSlice";
import { OrganizationListReducer } from "./organization/OrganizationListSlice";

// User Reducer
import { UserReducer } from "./user/UserSlice";
import { UserListReducer } from "./user/UserListSlice";

// Plant Reducer - Plant
import { PlantReducer } from "./plant/PlantSlice";
import { PlantListReducer } from "./plant/PlantListSlice";

// Plant Reducer - Plant Component
import { PlantComponentReducer } from "./plant/ComponentSlice";
import { PlantComponentListReducer } from "./plant/ComponentListSlice";

// Plant Reducer - Electricity Bill
import { PlantElectricityBillReducer } from "./plant/ElectricityBillSlice";

// Plant Reducer - Thermal Bill
import { PlantThermalBillReducer } from "./plant/ThermalBillSlice";

// Plant Reducer - Component Parts
import { PlantComponentPartsReducer } from "./plant/PartsSlice";

// Plant Reducer - File
import { PlantFileReducer } from "./plant/FileSlice";

// Product Reducer
import { ProductReducer } from "./product/ProductSlice";
import { ProductListReducer } from "./product/ProductListSlice";

// Constant Reducer
import { ConstantListReducer } from "./constant/ConstantListSlice";

// Role Reducer
import { RoleReducer } from "./role/RoleSlice";
import { RoleListReducer } from "./role/RoleListSlice";

// HSN Code Reducer
import { HSNCodeListReducer } from "./hsn-code/HSNCodeListSlice";

// NIC Code Reducer
import { NICCodeListReducer } from "./nic-code/NICCodeListSlice";

// App Reducer
import { AppRootReducer } from "../app/AppRootReducer";

export const RootReducer = combineReducers({
  // Auth Reducer
  auth: AuthReducer,

  // Audit Reducer
  audit: AuditReducer,
  auditList: AuditListReducer,
  auditComponent: AuditComponentReducer,
  auditComponentList: AuditComponentListReducer,
  auditInstrument: AuditInstrumentReducer,
  auditInstrumentList: AuditInstrumentListReducer,
  auditObsAndRec: AuditObservationAndRecommendationReducer,
  auditObsAndRecList: AuditObservationAndRecommendationListReducer,
  auditParameterValues: AuditParameterValuesReducer,
  auditData: AuditDataReducer,
  auditReports: AuditReportsReducer,
  auditReportsList: AuditReportsListReducer,
  auditFile: AuditFileReducer,

  // Component Reducer
  componentType: ComponentTypeReducer,
  componentTypeList: ComponentTypeListReducer,

  // Job Reducer
  job: JobReducer,
  jobList: JobListReducer,

  // Material Reducer
  material: MaterialReducer,
  materialList: MaterialListReducer,

  // Measurement Type Reducer
  measurementType: MeasurementTypeReducer,
  measurementTypeList: MeasurementTypeListReducer,

  // Instrument Reducer
  instrumentList: InstrumentListReducer,
  instrument: InstrumentReducer,

  // Organization Reducer
  organization: OrganizationReducer,
  organizationList: OrganizationListReducer,

  // Plant Reducer
  plant: PlantReducer,
  plantList: PlantListReducer,
  plantComponent: PlantComponentReducer,
  plantComponentList: PlantComponentListReducer,
  plantElecBill: PlantElectricityBillReducer,
  plantThermalBill: PlantThermalBillReducer,
  plantComponentParts: PlantComponentPartsReducer,
  plantFile: PlantFileReducer,

  // User Reducer
  user: UserReducer,
  userList: UserListReducer,

  // Product Reducer
  product: ProductReducer,
  productList: ProductListReducer,

  // Constant Reducer
  constantList: ConstantListReducer,

  // Role Reducer
  role: RoleReducer,
  roleList: RoleListReducer,

  // HSN Code Reducer
  hsnCodeList: HSNCodeListReducer,

  // NIC Code Reducer
  nicCodeList: NICCodeListReducer,

  ...AppRootReducer,
});
