import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Constants
import { DefaultPagination } from "../../constants/GeneralConstants";

// Utils
import tableSerialNumber from "../../utils/TableUtils";

// Components
import { Button } from "../../components/button/Button";
import TableDataNotFound from "../../components/table/TableDataNotFound";
import TableHeaders from "../../components/table/TableHeader";
import TableLoader from "../../components/table/TableLoader";
import TablePagination from "../../components/table/TablePagination";

// Section
import FileUploadModal from "./FileUploadModal";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "File Type",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Size",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

// Page Components
function FileListActionCell({ file = {}, setSelectedFile, fileReduceKey, dispatchActions, setShowModal = () => {} }) {
  const dispatch = useDispatch();

  // File Information
  const { id = "", name = "", url = "" } = file;

  // Download File Selector State
  const downloadFileLoading = useSelector((state) => state[fileReduceKey].downloadFileLoading[id]);

  // Delete File Selector State
  const deleteFileLoading = useSelector((state) => state[fileReduceKey].deleteFileLoading[id]);
  const deleteFileSuccess = useSelector((state) => state[fileReduceKey].deleteFileSuccess[id]);

  // Dispatch Actions
  const { downloadFileFn = () => {}, deleteFileFn = () => {}, resetApiSuccessStateFn = () => {} } = dispatchActions;

  // Open File Upload Modal
  function openFileUploadEditModal() {
    setSelectedFile(file);
    setShowModal(true);
  }

  useEffect(() => {
    if (deleteFileSuccess) {
      toast.success("File deleted successfully");
      dispatch(resetApiSuccessStateFn({ fileId: id }));
    }
  }, [dispatch, deleteFileSuccess]);

  return (
    <td>
      <div className="btn-cont">
        {/* View File Button */}
        <Button label="View" size="sm" onClick={openFileUploadEditModal}>
          <i className="fas fa-eye"></i>
        </Button>

        {/* Download File Button */}
        {downloadFileFn && (
          <Button
            label="Download"
            color="dark"
            size="sm"
            onClick={() => downloadFileFn(id, name, url)}
            loading={downloadFileLoading}
            disabled={downloadFileLoading}
          >
            <i className="fas fa-download"></i>
          </Button>
        )}

        {/* Delete File Button */}
        {deleteFileFn && (
          <Button
            label="Delete"
            color="danger"
            size="sm"
            onClick={() => deleteFileFn(id)}
            loading={deleteFileLoading}
            disabled={deleteFileLoading}
          >
            <i className="fas fa-trash"></i>
          </Button>
        )}
      </div>
    </td>
  );
}

function FileListTableRow({
  serialNo,
  file = {},
  fileTypes = {},
  setSelectedFile,
  fileReduceKey,
  dispatchActions,
  setShowModal = () => {},
}) {
  // File Information
  const { type = "", name = "", size = "" } = file;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{fileTypes[type]}</td>
      <td>{name}</td>
      <td>{`${size} bytes`}</td>

      {/* File List Action Cell  */}
      <FileListActionCell
        file={file}
        setSelectedFile={setSelectedFile}
        fileReduceKey={fileReduceKey}
        dispatchActions={dispatchActions}
        setShowModal={setShowModal}
      />
    </tr>
  );
}

function FileListTableBody({
  fileList = [],
  fileListLoading = false,
  fileTypes = {},
  pageSize = 1,
  pageNumber = 1,
  setSelectedFile = () => {},
  fileReduceKey = "",
  dispatchActions,
  setShowModal = () => {},
}) {
  if (fileListLoading) {
    return <TableLoader colSpan={6} />;
  }

  if (fileList.length === 0) {
    return <TableDataNotFound message="No files present" colSpan={6} />;
  }

  return (
    <>
      {fileList.map((file, index) => {
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return (
          <FileListTableRow
            key={file.id}
            serialNo={serialNo}
            file={file}
            fileTypes={fileTypes}
            setSelectedFile={setSelectedFile}
            fileReduceKey={fileReduceKey}
            dispatchActions={dispatchActions}
            setShowModal={setShowModal}
          />
        );
      })}
    </>
  );
}

function FileListHeader({ setSelectedFile, dispatchActions, setShowModal = () => {} }) {
  const { uploadFileFn = "" } = dispatchActions;

  function openFileUploadCreateModal() {
    setSelectedFile({});
    setShowModal(true);
  }

  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        <h4>Uploaded Files</h4>
      </div>

      {uploadFileFn && (
        <Button label="Upload File" color="dark" onClick={openFileUploadCreateModal}>
          <i className="fa-solid fa-plus me-2"></i>
        </Button>
      )}
    </div>
  );
}

/**
 * File Upload And List Section
 */
export default function FileUploadAndListSection({ fileReduceKey = "", dispatchActions = {}, fileTypes = {} }) {
  // State
  const [selectedFile, setSelectedFile] = useState({});
  const [showModal, setShowModal] = useState(false);

  // Selector State
  const fileList = useSelector((state) => state[fileReduceKey].fileList) || [];
  const fileListLoading = useSelector((state) => state[fileReduceKey].fileListLoading) || false;
  const fileListPagination = useSelector((state) => state[fileReduceKey].fileListPagination) || {};

  // Pagination
  const {
    pageNo = DefaultPagination.pageNumber,
    pageSize = DefaultPagination.pageSize,
    totalCount = DefaultPagination.totalCount,
  } = fileListPagination || {};

  return (
    <>
      <div className="page-content">
        <FileListHeader
          setSelectedFile={setSelectedFile}
          dispatchActions={dispatchActions}
          setShowModal={setShowModal}
        />

        <div className="table-responsive">
          <table className="table table-bordered border-end">
            <TableHeaders tableHeaders={tableHeaders} />

            <tbody>
              <FileListTableBody
                fileList={fileList}
                fileListLoading={fileListLoading}
                pageNumber={pageNo}
                pageSize={pageSize}
                fileTypes={fileTypes}
                setSelectedFile={setSelectedFile}
                fileReduceKey={fileReduceKey}
                dispatchActions={dispatchActions}
                setShowModal={setShowModal}
              />
            </tbody>
          </table>

          <TablePagination pageNumber={pageNo} pageSize={pageSize} totalCount={totalCount} />
        </div>
      </div>

      <FileUploadModal
        selectedFile={selectedFile}
        reducerKey={fileReduceKey}
        dispatchActions={dispatchActions}
        fileTypes={fileTypes}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
