import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { AuditObservationAndRecommendationListActions } from "../../../../redux-slice/audit/ObservationAndRecommendationListSlice";
import { AuditParameterValuesActions } from "../../../../redux-slice/audit/ParameterValuesSlice";

// Utils
import { getComponentTypeParameters } from "../../../../utils/DataPreLoadUtils";
import {
  constructFormDataObject,
  constructParameterValuesArray,
  getParametersFormInputs,
} from "../../../../utils/ComponentUtils";

// Component
import ComponentSelect from "../ComponentSelect";
import TabularForm from "../../../../components/form/TabularForm";
import Loader from "../../../../components/loader/Loader";

// Section
import AuditSavingsRecommendationListTable from "./AuditSavingsRecommendationListTable";

// Page Constants
const savingsTableHeaders = [
  {
    title: {
      displayName: "Parameters",
    },
  },
  {
    title: {
      displayName: "Value",
    },
  },
  {
    title: {
      displayName: "Units",
    },
  },
];

// Page Components
function SavingsCalculationTabularForm({
  savingsParameterList = [],
  loading = false,
  isSavingsParameterPresent = false,
}) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Audit Component Info Selector State
  const { component: componentInfo = {}, ...auditComponentInfo } = useSelector(
    (state) => state.auditComponent.auditComponentInfo
  );

  // Component Info
  const { id: selectedComponentId = "", componentTypeId: selectedComponentTypeId = "" } = componentInfo;

  // Getting component information
  const { savings = [] } = auditComponentInfo;

  // Form Inputs
  const savingsFormInputs = useMemo(() => {
    // Table Config
    const tableConfig = {
      showUnits: false,
      isInputPresent: true,
      isUnitConversionPresent: true,
    };

    return getParametersFormInputs(savingsParameterList, savings, tableConfig, selectedComponentId) || [];
  }, [selectedComponentId, selectedComponentTypeId, auditComponentInfo]);

  // Form Data for savings
  const savingsFormData = useMemo(() => {
    return constructFormDataObject(savingsParameterList, savings, selectedComponentId);
  }, [auditComponentInfo]);

  // Savings Parameters Form Submit
  function formSubmit(formData) {
    // Constructing savings array
    const savingsArray = constructParameterValuesArray(savingsParameterList, formData, selectedComponentId);

    // Dispatch
    dispatch(
      AuditParameterValuesActions.upsertParameterValues({
        auditId,
        componentId: selectedComponentId,
        parameterValuesData: savingsArray,
        type: "savings",
      })
    );
  }

  if (!isSavingsParameterPresent) {
    return null;
  }

  return (
    <div className="col-4">
      <TabularForm
        tableHeaders={savingsTableHeaders}
        formInputItems={savingsFormInputs}
        data={savingsFormData}
        loading={loading}
        formSubmit={formSubmit}
      />
    </div>
  );
}

/**
 * Audit Savings Calculation Page
 */
export default function AuditSavingsCalculationPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Get Path  Params
  const { auditId } = useParams();

  // Audit Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  const auditObsAndRecListLoading = useSelector((state) => state.auditObsAndRecList.auditObsAndRecListLoading);
  const auditComponentInfoLoading = useSelector((state) => state.auditComponent.auditComponentInfoLoading);
  const upsertParameterValuesLoading = useSelector((state) => state.auditParameterValues.upsertParameterValuesLoading);
  const upsertParameterValuesSuccess = useSelector((state) => state.auditParameterValues.upsertParameterValuesSuccess);

  // Component Info
  const { id: selectedComponentId = "", componentTypeId: selectedComponentTypeId = "" } = componentInfo;

  // Loading Status
  const loading = auditObsAndRecListLoading || auditComponentInfoLoading || upsertParameterValuesLoading;

  // Getting savings parameter list for the component
  const savingsParameterList = getComponentTypeParameters(selectedComponentTypeId, "SAVINGS") || [];
  const isSavingsParameterPresent = savingsParameterList.length !== 0;

  // use Effect
  useEffect(() => {
    if (selectedComponentId && !loading) {
      dispatch(
        AuditObservationAndRecommendationListActions.getAuditObsAndRecList({
          componentId: selectedComponentId,
          auditId,
        })
      );
    }
  }, [dispatch, auditId, selectedComponentId]);

  useEffect(() => {
    if (selectedComponentId && !isSavingsParameterPresent) {
      dispatch(
        AuditParameterValuesActions.upsertParameterValues({
          auditId,
          componentId: selectedComponentId,
          parameterValuesData: [],
          type: "savings",
        })
      );
    }
  }, [isSavingsParameterPresent, selectedComponentId]);

  useEffect(() => {
    return () => dispatch(AuditParameterValuesActions.resetAllApiSuccessState());
  }, [dispatch]);

  useEffect(() => {
    if (upsertParameterValuesSuccess && isSavingsParameterPresent) {
      toast.success("Parameters Updated Successfully");
    }
  }, [isSavingsParameterPresent, upsertParameterValuesSuccess]);

  return (
    <div className="page-content">
      {/* Component Select */}
      <ComponentSelect />

      {loading && <Loader />}

      {!selectedComponentTypeId && !loading && <div>Please select a component !!</div>}

      {selectedComponentTypeId && !loading && (
        <>
          <h3 className="mb-3">Savings</h3>

          <div className="row">
            <SavingsCalculationTabularForm
              savingsParameterList={savingsParameterList}
              loading={upsertParameterValuesLoading}
              isSavingsParameterPresent={isSavingsParameterPresent}
            />

            <div className="col">
              <AuditSavingsRecommendationListTable />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
