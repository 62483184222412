// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Urls
import { loginUrl, resetPasswordUrl, updateUserPasswordUrl } from "../../urls/api-urls/AuthURL";

// Constants
import { ContentType } from "../../app/http/HttpConstants";

// Utils
import URLs from "../../app/utils/URLs";

// Login
export async function loginApi(loginFormData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(loginUrl, {}, loginFormData, ContentType.APP_FORM_URL_ENCODED);
}

// Reset Password
export async function resetPasswordApi(email = "", newPassword = "") {
  const params = {
    userEmail: email,
  };

  const bodyFormData = new FormData();
  bodyFormData.set("newPassword", newPassword);

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(resetPasswordUrl, params, bodyFormData, ContentType.APP_FORM_URL_ENCODED);
}

// Update User Password
export async function updateUserPasswordApi(userId, newPassword) {
  const params = {
    newPassword,
  };

  const url = URLs.format(updateUserPasswordUrl, { userId });

  const httpClient = HttpClientFactory.getInstance();
  return httpClient.put(url, params);
}
