import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditActions } from "../../redux-slice/audit/AuditSlice";
import { PlantActions } from "../../redux-slice/plant/PlantSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import {
  auditCreateApi,
  getAuditInfoApi,
  getAuditDetailedProgressApi,
  updateAuditInchargeApi,
  startAuditPreAssessmentApi,
  startPreAuditApi,
  addAuditorApi,
  removeAuditorApi,
  updatePlantInchargeApi,
  updateStartDateApi,
} from "../../api/audit/AuditAPI";

import { getPlantInfoByIdApi } from "../../api/plant/PlantAPI";

// Audit Create
function* auditCreate(action) {
  try {
    const { auditData } = action.payload;

    yield auditCreateApi(auditData);

    yield put(AuditActions.createAuditSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.createAuditFailure({ error }));
  }
}

// Get Audit Info By Id
function* getAuditInfoById(action) {
  try {
    const { auditId } = action.payload;

    const auditInfo = yield getAuditInfoApi(auditId);

    const { plantId } = auditInfo;

    const plantInfo = yield getPlantInfoByIdApi(plantId);

    yield put(PlantActions.getPlantInfoSuccess({ plantInfo }));

    yield put(AuditActions.getAuditInfoSuccess({ auditInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getAuditInfoFailure({ error }));
  }
}

// Get Audit Detailed Progress By Id
function* getAuditDetailedProgressById(action) {
  try {
    const { auditId } = action.payload;

    const auditDetailedProgress = yield getAuditDetailedProgressApi(auditId);

    yield put(AuditActions.getAuditDetailedProgressSuccess({ auditDetailedProgress }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.getAuditDetailedProgressFailure({ error }));
  }
}

// Update Plant Incharge By Id
function* updatePlantInchargeById(action) {
  try {
    const { auditId, userId } = action.payload;

    yield updatePlantInchargeApi(auditId, userId);

    yield put(AuditActions.updatePlantInchargeSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updatePlantInchargeFailure({ error }));
  }
}

// Update Audit Incharge By Id
function* updateAuditInchargeById(action) {
  try {
    const { auditId, userId, setIsEdit, revalidator } = action.payload;

    yield updateAuditInchargeApi(auditId, userId);

    // To revalidate audit information
    yield revalidator.revalidate();

    yield put(AuditActions.updateAuditInchargeSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updateAuditInchargeFailure({ error }));
  }
}

// Start Audit Pre Assessment By Id
function* startAuditPreAssessmentById(action) {
  try {
    const { auditId } = action.payload;

    yield startAuditPreAssessmentApi(auditId);

    yield put(AuditActions.startAuditPreAssessmentSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.startAuditPreAssessmentFailure({ error }));
  }
}

// Start Pre Audit By Id
function* startPreAuditById(action) {
  try {
    const { auditId } = action.payload;

    yield startPreAuditApi(auditId);

    yield put(AuditActions.startPreAuditSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.startPreAuditFailure({ error }));
  }
}

// Add Auditor By Id
function* addAuditorById(action) {
  try {
    const { auditId, userId, revalidator } = action.payload;

    yield addAuditorApi(auditId, userId);

    // To revalidate audit information
    yield revalidator.revalidate();

    yield put(AuditActions.addAuditorSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.addAuditorFailure({ error }));
  }
}

// Remove Auditor By Id
function* removeAuditorById(action) {
  const { auditId, userId, revalidator } = action.payload;

  try {
    yield removeAuditorApi(auditId, userId);

    // To revalidate audit information
    yield revalidator.revalidate();

    yield put(AuditActions.removeAuditorSuccess({ userId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.removeAuditorFailure({ error, userId }));
  }
}

// Update Start Date
function* updateStartDate(action) {
  const { auditId, date = "" } = action.payload;

  try {
    yield updateStartDateApi(auditId, date);

    yield put(AuditActions.updateStartDateSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditActions.updateStartDateFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditActions.createAudit.type, auditCreate),
    takeLatest(AuditActions.getAuditInfo.type, getAuditInfoById),
    takeLatest(AuditActions.getAuditDetailedProgress.type, getAuditDetailedProgressById),
    takeLatest(AuditActions.updatePlantIncharge.type, updatePlantInchargeById),
    takeLatest(AuditActions.updateAuditIncharge.type, updateAuditInchargeById),
    takeLatest(AuditActions.startAuditPreAssessment.type, startAuditPreAssessmentById),
    takeLatest(AuditActions.startPreAudit.type, startPreAuditById),
    takeLatest(AuditActions.addAuditor.type, addAuditorById),
    takeLatest(AuditActions.removeAuditor.type, removeAuditorById),
    takeLatest(AuditActions.updateStartDate.type, updateStartDate),
  ]);
}
