import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { AuthActions } from "../../redux-slice/auth/AuthSlice";
import { ErrorActions } from "./ErrorSlice";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Utils
import URLs from "../../app/utils/URLs";

// Constants
const SERVER_ERROR_CODES = [500, 502, 503];
const PAGE_ERROR_CODES = [401, 404];
const TOKEN_EXPIRED_ERROR_CODE = 403;

// Util Functions
// ----------------------------------------------------------------------------

function getErrorAndStatusCode(error) {
  // default error object
  const defaultErrorObject = {
    errorMessage: "",
    statusCode: false,
  };

  // from Error.response
  if (error.response) {
    // status code
    const statusCode = error.response?.status;

    // error message
    const errorMessage = error.response?.data || "";

    // return
    return { errorMessage, statusCode };
  }

  return defaultErrorObject;
}

/**
 * ERROR PAGES WRAPPER COMPONENT
 * @param {*} children : pages
 */
export default function ErrorHandler({ children }) {
  // Dispatch
  const dispatch = useDispatch();

  // Location
  const { pathname } = useLocation();

  // Navigate
  const navigate = useNavigate();

  // Selector States
  const apiError = useSelector((state) => state.error.errorInfo);
  const showToaster = useSelector((state) => state.error.showToaster);
  const showPageError = useSelector((state) => state.error.showPageError);

  // useEffect
  useEffect(() => {
    dispatch(ErrorActions.clearErrorInfo());
  }, [dispatch, showPageError, showToaster, pathname]);

  // errorMessage and statusCode from api error
  const { errorMessage, statusCode: errorStatusCode } = getErrorAndStatusCode(apiError);

  // If NO-Error Status Code (means no error)
  if (!errorStatusCode) {
    return <>{children}</>;
  }

  // 403 Token Expired Error
  // ---------------------------------------
  if (errorStatusCode === TOKEN_EXPIRED_ERROR_CODE) {
    dispatch(AuthActions.logout({ navigate }));
    return;
  }

  // 500 and 502 and 503 Error Status Handle
  // ---------------------------------------
  if (SERVER_ERROR_CODES.includes(errorStatusCode)) {
    // Redirecting url
    const errorPageUrl = URLs.format(RedirectTo.ErrorPageUrl, { errorStatusCode });

    navigate(errorPageUrl);

    return null;
  }

  // show Page Error
  // -------------------------------------
  if (showPageError) {
    return <>{children}</>;
  }

  // showing toaster only
  // -----------------------------------------
  if (showToaster) {
    toast.error(`${errorStatusCode} ${errorMessage}`);
    return <>{children}</>;
  }

  //  Rendering of Error Pages
  // ----------------------------------------

  if (PAGE_ERROR_CODES.includes(errorStatusCode)) {
    // Redirecting url
    const errorPageUrl = URLs.format(RedirectTo.ErrorPageUrl, { errorStatusCode });

    navigate(errorPageUrl);

    return null;
  }

  return <>{children}</>;
}
