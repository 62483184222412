import { all, fork } from "redux-saga/effects";

// Auth Saga
import AuthSaga from "./auth/AuthSaga";

// Audit Saga - Audit
import AuditSaga from "./audit/AuditSaga";
import AuditListSaga from "./audit/AuditListSaga";

// Audit Saga - Component
import AuditComponentSaga from "./audit/ComponentSaga";
import AuditComponentListSaga from "./audit/ComponentListSaga";

// Audit Saga - Instrument
import AuditInstrumentSaga from "./audit/InstrumentSaga";
import AuditInstrumentListSaga from "./audit/InstrumentListSaga";

// Audit Saga - Observation and Recommendation
import AuditObservationAndRecommendationSaga from "./audit/ObservationAndRecommendationSaga";
import AuditObservationAndRecommendationListSaga from "./audit/ObservationAndRecommendationListSaga";

// Audit Saga - Parameter
import AuditParameterValuesSaga from "./audit/ParameterValuesSaga";

// Audit Saga - Data
import AuditDataSaga from "./audit/DataSaga";

// Audit Saga - Reports
import AuditReportsSaga from "./audit/ReportsSaga";
import AuditReportsListSaga from "./audit/ReportsListSaga";

// Audit Saga - File
import AuditFileSaga from "./audit/FileSaga";

// Component Type Saga
import ComponentTypeSaga from "./component-type/ComponentTypeSaga";
import ComponentTypeListSaga from "./component-type/ComponentTypeListSaga";

// Job Saga
import JobSaga from "./job/JobSaga";
import JobListSaga from "./job/JobListSaga";

// Material Saga
import MaterialSaga from "./material/MaterialSaga";
import MaterialListSaga from "./material/MaterialListSaga";

// Measurement Type Saga
import MeasurementTypeSaga from "./measurement-type/MeasurementTypeSaga";
import MeasurementTypeListSaga from "./measurement-type/MeasurementTypeListSaga";

// Instrument Saga
import InstrumentSaga from "./instrument/InstrumentSaga";
import InstrumentListSaga from "./instrument/InstrumentListSaga";

// Organization Saga
import Organization from "./organization/OrganizationSaga";
import OrganizationListSaga from "./organization/OrganizationListSaga";

// Plant Saga - Plant
import PlantSaga from "./plant/PlantSaga";
import PlantListSaga from "./plant/PlantListSaga";

// Plant Saga - Component
import PlantComponentSaga from "./plant/ComponentSaga";
import PlantComponentListSaga from "./plant/ComponentListSaga";

// Plant Saga - Electricity Bill
import PlantElectricityBillSaga from "./plant/ElectricityBillSaga";

// Plant Saga - Thermal Bill
import PlantThermalBillSaga from "./plant/ThermalBillSaga";

// Plant Saga - Component Parts
import PlantComponentPartsSaga from "./plant/PartsSaga";

// Plant Saga - File
import PlantFileSaga from "./plant/FileSaga";

// User Saga
import UserListSaga from "./user/UserListSaga";
import UserSaga from "./user/UserSaga";

// Product Saga
import ProductSaga from "./product/ProductSaga";
import ProductListSaga from "./product/ProductListSaga";

// Constant Saga
import ConstantListSaga from "./constant/ConstantListSaga";

// Role Saga
import RoleSaga from "./role/RoleSaga";
import RoleListSaga from "./role/RoleListSaga";

// HSN Code Saga
import HSNCodeListSaga from "./hsn-code/HSNCodeListSaga";

// NIC Code Saga
import NICCodeListSaga from "./nic-code/NICCodeListSaga";

// APP Saga
import { AppRootSaga } from "../app/AppRootSaga";

export default function* RootSaga() {
  yield all([
    // Auth Saga
    fork(AuthSaga),

    // AuditSage
    fork(AuditSaga),
    fork(AuditListSaga),
    fork(AuditComponentSaga),
    fork(AuditComponentListSaga),
    fork(AuditInstrumentSaga),
    fork(AuditInstrumentListSaga),
    fork(AuditObservationAndRecommendationSaga),
    fork(AuditObservationAndRecommendationListSaga),
    fork(AuditParameterValuesSaga),
    fork(AuditDataSaga),
    fork(AuditReportsSaga),
    fork(AuditReportsListSaga),
    fork(AuditFileSaga),

    // Component type saga
    fork(ComponentTypeSaga),
    fork(ComponentTypeListSaga),

    // Job Saga
    fork(JobSaga),
    fork(JobListSaga),

    // Material saga
    fork(MaterialSaga),
    fork(MaterialListSaga),

    // Measurement Type saga
    fork(MeasurementTypeSaga),
    fork(MeasurementTypeListSaga),

    // Instrument Saga
    fork(InstrumentSaga),
    fork(InstrumentListSaga),

    // Organization saga
    fork(Organization),
    fork(OrganizationListSaga),

    // Plant Saga
    fork(PlantSaga),
    fork(PlantListSaga),
    fork(PlantComponentSaga),
    fork(PlantComponentListSaga),
    fork(PlantElectricityBillSaga),
    fork(PlantThermalBillSaga),
    fork(PlantComponentPartsSaga),
    fork(PlantFileSaga),

    // User List Saga
    fork(UserListSaga),
    fork(UserSaga),

    // Product saga
    fork(ProductSaga),
    fork(ProductListSaga),

    // Constant saga
    fork(ConstantListSaga),

    // Role Saga
    fork(RoleSaga),
    fork(RoleListSaga),

    // HSN Code Saga
    fork(HSNCodeListSaga),

    // NIC Code Saga
    fork(NICCodeListSaga),

    ...AppRootSaga,
  ]);
}
