import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.updateMaterialStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Update Material Status (Enable/Disable)
  updateMaterialStatusLoadingMap: {},
  updateMaterialStatusSuccessMap: {},
  updateMaterialStatusError: "",
};

const materialSlice = createSlice({
  name: "Material",
  initialState,
  reducers: {
    // Update Material Status (Enable/Disable)
    updateMaterialStatus: (state, action) => {
      const { materialId } = action.payload;
      state.updateMaterialStatusSuccessMap[materialId] = false;
      state.updateMaterialStatusLoadingMap[materialId] = true;
      state.updateMaterialStatusError = "";
    },

    updateMaterialStatusSuccess: (state, action) => {
      const { materialId } = action.payload;
      state.updateMaterialStatusSuccessMap[materialId] = true;
      delete state.updateMaterialStatusLoadingMap[materialId];
    },

    updateMaterialStatusFailure: (state, action) => {
      const { materialId, error } = action.payload;
      delete state.updateMaterialStatusLoadingMap[materialId];
      state.updateMaterialStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const MaterialReducer = materialSlice.reducer;

// Actions
export const MaterialActions = materialSlice.actions;
