// Plant Url
export const addPlantUrl = "/api/v1/plant/";
export const updatePlantByIdUrl = "/api/v1/plant/:plantId";
export const updatePlantStatusByIdUrl = "/api/v1/plant/:plantId/status";
export const getPlantInfoByIdUrl = "/api/v1/plant/:plantId";

// Plant Component Url
export const addPlantComponentByIdUrl = "/api/v1/plant/:plantId/component";
export const updatePlantComponentByIdUrl = "/api/v1/plant/:plantId/component/:componentId";
export const plantComponentImageByIdUrl = "/api/v1/plant/:plantId/component/:componentId/image";
export const decommissionComponentUrl = "/api/v1/plant/:plantId/component/:componentId/decommission";

// Plant Component Part Url
export const addPlantComponentPartByIdUrl = "/api/v1/plant/:plantId/component/:componentId/part";
export const updatePlantComponentPartByIdUrl = "/api/v1/plant/:plantId/component/:componentId/part/:partId";
export const deletePlantComponentPartByIdUrl = "/api/v1/plant/:plantId/component/:componentId/part/:partId";

// Plant Thermal Electricity Bill
export const getPlantThermalBillListByIdUrl = "/api/v1/plant/:plantId/thermal_bill/search";
export const createPlantThermalBillUrl = "/api/v1/plant/:plantId/thermal_bill";
export const updatePlantThermalBillByIdUrl = "/api/v1/plant/:plantId/thermal_bill/:thermalBillId";
export const uploadPlantThermalBillByIdUrl = "/api/v1/plant/:plantId/thermal_bill/:thermalBillId/file";

// Plant Electricity Bill Url
export const getPlantElectricityBillListByIdUrl = "/api/v1/plant/:plantId/power_bill/search";
export const createPlantElectricityBillUrl = "/api/v1/plant/:plantId/power_bill";
export const updatePlantElectricityBillByIdUrl = "/api/v1/plant/:plantId/power_bill/:electricityBillId";
export const uploadPlantElectricityBillByIdUrl = "/api/v1/plant/:plantId/power_bill/:electricityBillId/file";

// Plant Files
export const getPlantFileListUrl = "/api/v1/plant/:plantId/file/search";
export const createPlantFileUrl = "/api/v1/plant/:plantId/file";
export const updatePlantFileByIdUrl = "/api/v1/plant/:plantId/file/:fileId";
export const deletePlantFileByIdUrl = "/api/v1/plant/:plantId/file/:fileId";

// Plant list Url
export const getPlantListUrl = "/api/v1/plant/search";
export const getPlantComponentListUrl = "/api/v1/plant/:plantId/component";
