import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.updateObsAndRecSuccess = {};
  state.updateRecInvestmentSuccess = {};
}

// Initial State
const initialState = {
  // Update Audit Observation And Recommendation
  updateObsAndRecLoading: {},
  updateObsAndRecSuccess: {},
  updateObsAndRecError: "",

  // Update Recommendation Investment
  updateRecInvestmentLoading: {},
  updateRecInvestmentSuccess: {},
  updateRecInvestmentError: "",
};

// Audit Observation And Recommendation Slice
const auditObsAndRecSlice = createSlice({
  name: "AuditObservationAndRecommendation",
  initialState,
  reducers: {
    // Update Audit Observation And Recommendation
    updateAuditObsAndRec: (state, action) => {
      const { observationId } = action.payload;
      state.updateObsAndRecLoading[observationId] = true;
      state.updateObsAndRecSuccess[observationId] = false;
      state.updateObsAndRecError = "";
    },

    updateAuditObsAndRecSuccess: (state, action) => {
      const { observationId } = action.payload;
      state.updateObsAndRecSuccess[observationId] = true;
      delete state.updateObsAndRecLoading[observationId];
    },

    updateAuditObsAndRecFailure: (state, action) => {
      const { observationId, error } = action.payload;
      delete state.updateObsAndRecLoading[observationId];
      state.updateObsAndRecError = error;
    },

    // Update Recommendation Investment
    updateAuditRecInvestment: (state, action) => {
      const { recommendationId } = action.payload;
      state.updateRecInvestmentSuccess[recommendationId] = false;
      state.updateRecInvestmentLoading[recommendationId] = true;
      state.updateRecInvestmentError = "";
    },

    updateAuditRecInvestmentSuccess: (state, action) => {
      const { recommendationId } = action.payload;
      state.updateRecInvestmentSuccess[recommendationId] = true;
      delete state.updateRecInvestmentLoading[recommendationId];
    },

    updateAuditRecInvestmentFailure: (state, action) => {
      const { recommendationId, error } = action.payload;
      delete state.updateRecInvestmentLoading[recommendationId];
      state.updateRecInvestmentError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditObservationAndRecommendationReducer = auditObsAndRecSlice.reducer;

// Actions
export const AuditObservationAndRecommendationActions = auditObsAndRecSlice.actions;
