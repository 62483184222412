import { useDispatch, useSelector } from "react-redux";
import { useOutletContext, useParams } from "react-router-dom";
import { useEffect } from "react";

// Actions
import { AuditListActions } from "../../redux-slice/audit/AuditListSlice";

// Components
import PageHeader from "../../components/page-header/PageHeader";
import AuditInitiateButton from "../../components/audit/AuditInitiateButton";

// Page Components
import AuditListTable from "../../page-components/audit-list/AuditListTable";

// Page Components
function PageHeaderSection() {
  return <PageHeader title={"Audits Dashboard"} className="w-100" />;
}

/**
 * Audit List By Auditor
 */
export default function AuditListByAuditor() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { userId = "" } = useParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Selector State
  const auditListByAuditor = useSelector((state) => state.auditList.auditListByAuditor);
  const auditListByAuditorLoading = useSelector((state) => state.auditList.auditListByAuditorLoading);

  // use Effect
  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
    dispatch(AuditListActions.getAuditListByAuditor({ userId }));
  }, [dispatch, userId]);

  return (
    <div className="page-content">
      <div className="my-3 text-end">
        <AuditInitiateButton />
      </div>
      {/* Audit List Table */}
      <AuditListTable items={auditListByAuditor} loading={auditListByAuditorLoading} />
    </div>
  );
}
