import { useEffect } from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { ComponentTypeListActions } from "../../../redux-slice/component-type/ComponentTypeListSlice";
import { ComponentTypeActions } from "../../../redux-slice/component-type/ComponentTypeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import PageHeader from "../../../components/page-header/PageHeader";
import Input from "../../../components/input/Input";

// Sections
import ParameterListTable from "./ParameterListTable";

// Page Constants
const tabs = [
  { title: "Properties", type: "PROPERTY" },
  { title: "Measurements", type: "MEASUREMENT" },
  { title: "Calculations", type: "CALCULATION" },
  { title: "Savings", type: "SAVINGS" },
];

// Page Components
function ParameterListPageTabs({ tabs, parameterType, updateQueryParams }) {
  // Function update search params
  function updateSearchParams(type) {
    const params = { tabId: type };

    updateQueryParams({ params });
  }

  return (
    <ul className="nav nav-tabs">
      {tabs.map((tab, index) => {
        const { title, type } = tab;
        return (
          <li key={index} className="nav-item">
            <Link
              className={`nav-link ${parameterType === type ? "active" : ""}`}
              onClick={() => updateSearchParams(type)}
            >
              {title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}

function ParameterListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search Components...`} isSearchInput={true} />
    </div>
  );
}

/**
 * Parameter List page
 */
export default function ParameterListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { componentTypeId } = params;

  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);
  const tabId = searchParams.get(QueryParamsKeys.tabId) || tabs[0].type;

  // Parameter List Selector State
  const parameterList = useSelector((state) => state.componentTypeList.parameterList);
  const parameterListLoading = useSelector((state) => state.componentTypeList.parameterListLoading);
  const parameterListPagination = useSelector((state) => state.componentTypeList.parameterListPagination);

  // Component Type Info Selector State
  const componentTypeInfo = useSelector((state) => state.componentType.componentTypeInfo);
  const componentTypeInfoLoading = useSelector((state) => state.componentType.componentTypeInfoLoading);

  // Page Header
  const { name = "" } = componentTypeInfo;
  const pageTitle = !componentTypeInfoLoading ? `${name} :: Parameters` : "";

  // Back Button Config
  const backButtonConfig = { redirectUrl: RedirectTo.ComponentTypeListPageUrl };

  // use Effect
  useEffect(() => {
    dispatch(ComponentTypeActions.getComponentTypeInfo({ componentTypeId }));
  }, [dispatch, componentTypeId]);

  useEffect(() => {
    return () => dispatch(ComponentTypeActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber, tabId]);

  useEffect(() => {
    dispatch(
      ComponentTypeListActions.getParameterList({
        componentTypeId,
        parameterType: tabId,
        pageNumber,
        pageSize,
        searchText,
      })
    );
  }, [dispatch, componentTypeId, tabId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title={pageTitle} backButtonConfig={backButtonConfig} />);
  }, [name]);

  return (
    <div className="page-content">
      {/* Parameter List Page Tabs */}
      <ParameterListPageTabs tabs={tabs} parameterType={tabId} updateQueryParams={updateQueryParams} />

      {/* Parameter List Table Actions Section */}
      <ParameterListTableActionsSection />

      {/* Parameter List Table */}
      <ParameterListTable
        parameterList={parameterList}
        parameterListLoading={parameterListLoading}
        parameterListPagination={parameterListPagination}
        pageNumber={pageNumber}
        parameterType={tabId}
      />
    </div>
  );
}
