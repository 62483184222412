import { useLoaderData, useParams } from "react-router-dom";

// Constants
import { PlantLayoutSideBar, SIDEBAR_HEADER_CONSTANTS } from "../../constants/LayoutConstants";

// Utils
import { constructSideBarUrls } from "../../utils/LayoutUtils";
import { bcForPlantViewPage } from "../../utils/BreadcrumbUtils";
import URLs from "../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Components
import PageHeader from "../page-header/PageHeader";

// Layouts
import SidebarMenu from "./SidebarMenu";
import SiderbarFooter from "./SidebarFooter";

// App Layout
import Layout from "../../app/layout/Layout";

/**
 * Layout Page
 */
export default function PlantLayout() {
  // Params
  const params = useParams();
  const { orgId, plantId } = params;

  // Get saved info from the loader
  const { plantInfo = {}, plantOrgInfo = {} } = useLoaderData();

  const { name: orgName = "" } = plantOrgInfo;
  const { name: plantName = "" } = plantInfo;

  // Sidebar Configuration
  const { sideBarElements = [], backButtonConfig = {} } = PlantLayoutSideBar || {};

  // Replace auditId param in all the sidebar urls..
  const paramMap = { orgId, plantId };

  const updatedSideBarElements = constructSideBarUrls(sideBarElements, paramMap);

  const updatedBackButtonConfig = { ...backButtonConfig, link: URLs.format(backButtonConfig.link, { orgId }) };

  // Topbar Config
  const topbarConfig = {
    topBarMenuComponent: (
      <div className="d-flex align-items-center justify-content-between">
        <PageHeader breadcrumbList={bcForPlantViewPage(orgId, orgName, plantName)} className="mt-2" />
      </div>
    ),
  };

  // Sidebar Config
  const { headerTitle, logoHeight, logoURL, collapsedLogoHeight, collapsedLogoURL } = SIDEBAR_HEADER_CONSTANTS;
  const sidebarConfig = {
    menuComponent: <SidebarMenu sidebarMenuList={updatedSideBarElements} backButtonConfig={updatedBackButtonConfig} />,
    footerComponent: <SiderbarFooter />,
    headerLogo: { height: logoHeight, logoURL },
    collapsedHeaderLogo: { height: collapsedLogoHeight, logoURL: collapsedLogoURL },
    redirectURL: RedirectTo.DashboardPageUrl,
    title: headerTitle,
  };

  return <Layout sidebarCollapsible={true} topbarConfig={topbarConfig} sidebarConfig={sidebarConfig} />;
}
