// Reference : https://ant.design/docs/react/use-custom-date-library
/**
 * Since Ant Design uses day.js as its dependency for the DatePicker component,
 * we need to create a custom DatePicker to use moment.js instead.
 */
import { DatePicker } from "antd";
import type { Moment } from "moment";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

const CustomDatePicker = DatePicker.generatePicker<Moment>(momentGenerateConfig);

export default CustomDatePicker;
