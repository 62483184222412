import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";

// Constants
import { OrgTypes } from "../../../constants/GeneralConstants";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TablePagination from "../../../components/table/TablePagination";
import ToggleButton from "../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Website",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

// Page Components
function OrganizationStatus({ organization }) {
  const dispatch = useDispatch();

  const { id: orgId, orgType, isActive } = organization;

  const loading = useSelector((state) => state.organization.updateOrganizationStatusLoadingMap[orgId]) || false;
  const updateOrganizationStatusSuccess =
    useSelector((state) => state.organization.updateOrganizationStatusSuccessMap[orgId]) || false;
  const organizationListPagination = useSelector((state) => state.organizationList.organizationListPagination);
  const { pageSize, pageNo } = organizationListPagination;

  const status = !isActive;

  function updateOrganizationStatus() {
    dispatch(OrganizationActions.updateOrganizationStatus({ orgId, orgType, status, pageNumber: pageNo, pageSize }));
  }

  useEffect(() => {
    if (updateOrganizationStatusSuccess) {
      toast.success("Organization Status Updated Successfully");
    }
  }, [updateOrganizationStatusSuccess]);

  return (
    <td>
      <ToggleButton isActive={isActive} loading={loading} onClick={updateOrganizationStatus} />
    </td>
  );
}
function OrganizationListTableActionCell({ organization }) {
  // Navigate
  const navigate = useNavigate();
  const { id: orgId, orgType = "", isActive } = organization;
  const { nameLowercase = "" } = OrgTypes[orgType];

  // Action Butttons
  const actionButtons = [
    {
      label: "View",
      isIndustry: true,
      redirectUrl: URLs.format(RedirectTo.OrganizationViewPageUrl, { orgType: nameLowercase, orgId }),
    },
    {
      label: "View Users",
      isIndustry: true,
      redirectUrl: URLs.format(RedirectTo.UserListPageUrl, { orgId }),
    },
    {
      label: "View Plants",
      isIndustry: orgType === "INDUSTRY",
      redirectUrl: URLs.format(RedirectTo.PlantListPageUrl, { orgId }),
    },
  ];

  return (
    <td>
      <div className="btn-cont">
        {actionButtons.map((btn, idx) => {
          const { label = "", isIndustry = false, redirectUrl = "" } = btn || {};

          if (!isIndustry) {
            return null;
          }

          return (
            <Button label={label} size="sm" key={idx} onClick={() => navigate(redirectUrl)} disabled={!isActive}>
              <i className="fas fa-eye"></i>
            </Button>
          );
        })}
      </div>
    </td>
  );
}

function OrganizationListTableWebsiteCell({ website }) {
  return (
    <td>
      <a href={website} target="_blank" className="text-decoration-none" rel="noreferrer">
        {website}
      </a>
    </td>
  );
}

function OrganizationListTableRow({ organization, serialNo }) {
  const { name, website } = organization;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>

      {/* Organization Table Website Cell */}
      <OrganizationListTableWebsiteCell website={website} />

      <OrganizationStatus organization={organization} />

      {/* Organization Table Action Cell */}
      <OrganizationListTableActionCell organization={organization} />
    </tr>
  );
}

function OrganizationListTableBody({ organizationListLoading, organizationList, pageSize, pageNumber }) {
  //Loader
  if (organizationListLoading) {
    return <TableLoader colSpan={8} />;
  }

  //if there is no organization list
  if (organizationList.length === 0) {
    return <TableDataNotFound message="No organization present" colSpan={8} />;
  }

  //Organization list table row
  return (
    <>
      {organizationList.map((organization, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return <OrganizationListTableRow key={organization.id} organization={organization} serialNo={serialNo} />;
      })}
    </>
  );
}

/**
 * Organization List Table
 */
export default function OrganizationListTable({
  organizationList = [],
  organizationListLoading = false,
  organizationListPagination = {},
  pageNumber,
}) {
  const { totalCount, pageSize, pageNo } = organizationListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Organization List Table Body */}
          <OrganizationListTableBody
            organizationList={organizationList}
            organizationListLoading={organizationListLoading}
            pageSize={pageSize}
            pageNumber={pageNo}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
