// Urls
import {
  auditCreateUrl,
  getAuditInfoByIdUrl,
  startAuditPreAssessmentByIdUrl,
  startPreAuditByIdUrl,
  updateAuditInchargeByIdUrl,
  addAuditorByIdUrl,
  removeAuditorByIdUrl,
  getAuditDetailedProgressByIdUrl,
  updatePlantInchargeByIdUrl,
  updateAuditStartDateUrl,
} from "../../urls/api-urls/AuditURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// Utils
import URLs from "../../app/utils/URLs";

// Initiate New Audit
export async function auditCreateApi(auditData) {
  const httpClient = HttpClientFactory.getInstance();
  return httpClient.post(auditCreateUrl, {}, auditData);
}

// Get Audit Info
export async function getAuditInfoApi(auditId) {
  const url = URLs.format(getAuditInfoByIdUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Get Audit Detailed Progress
export async function getAuditDetailedProgressApi(auditId) {
  const url = URLs.format(getAuditDetailedProgressByIdUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.get(url);
}

// Update Plant Incharge
export async function updatePlantInchargeApi(auditId, userId) {
  const url = URLs.format(updatePlantInchargeByIdUrl, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Update Audit Incharge
export async function updateAuditInchargeApi(auditId, userId) {
  const url = URLs.format(updateAuditInchargeByIdUrl, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Start Pre Assessment
export async function startAuditPreAssessmentApi(auditId) {
  const url = URLs.format(startAuditPreAssessmentByIdUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Start Pre Audit
export async function startPreAuditApi(auditId) {
  const url = URLs.format(startPreAuditByIdUrl, { auditId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Add Auditor
export async function addAuditorApi(auditId, userId) {
  const url = URLs.format(addAuditorByIdUrl, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Remove Auditor
export async function removeAuditorApi(auditId, userId) {
  const url = URLs.format(removeAuditorByIdUrl, { auditId, userId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}

// Update Start Date
export async function updateStartDateApi(auditId, date) {
  const url = URLs.format(updateAuditStartDateUrl, { auditId, date });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}
