import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.addPlantSuccess = false;
  state.updatePlantSuccess = false;
  state.updatePlantStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get plant information
  plantInfo: {},
  plantInfoLoading: false,
  plantInfoError: "",

  // Add plant
  addPlantLoading: false,
  addPlantSuccess: false,
  addPlantError: "",

  // Update plant
  updatePlantLoading: false,
  updatePlantSuccess: false,
  updatePlantError: "",

  // Update plant status
  updatePlantStatusLoadingMap: {},
  updatePlantStatusSuccessMap: {},
  updatePlantStatusError: "",
};

// Plant Slice
const plantSlice = createSlice({
  name: "Plant",
  initialState,
  reducers: {
    // Get plant information
    getPlantInfo: (state) => {
      state.plantInfoLoading = true;
    },

    getPlantInfoSuccess: (state, action) => {
      state.plantInfoLoading = false;
      state.plantInfo = action.payload.plantInfo;
    },

    getPlantInfoFailure: (state, action) => {
      state.plantInfoLoading = false;
      state.plantInfoError = action.payload.error;
    },

    // Add plant
    addPlant: (state) => {
      state.addPlantSuccess = false;
      state.addPlantLoading = true;
    },

    addPlantSuccess: (state) => {
      state.addPlantSuccess = true;
      state.addPlantLoading = false;
    },

    addPlantFailure: (state, action) => {
      state.addPlantLoading = false;
      state.addPlantError = action.payload.error;
    },

    // Update plant
    updatePlant: (state) => {
      state.updatePlantSuccess = false;
      state.updatePlantLoading = true;
    },

    updatePlantSuccess: (state) => {
      state.updatePlantSuccess = true;
      state.updatePlantLoading = false;
    },

    updatePlantFailure: (state, action) => {
      state.updatePlantLoading = false;
      state.updatePlantError = action.payload.error;
    },

    // Update Plant status
    updatePlantStatus: (state, action) => {
      const { plantId } = action.payload;
      state.updatePlantStatusSuccessMap[plantId] = false;
      state.updatePlantStatusLoadingMap[plantId] = true;
      state.updatePlantStatusError = "";
    },

    updatePlantStatusSuccess: (state, action) => {
      const { plantId } = action.payload;
      state.updatePlantStatusSuccessMap[plantId] = true;
      delete state.updatePlantStatusLoadingMap[plantId];
    },

    updatePlantStatusFailure: (state, action) => {
      const { plantId } = action.payload;
      delete state.updatePlantStatusLoadingMap[plantId];
      state.updatePlantStatusError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantReducer = plantSlice.reducer;

// Actions
export const PlantActions = plantSlice.actions;
