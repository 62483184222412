import { useDispatch } from "react-redux";
import { useState } from "react";

// Components
import { Button } from "../../components/button/Button";
import Input from "../../components/input/Input";
import Label from "../../components/label/Label";

/**
 * Update User Password Form
 * @param {*} emailId : String
 * @param {*} loading : Boolean
 * @param {*} dispatchFn : Function
 */
export default function UpdateUserPasswordForm({ userId = "", email = "", loading = false, dispatchFn = () => {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [values, setValues] = useState({});
  const [error, setError] = useState({});

  const { newPassword = "", confirmPassword = "" } = values || {};
  const { newPasswordErr = "", confirmPasswordErr = "" } = error || {};

  function handleChange({ target }, key) {
    setValues((prev) => ({ ...prev, [key]: target.value }));
  }

  function updatePassword() {
    if (!newPassword) {
      setError({ newPasswordErr: "Please enter password !!" });
    }

    if (!confirmPassword) {
      setError((prev) => ({ ...prev, confirmPasswordErr: "Please enter password !!" }));
      return;
    }

    if (newPassword !== confirmPassword) {
      setError({ confirmPasswordErr: "Password Mismatch !!" });
      return;
    }

    // Clear State
    setError({});

    // Dispatch
    dispatch(dispatchFn({ userId, email, newPassword }));
  }

  return (
    <div className="mt-2">
      {/* New Password Input */}
      <Label title="New Password" isMandatory={true} />
      <Input value={newPassword} onChange={(e) => handleChange(e, "newPassword")} error={newPasswordErr} />

      {/* Confirm Password Input */}
      <Label title="Confirm Password" isMandatory={true} className="mt-2" />
      <Input value={confirmPassword} onChange={(e) => handleChange(e, "confirmPassword")} error={confirmPasswordErr} />

      {/* Reset Password Button */}
      <Button
        label="Reset"
        color="warning"
        className="mt-3 float-end"
        onClick={updatePassword}
        loading={loading}
        disabled={loading}
      />
    </div>
  );
}
