import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Actions
import { AuditComponentActions } from "../../../redux-slice/audit/ComponentSlice";
import { AuditComponentListActions } from "../../../redux-slice/audit/ComponentListSlice";

// Constants
import { DataPreLoadKeys } from "../../../constants/GeneralConstants";
import { ModalKeys } from "../../../constants/ModalConstants";

// Utils
import { getItem } from "../../../app/LocalStorage";

// Components
import { Button } from "../../../components/button/Button";

// Modal
import Modal from "../../../components/modal/Modal";

// Page Components
function UniqueIdentifierLabel({ internalId, uniqueIdentifiers = [], className = "" }) {
  const uniqueIdentifiersByComponent = uniqueIdentifiers[internalId] || [];

  return uniqueIdentifiersByComponent.map((identifier, index) => {
    return (
      <label key={index} className={className}>
        {identifier}
      </label>
    );
  });
}

function ComponentsSection({ componentType = {}, componentsList = [], uniqueIdentifiers }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { auditId = "" } = params;

  // Data
  const { code: compTypeCode, name: compTypeName } = componentType;

  // Function select component
  function selectComponent(component) {
    const { id = "" } = component || {};

    // Dispatch to update audit component information
    dispatch(AuditComponentActions.getAuditComponentInfo({ auditId, componentId: id }));
  }

  return (
    <div className="border-bottom py-2">
      <h5>
        {compTypeName} ({compTypeCode})
      </h5>
      <div>
        {componentsList.map((eachComponent) => {
          const { id, name: compName, internalId: compInternalId, imageURL = "" } = eachComponent || {};

          return (
            <div
              key={id}
              className="card cursor-pointer card-inline card-simple card-component-select"
              data-bs-dismiss="modal"
              onClick={() => selectComponent(eachComponent)}
            >
              <div className="card-body d-flex justify-content-between">
                <div className="">
                  {/* Component Image */}
                  {imageURL && <img alt="" src={imageURL} height={40} className="me-2" />}

                  {/* Icon */}
                  {!imageURL && <i className="fas fa-fan"></i>}
                </div>
                <div className="px-2">
                  <p className="title">{compName}</p>
                  <label>{compInternalId}</label>

                  {/* Unique Identifier Label */}
                  <UniqueIdentifierLabel
                    internalId={compInternalId}
                    uniqueIdentifiers={uniqueIdentifiers}
                    className="d-block"
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

/**
 * Component Selection Modal
 */
function ComponentSelectModal({ componentTypesMap = {}, componentsListByType = {}, uniqueIdentifiers = {}, modalId }) {
  const componentsListByTypeArray = Object.keys(componentsListByType);

  // Checking whether components is present or not
  const isComponentsPresent = componentsListByTypeArray.length !== 0;
  return (
    <Modal id={modalId} title="Plant Components" size="xl">
      {!isComponentsPresent && <div>Please add components !!</div>}

      {isComponentsPresent &&
        componentsListByTypeArray.map((componentTypeCode) => {
          // Getting the component type information
          const componentType = componentTypesMap[componentTypeCode] || {};
          const componentsList = componentsListByType[componentTypeCode] || [];

          return (
            <ComponentsSection
              key={componentTypeCode}
              componentType={componentType}
              componentsList={componentsList}
              uniqueIdentifiers={uniqueIdentifiers}
            />
          );
        })}
    </Modal>
  );
}

/**
 * Component Select
 */
export default function ComponentSelect() {
  // Dispatch
  const dispatch = useDispatch();

  // Get Path  Params
  const { auditId = "" } = useParams();

  // Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);
  const { name = "", internalId = "", imageURL = "" } = componentInfo;

  // Audit Plant Component List Selector State
  const auditPlantComponentList = useSelector((state) => state.auditComponentList.auditPlantComponentList);

  // Local Storage Data
  const { parametersByCompType = [], componentTypes = {} } = JSON.parse(
    getItem(DataPreLoadKeys.COMPONENT_TYPES) || "{}"
  );

  // use Effect to get the AuditComponentsList
  useEffect(() => {
    if (auditId) {
      dispatch(AuditComponentListActions.getAuditPlantCompList({ auditId }));
    }
  }, [dispatch, auditId]);

  const componentTypesMap = componentTypes?.reduce((map, compType) => {
    map[compType.code] = {
      ...compType,
      parameters: parametersByCompType[compType.code] || {},
    };

    return map;
  }, {});

  // Group Components By Type
  const componentsListByType = auditPlantComponentList?.reduce((acc, componentType) => {
    const componentTypeCode = componentType?.componentTypeCode || "";

    // Grouping components by component type
    acc[componentTypeCode] = [...(acc[componentTypeCode] ?? []), componentType];

    return acc;
  }, {});

  // Unique indentifier for each component
  const uniqueIdentifiers = auditPlantComponentList?.reduce((acc, componentType) => {
    const { internalId = "", componentTypeCode = "" } = componentType || {};

    // Checking whether parameter is present or not
    const isParameterPresent = Object.keys(componentTypesMap[componentTypeCode]?.parameters || {}).length !== 0;

    // If parameter is present
    if (isParameterPresent) {
      const { PROPERTY = [] } = componentTypesMap[componentTypeCode].parameters;

      // Checking for the unqiue descriptor property
      const { name = "", id = "" } = PROPERTY.find((eachComponent) => eachComponent.isDescriptor) || {};

      // Looping components
      componentsListByType[componentTypeCode].forEach((eachComponent) => {
        eachComponent.measurements?.PROPERTY?.forEach((eachObj) => {
          // For all the components property which has descriptor is identified,
          // and name of the property and value is constructed as single string,
          // and added to uniqueIdentifier array with key as internal id. Example : {"Boiler-000":["Make Manual",..]}
          if (eachObj.id === id) {
            acc[internalId] = [...(acc[internalId] ?? []), `${name} ${eachObj.value}`];
          }
        });
      });
    }

    return acc;
  }, {});

  // Getting Modal Id
  const modalId = ModalKeys.plantComponentSelectModal;

  return (
    <>
      <div className="card mb-3">
        <div className="card-body d-flex align-items-center justify-content-between">
          <div className="ms-2">
            {/* Component Image */}
            {imageURL && <img alt="" src={imageURL} height={60} className="me-2" />}

            {/* Icon */}
            {!imageURL && <i className="fas fa-fan me-2"></i>}

            <span className="title fw-bold me-2">{name}</span>
            <label>{internalId && `( ${internalId} )`}</label>

            {/* Unique Identifier Label */}
            <UniqueIdentifierLabel internalId={internalId} uniqueIdentifiers={uniqueIdentifiers} className="ms-2" />
          </div>

          {/* Button */}
          <Button color="primary" data-bs-toggle="modal" data-bs-target={`#${modalId}`}>
            Select Component
          </Button>
        </div>
      </div>

      {/** Component Select Modal */}
      <ComponentSelectModal
        componentTypesMap={componentTypesMap}
        componentsListByType={componentsListByType}
        uniqueIdentifiers={uniqueIdentifiers}
        modalId={modalId}
      />
    </>
  );
}
