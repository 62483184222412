import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Custom Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { PlantFileActions } from "../../../../redux-slice/plant/FileSlice";

// Constants
import { DefaultPagination, QueryParamsKeys } from "../../../../constants/GeneralConstants";
import { ModalKeys } from "../../../../constants/ModalConstants";
import { PlantFileTypes } from "../../../../constants/SelectOptionConstants";

// Page Components
import FileUploadAndListSection from "../../../../page-components/file-list/FileListSection";

/**
 * Plant File List Page
 */
export default function PlantFileListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { plantId = "" } = useParams();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Selector State
  const fileListPagination = useSelector((state) => state.plantFile.fileListPagination) || {};

  // From Url
  const type = searchParams.get(QueryParamsKeys.type) || "";
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber) || "";

  // Pagination
  const { pageNo = DefaultPagination.pageNumber, pageSize = DefaultPagination.pageSize } = fileListPagination || {};

  // Modal id
  const modalId = ModalKeys.fileUploadModal;

  // use Effect
  useEffect(() => {
    dispatch(PlantFileActions.getPlantFileList({ plantId, pageNumber, pageSize, type }));
  }, [dispatch, plantId, pageNumber, pageSize, type]);

  useEffect(() => {
    return () => dispatch(PlantFileActions.resetAllApiSuccessState());
  }, [dispatch]);

  // Download Plant File
  function downloadPlantFile(fileId = "", fileName = "", url = "") {
    dispatch(PlantFileActions.downloadPlantFileById({ fileId, fileName, apiUrl: url }));
  }

  // Delete Plant File
  function deletePlantFile(fileId = "") {
    dispatch(PlantFileActions.deletePlantFileById({ plantId, fileId, pageNumber: pageNo, pageSize, type }));
  }

  // Upload Plant File
  function uploadPlantFile(formData = "", selectedFileId = "") {
    // Plant dispatch action for update and create file
    const plantDispatchAction = selectedFileId ? "updatePlantFileById" : "createPlantFile";

    dispatch(
      PlantFileActions[plantDispatchAction]({
        plantId,
        pageNumber: pageNo,
        pageSize,
        formData,
        type,
        modalId,
        fileId: selectedFileId,
      })
    );
  }

  // Dispatch Actions
  const dispatchActions = {
    downloadFileFn: downloadPlantFile,
    deleteFileFn: deletePlantFile,
    uploadFileFn: uploadPlantFile,
  };

  return (
    <div>
      {/* File Upload And List Section */}
      <FileUploadAndListSection
        fileReduceKey="plantFile"
        dispatchActions={dispatchActions}
        fileTypes={PlantFileTypes}
      />
    </div>
  );
}
