import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { toast } from "react-toastify";

// Actions
import { AuditObservationAndRecommendationActions } from "../../../../redux-slice/audit/ObservationAndRecommendationSlice";

// Constants
import { DataPreLoadKeys } from "../../../../constants/GeneralConstants";

// Utils
import { getItem } from "../../../../app/LocalStorage";
import { formatText } from "../../../../utils/StringUtils";

// Components
import { Button } from "../../../../components/button/Button";
import TableHeaders from "../../../../components/table/TableHeader";
import TableDataNotFound from "../../../../components/table/TableDataNotFound";

// Modal
import AuditObservationModal from "./AuditObservationModal";
import ToggleButton from "../../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Is Observed",
    },
  },
  {
    title: {
      displayName: "Notes",
    },
  },
  {
    title: {
      displayName: "Recommendations",
    },
  },
  {
    title: {
      displayName: "",
    },
  },
];

const DEFAULT_SELECTED_OBSERVATION_OBJ = {
  observationCode: "",
  description: "",
  observationId: "",
  observationCodeId: "",
  notes: "",
  recommendations: [],
  isObserved: "",
};

// Page Components
function AuditObservationListTableActionCell({
  observation = {},
  observationObj = {},
  setSelectedObservation,
  setShowModal = () => {},
}) {
  // Observation Info
  const { code: observationCode, description = "" } = observation;

  // Selected Observation
  const { id = "", observationCodeId = "", notes = "", recommendations = [] } = observationObj;

  // Checking whether the observation is present or not
  const isObserved = Object.keys(observationObj).length !== 0;

  // Open Audit Observation Edit Modal Function
  function openAuditObservationEditModal() {
    setSelectedObservation({
      observationCode,
      description,
      observationId: id,
      observationCodeId,
      notes,
      recommendations,
      isObserved,
    });
    setShowModal(true);
  }

  return (
    <td>
      {/* Button */}
      <Button
        label="Add Recommendations"
        color="success"
        size="sm"
        disabled={!isObserved}
        onClick={openAuditObservationEditModal}
      >
        <i className="fa-solid fa-plus me-1"></i>
      </Button>
    </td>
  );
}

function AuditObservation({ recommendation }) {
  const { id, code } = recommendation;
  return (
    <div key={id} className="mt-2">
      <span className="border border-black bg-transparent rounded-pill">
        <span className="m-2">{code}</span>
      </span>
    </div>
  );
}

function AuditObservationListTableRecommendationCell({ recommendations = [] }) {
  // If there are no recommendations present
  if (recommendations.length === 0) {
    return <td>--</td>;
  }

  const maxItemsToShowCount = 3;
  const maxItemsToShow = recommendations.slice(0, maxItemsToShowCount);
  const remainingItemsCount = recommendations.length - maxItemsToShowCount;

  const tooltipContent = (
    <div>
      {recommendations.map((recommendation, index) => (
        <AuditObservation key={index} recommendation={recommendation} />
      ))}
    </div>
  );

  return (
    <td>
      <div className="row g-0">
        {maxItemsToShow.map((recommendation, index) => (
          <AuditObservation key={index} recommendation={recommendation} />
        ))}

        {remainingItemsCount > 0 && (
          <Tooltip title={tooltipContent}>
            <span className="cursor-pointer mt-2"> +{remainingItemsCount} more</span>
          </Tooltip>
        )}
      </div>
    </td>
  );
}

function AuditObservationListTableCell({ observation = {}, setSelectedObservation, setShowModal = () => {} }) {
  const dispatch = useDispatch();

  const params = useParams();
  const { auditId } = params;

  const { id: observationId } = observation;

  const auditObsAndRecList = useSelector((state) => state.auditObsAndRecList.auditObsAndRecList);

  const loading = useSelector((state) => state.auditObsAndRec.updateObsAndRecLoading[observationId]) || false;
  const isUpdateObsAndRecSuccess =
    useSelector((state) => state.auditObsAndRec.updateObsAndRecSuccess[observationId]) || false;

  // Component Info Selector State
  const { component: componentInfo } = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Finding whether observation is made or not
  const observationObj =
    auditObsAndRecList?.find(({ observationCodeId = "" }) => observationCodeId === parseInt(observationId)) || {};

  // Checking whether the observation is present or not
  const isObserved = Object.keys(observationObj).length !== 0;
  const { id = "", notes = "", recommendations = [] } = observationObj;

  useEffect(() => {
    if (isUpdateObsAndRecSuccess) {
      toast.success("Observations Updated Successfully");
    }
  }, [isUpdateObsAndRecSuccess]);

  // Function add or remove observation
  function addOrRemoveObservation() {
    const { id: componentId = "", componentTypeId = "" } = componentInfo || {};
    const observationData = {
      id,
      auditInfoId: auditId,
      elementStr: "COMPONENT",
      elementId: componentId,
      elementTypeId: componentTypeId,
      observationCodeId: observationId,
      isObserved: !isObserved,
    };

    // Dispatch
    dispatch(AuditObservationAndRecommendationActions.updateAuditObsAndRec({ observationData, observationId }));
  }

  return (
    <>
      <td>
        <ToggleButton isActive={isObserved} loading={loading} onClick={addOrRemoveObservation} />
      </td>

      <td>
        <div className="table-truncate text-truncate" title={notes}>
          {formatText(notes)}
        </div>
      </td>

      {/* Audit Observation List Table Recommendation Cell */}
      <AuditObservationListTableRecommendationCell recommendations={recommendations} />

      {/* Audit Observation List Table Action Cell */}
      <AuditObservationListTableActionCell
        observation={observation}
        observationObj={observationObj}
        setSelectedObservation={setSelectedObservation}
        setShowModal={setShowModal}
      />
    </>
  );
}

function AuditObservationListTableRow({ observation = {}, setSelectedObservation, setShowModal = () => {} }) {
  const { code = "", description = "" } = observation;

  return (
    <tr>
      <td>{code}</td>
      <td>{description}</td>

      {/* Audit Observation List Table Cell */}
      <AuditObservationListTableCell
        observation={observation}
        setSelectedObservation={setSelectedObservation}
        setShowModal={setShowModal}
      />
    </tr>
  );
}

function AuditObservationListTableBody({ observationCodeList = [], setSelectedObservation, setShowModal = () => {} }) {
  //if no audit observation data present
  if (observationCodeList.length === 0) {
    return <TableDataNotFound message="No audit observation data present" colSpan={8} />;
  }

  return (
    <>
      {observationCodeList.map((observation, index) => {
        return (
          <AuditObservationListTableRow
            key={index}
            observation={observation}
            setSelectedObservation={setSelectedObservation}
            setShowModal={setShowModal}
          />
        );
      })}
    </>
  );
}

/**
 * Audit Observation List Table
 */
export default function AuditObservationListTable() {
  const dispatch = useDispatch();

  // State
  const [selectedObservation, setSelectedObservation] = useState(DEFAULT_SELECTED_OBSERVATION_OBJ);
  const [showModal, setShowModal] = useState(false);

  // Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Component Info
  const { componentTypeId = "" } = componentInfo || {};

  // Observation Codes Data
  const { observationCodesByComponent = {} } = JSON.parse(getItem(DataPreLoadKeys.OBSERVATION_CODES || "{}")) || {};

  // use Memo
  const observationCodeList = useMemo(() => {
    return observationCodesByComponent[componentTypeId] || [];
  }, [componentTypeId]);

  useEffect(() => {
    return () => dispatch(AuditObservationAndRecommendationActions.resetAllApiSuccessState());
  }, [dispatch]);

  return (
    <>
      <div className="table-responsive card p-4">
        <h5 className="sec-title mb-3">Observations</h5>

        <table className="table">
          {/* Table Headers */}
          <TableHeaders tableHeaders={tableHeaders} />

          <tbody>
            {/* Audit Observation List Table Body */}
            <AuditObservationListTableBody
              observationCodeList={observationCodeList}
              setSelectedObservation={setSelectedObservation}
              setShowModal={setShowModal}
            />
          </tbody>
        </table>
      </div>

      {/* Audit Observation Modal */}
      <AuditObservationModal
        selectedObservation={selectedObservation}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
