import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.upsertPlantThermalBillSucess = false;
  state.uploadPlantThermalBillSuccess = {};
}

// Initial State
const initialState = {
  // Get plant thermal bill list
  plantThermalBillList: [],
  plantThermalBillListPagination: {},
  plantThermalBillListLoading: false,
  plantThermalBillListError: "",

  // Upsert plant thermal bill
  upsertPlantThermalBillLoading: false,
  upsertPlantThermalBillSucess: false,
  upsertPlantThermalBillError: {},

  // Upload plant thermal bill
  uploadPlantThermalBillLoading: {},
  uploadPlantThermalBillSuccess: {},
  uploadPlantThermalBillError: "",

  // Download plant thermal bill
  downloadPlantThermalBillLoading: {},
  downloadPlantThermalBillError: "",
};

// Plant Thermal Bill Slice
const plantThermalBill = createSlice({
  name: "PlantThermalBill",
  initialState,
  reducers: {
    // Get plant Thermal bill list
    getPlantThermalBillList: (state) => {
      state.plantThermalBillListLoading = true;
    },

    getPlantThermalBillListSuccess: (state, action) => {
      state.plantThermalBillListLoading = false;
      state.plantThermalBillList = action.payload.plantThermalBillList;
      state.plantThermalBillListPagination = action.payload.plantThermalBillListPagination;
    },

    getPlantThermalBillListFailure: (state, action) => {
      state.plantThermalBillListLoading = false;
      state.plantThermalBillListError = action.payload.error;
    },

    // Create plant thermal bill
    createPlantThermalBill: (state) => {
      state.upsertPlantThermalBillSucess = false;
      state.upsertPlantThermalBillLoading = true;
    },

    upsertPlantThermalBillSucess: (state) => {
      state.upsertPlantThermalBillSucess = true;
      state.upsertPlantThermalBillLoading = false;
    },

    createPlantThermalBillFailure: (state, action) => {
      state.upsertPlantThermalBillLoading = false;
      state.upsertPlantThermalBillError = action.payload.error;
    },

    // Update plant thermal bill
    updatePlantThermalBill: (state) => {
      state.upsertPlantThermalBillSucess = false;
      state.upsertPlantThermalBillLoading = true;
    },

    upsertPlantThermalBillSucess: (state) => {
      state.upsertPlantThermalBillSucess = true;
      state.upsertPlantThermalBillLoading = false;
    },

    updatePlantThermalBillFailure: (state, action) => {
      state.upsertPlantThermalBillLoading = false;
      state.upsertPlantThermalBillError = action.payload.error;
    },

    // Upload plant thermal bill
    uploadPlantThermalBill: (state, action) => {
      const { thermalBillId } = action.payload;
      state.uploadPlantThermalBillSuccess[thermalBillId] = false;
      state.uploadPlantThermalBillLoading[thermalBillId] = true;
      state.uploadPlantThermalBillError = "";
    },

    uploadPlantThermalBillSuccess: (state, action) => {
      const { thermalBillId } = action.payload;
      state.uploadPlantThermalBillSuccess[thermalBillId] = true;
      delete state.uploadPlantThermalBillLoading[thermalBillId];
    },

    uploadPlantThermalBillFailure: (state, action) => {
      const { thermalBillId, error } = action.payload;
      delete state.uploadPlantThermalBillLoading[thermalBillId];
      state.uploadPlantThermalBillError = error;
    },

    // Download plant thermal bill
    downloadPlantThermalBill: (state, action) => {
      const { thermalBillId = "" } = action.payload;
      state.downloadPlantThermalBillLoading[thermalBillId] = true;
      state.uploadPlantThermalBillError = "";
    },

    downloadPlantThermalBillSuccess: (state, action) => {
      const { thermalBillId = "" } = action.payload;
      delete state.downloadPlantThermalBillLoading[thermalBillId];
    },

    downloadPlantThermalBillFailure: (state, action) => {
      const { thermalBillId = "", error } = action.payload;
      delete state.downloadPlantThermalBillLoading[thermalBillId];
      state.uploadPlantThermalBillError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantThermalBillReducer = plantThermalBill.reducer;

// Actions
export const PlantThermalBillActions = plantThermalBill.actions;
