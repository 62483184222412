// Audit List
export const getAuditListUrl = "/api/v1/audit/search";
export const getAuditListByAuditorUrl = "/api/v1/audit/auditor/:userId";

// Audit Plant List
export const getAuditPlantComponentListByIdUrl = "/api/v1/audit/:auditId/components";

// Audit
export const getAuditInfoByIdUrl = "/api/v1/audit/:auditId";
export const updatePlantInchargeByIdUrl = "/api/v1/audit/:auditId/plant_incharge/:userId";
export const updateAuditInchargeByIdUrl = "/api/v1/audit/:auditId/audit_incharge/:userId";
export const addAuditorByIdUrl = "/api/v1/audit/:auditId/auditor/add/:userId";
export const removeAuditorByIdUrl = "/api/v1/audit/:auditId/auditor/remove/:userId";
export const getAuditDetailedProgressByIdUrl = "/api/v1/audit/:auditId/progress";
export const updateAuditStartDateUrl = "/api/v1/audit/:auditId/starts/:date";

// Audit - flow
export const auditCreateUrl = "/api/v1/audit/init";
export const startAuditPreAssessmentByIdUrl = "/api/v1/audit/:auditId/pre_assessment/start";
export const startPreAuditByIdUrl = "/api/v1/audit/:auditId/pre_audit/start";

// Audit - Component
export const getAuditComponentInfoByIdUrl = "/api/v1/audit/:auditId/element/COMPONENT/:componentId";
export const getAuditComponentsDetailUrl = "/api/v1/audit/:auditId/elements";
export const addAuditWalkthroughRemarksUrl = "/api/v1/audit/:auditId/element/COMPONENT/:componentId";
export const addAuditWalkthroughImageUrl = "/api/v1/audit/:auditId/element/COMPONENT/:componentId/image";
export const addAuditComponentRemarksUrl = "/api/v1/audit/:auditId/element/COMPONENT/:componentId/remark";

// Audit - Parameter Value
export const upsertParameterValuesUrl = "/api/v1/audit/:auditId/param_val/COMPONENT/:componentId";

// Audit - instrument
export const getAuditInstrumentDataListByIdUrl = "/api/v1/audit/instrument_data/search";
export const uploadAuditInstrumentDataFileUrl = "/api/v1/audit/instrument_data/upload";
export const getAuditInstrumentDataInfoByIdUrl = "/api/v1/audit/instrument_data/:instrumentDataId";
export const selectAuditInstrumentDataByIdApiUrl = "/api/v1/audit/instrument_data/:instrumentDataId/measurements";

// Audit - observation and recommendation
export const getAuditObservationAndRecommendationListByIdUrl = "/api/v1/audit/:auditId/observation/search";
export const updateAuditObservationAndRecommendationUrl = "/api/v1/audit/:auditId/observation/";
export const updateRecommendationInvestmentUrl = "/api/v1/audit/:auditId/recommendation/:recommendationId";

// Audit - Data
export const downloadPerfAnalysisDataUrl = "/api/v1/audit/:auditId/data/param_val/download/xls";
export const downloadRecommendationDataUrl = "/api/v1/audit/:auditId/data/recommendation/download/xls";

// Audit - Report
export const createAuditReportUrl = "/api/v1/audit/:auditId/report";
export const updateAuditReportUrl = "/api/v1/audit/:auditId/report/:reportId";
export const deleteAuditReportUrl = "/api/v1/audit/:auditId/report/:reportId";
export const getAuditReportHTMLUrl = "/api/v1/audit/:auditId/report/:reportId";
export const downloadAuditReportPDFUrl = "/api/v1/audit/:auditId/report/:reportId/pdf";
export const updateAuditReportStatusUrl = "/api/v1/audit/:auditId/report/:reportId/status";
export const getAuditReportDataUrl = "/api/v1/audit/:auditId/report/data";

// Audit - Reports List
export const getAuditReportsListUrl = "/api/v1/audit/:auditId/report";

// Audit Files
export const getAuditFileListUrl = "/api/v1/audit/:auditId/file/search";
export const createAuditFileUrl = "/api/v1/audit/:auditId/file";
export const updateAuditFileByIdUrl = "/api/v1/audit/:auditId/file/:fileId";
export const deleteAuditFileByIdUrl = "/api/v1/audit/:auditId/file/:fileId";
