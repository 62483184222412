import { toast } from "react-toastify";
import { all, put, takeEvery } from "redux-saga/effects";

// Actions
import { ErrorActions } from "../../app/error/ErrorSlice";
import { ComponentTypeListActions } from "../../redux-slice/component-type/ComponentTypeListSlice";
import { ComponentTypeActions } from "../../redux-slice/component-type/ComponentTypeSlice";

// APIs
import {
  getComponentTypeInfoByIdApi,
  updateComponentTypeStatusByIdApi,
  updateRecommendationCodeStatusByIdApi,
  updateObservationCodeStatusByIdApi,
  updateParameterStatusByIdApi,
} from "../../api/component-type/ComponentTypeAPI";

import {
  getComponentTypeListApi,
  getRecommendationCodeListByComponentTypeIdApi,
  getObservationCodeListByComponentTypeIdApi,
  getParameterListByComponentTypeIdApi,
} from "../../api/component-type/ComponentTypeListAPI";

// Get Component Information By Id
function* getComponentTypeInfoById(action) {
  const { componentTypeId } = action.payload;
  try {
    const componentTypeInfo = yield getComponentTypeInfoByIdApi(componentTypeId);

    yield put(ComponentTypeActions.getComponentTypeInfoSuccess({ componentTypeInfo }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.getComponentTypeInfoFailure({ error }));
  }
}

// Update Component Type Status By Id
function* updateComponentTypeStatusById(action) {
  const { componentTypeId, status, pageNumber, pageSize } = action.payload;
  try {
    yield updateComponentTypeStatusByIdApi(componentTypeId, status);

    const { items, pagination } = yield getComponentTypeListApi(pageNumber, pageSize);

    yield put(
      ComponentTypeListActions.getComponentTypeListSuccess({
        componentTypeList: items,
        componentTypeListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateComponentTypeStatusSuccess({ componentTypeId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateComponentTypeStatusFailure({ componentTypeId, error }));
  }
}

// Update Observation Code Status By Id
function* updateObservationCodeStatusById(action) {
  const { componentTypeId, obsCodeId, status, pageNumber, pageSize } = action.payload;
  try {
    yield updateObservationCodeStatusByIdApi(obsCodeId, status);

    const { items, pagination } = yield getObservationCodeListByComponentTypeIdApi(
      componentTypeId,
      pageNumber,
      pageSize
    );

    yield put(
      ComponentTypeListActions.getObsCodeListSuccess({
        observationCodeList: items,
        observationCodeListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateObservationCodeStatusSuccess({ obsCodeId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateObservationCodeStatusFailure({ obsCodeId, error }));
  }
}

// Update Recommendation Code Status By Id
function* updateRecommendationCodeStatusById(action) {
  const { componentTypeId, recCodeId, status, pageNumber, pageSize } = action.payload;
  try {
    yield updateRecommendationCodeStatusByIdApi(recCodeId, status);

    const { items, pagination } = yield getRecommendationCodeListByComponentTypeIdApi(
      componentTypeId,
      pageNumber,
      pageSize
    );

    yield put(
      ComponentTypeListActions.getRecCodeListSuccess({
        recCodeList: items,
        recommendationCodeListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateRecommendationCodeStatusSuccess({ recCodeId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateRecommendationCodeStatusFailure({ recCodeId, error }));
  }
}

// Update Parameter Status By Id
function* updateParameterStatusById(action) {
  const { componentTypeId, parameterType, parameterId, status, pageNumber, pageSize, searchText } = action.payload;
  try {
    yield updateParameterStatusByIdApi(parameterId, status);

    const { items, pagination } = yield getParameterListByComponentTypeIdApi(
      componentTypeId,
      parameterType,
      pageNumber,
      pageSize,
      searchText
    );

    yield put(
      ComponentTypeListActions.getParameterListSuccess({
        parameterList: items,
        parameterListPagination: pagination,
      })
    );

    yield put(ComponentTypeActions.updateParameterTypeStatusSuccess({ parameterId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ComponentTypeActions.updateParameterTypeStatusFailure({ parameterId, error }));
  }
}

export default function* root() {
  yield all([
    takeEvery(ComponentTypeActions.getComponentTypeInfo.type, getComponentTypeInfoById),
    takeEvery(ComponentTypeActions.updateComponentTypeStatus.type, updateComponentTypeStatusById),
    takeEvery(ComponentTypeActions.updateObservationCodeStatus.type, updateObservationCodeStatusById),
    takeEvery(ComponentTypeActions.updateRecommendationCodeStatus.type, updateRecommendationCodeStatusById),
    takeEvery(ComponentTypeActions.updateParameterTypeStatus.type, updateParameterStatusById),
  ]);
}
