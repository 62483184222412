import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditComponentActions } from "../../redux-slice/audit/ComponentSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import {
  addAuditWalkthroughRemarksApi,
  addAuditWalkthroughImageApi,
  getAuditComponetInfoApi,
  addAuditComponentRemarksApi,
  getAuditComponentsDetailApi,
} from "../../api/audit/ComponentAPI";

// Get Audit Component Info By Id
function* getAuditComponentInfoById(action) {
  try {
    const { auditId, componentId } = action.payload;

    const auditComponentInfo = yield getAuditComponetInfoApi(auditId, componentId);

    // Dispatching Action
    yield put(AuditComponentActions.getAuditComponentInfoSuccess({ auditComponentInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.getAuditComponentInfoFailure({ error }));
  }
}

// Get Components Detail
function* getComponentsDetail(action) {
  try {
    const { auditId } = action.payload;

    const componentsDetail = yield getAuditComponentsDetailApi(auditId);

    // Dispatching Action
    yield put(AuditComponentActions.getComponentsDetailSuccess({ componentsDetail }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.getComponentsDetailFailure({ error }));
  }
}

// Add Walkthorugh remarks
function* addWalkthroughRemarks(action) {
  try {
    const { auditId, componentId, formData } = action.payload;

    yield addAuditWalkthroughRemarksApi(auditId, componentId, formData);

    const auditComponentInfo = yield getAuditComponetInfoApi(auditId, componentId);

    yield put(AuditComponentActions.getAuditComponentInfoSuccess({ auditComponentInfo }));

    yield put(AuditComponentActions.addWalkthroughRemarksSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.addWalkthroughRemarksFailure({ error }));
  }
}

// Add Walkthrough Remarks Image
function* addWalkthroughImage(action) {
  try {
    const { auditId, componentId, formData } = action.payload;

    yield addAuditWalkthroughImageApi(auditId, componentId, formData);

    const auditComponentInfo = yield getAuditComponetInfoApi(auditId, componentId);

    yield put(AuditComponentActions.getAuditComponentInfoSuccess({ auditComponentInfo }));

    yield put(AuditComponentActions.addWalkthroughImageSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.addWalkthroughImageFailure({ error }));
  }
}

// Add Component Remarks
function* addComponentRemarks(action) {
  try {
    const { auditId, componentId, formData } = action.payload;

    yield addAuditComponentRemarksApi(auditId, componentId, formData);

    yield put(AuditComponentActions.addComponentRemarksSuccess({ componentId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditComponentActions.addComponentRemarksFailure({ error, componentId }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditComponentActions.getAuditComponentInfo.type, getAuditComponentInfoById),
    takeLatest(AuditComponentActions.getComponentsDetail.type, getComponentsDetail),
    takeLatest(AuditComponentActions.addWalkthroughRemarks.type, addWalkthroughRemarks),
    takeLatest(AuditComponentActions.addWalkthroughImage.type, addWalkthroughImage),
    takeLatest(AuditComponentActions.addComponentRemarks.type, addComponentRemarks),
  ]);
}
