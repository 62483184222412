import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.resetPasswordSuccess = false;
  state.updateUserPasswordSuccess = false;
}

//intital state
const initialState = {
  authUser: {},
  loginLoading: false,
  loginError: "",

  // Reset Password
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  resetPasswordError: "",

  // Update User Password
  updateUserPasswordLoading: false,
  updateUserPasswordSuccess: false,
  updateUserPasswordError: "",
};

// Auth Slice
const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    //----Action to login-----//
    login: (state) => {
      state.loginLoading = true;
    },

    loginSuccess: (state, action) => {
      state.loginLoading = false;
      state.authUser = action.payload.loggedInData;
    },

    loginFailure: (state, action) => {
      state.loginLoading = false;
      state.loginError = action.payload.error;
    },

    // Reset Password
    resetPassword: (state) => {
      state.resetPasswordSuccess = false;
      state.resetPasswordLoading = true;
    },

    resetPasswordSuccess: (state) => {
      state.resetPasswordSuccess = true;
      state.resetPasswordLoading = false;
      state.resetPasswordError = "";
    },

    resetPasswordFailure: (state, action) => {
      state.resetPasswordLoading = false;
      state.resetPasswordError = action.payload.error;
    },

    // Update User Password
    updateUserPassword: (state) => {
      state.updateUserPasswordSuccess = false;
      state.updateUserPasswordLoading = true;
    },

    updateUserPasswordSuccess: (state) => {
      state.updateUserPasswordSuccess = true;
      state.updateUserPasswordLoading = false;
      state.updateUserPasswordError = "";
    },

    updateUserPasswordFailure: (state, action) => {
      state.updateUserPasswordLoading = false;
      state.updateUserPasswordError = action.payload.error;
    },

    //----Action to clear Login Details-----//
    logout: () => initialState,

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuthReducer = authSlice.reducer;

// Actions
export const AuthActions = authSlice.actions;
