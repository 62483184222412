import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useRevalidator } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";

// Actions
import { AuditActions } from "../../../../redux-slice/audit/AuditSlice";

// Utils
import { toTimestamp } from "../../../../utils/DateUtils";

// Components
import CustomDatePicker from "../../../../components/date-picker/DatePicker.tsx";
import { DateFormat } from "../../../../constants/GeneralConstants";

// Page Components
function DateActions({ isDatePickerDisabled = true, setIsDatePickerDisabled = () => {}, selectedDate = null }) {
  const { auditId = "" } = useParams();

  const dispatch = useDispatch();

  const iconClassName = isDatePickerDisabled ? "fa-pencil text-primary" : "fa-circle-xmark text-danger";

  function handleToggleDatePicker() {
    setIsDatePickerDisabled((prev) => !prev);
  }

  function updateDate() {
    const date = toTimestamp(selectedDate);
    dispatch(AuditActions.updateStartDate({ auditId, date }));
  }

  return (
    <>
      <i className={`fas ${iconClassName} ps-3 fa-lg cursor-pointer`} onClick={handleToggleDatePicker}></i>

      {!isDatePickerDisabled && (
        <i className="fas fa-circle-check fa-lg ms-2 text-success cursor-pointer" onClick={updateDate}></i>
      )}
    </>
  );
}

/**
 * Audit Date
 * @param {*} auditInfo
 */
export default function AuditDate({ auditInfo = {} }) {
  const { startTimestamp = "" } = auditInfo;

  const [selectedDate, setSelectedDate] = useState(null);
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(true);

  // Revalidator Hook
  const revalidator = useRevalidator();

  const updateStartDateLoading = useSelector((state) => state.audit.updateStartDateLoading);
  const updateStartDateSuccess = useSelector((state) => state.audit.updateStartDateSuccess);

  useEffect(() => {
    const dateObj = moment(startTimestamp);
    setSelectedDate(dateObj);
  }, [isDatePickerDisabled, startTimestamp]);

  useEffect(() => {
    if (updateStartDateSuccess) {
      revalidator.revalidate();

      toast.success("Start date updated successfully");
      setIsDatePickerDisabled(true);
    }
  }, [updateStartDateSuccess]);

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  return (
    <div className="row align-items-center">
      <div className="col-4 bold">Start Date</div>

      <div className="col-5">
        <div className="d-flex align-items-center">
          <CustomDatePicker
            className="w-100 border rounded-0 shadow-none cursor-pointer"
            size="small"
            value={selectedDate}
            format={DateFormat.DATE_MONTH_YEAR_SLASH}
            onChange={handleDateChange}
            placeholder={DateFormat.DATE_MONTH_YEAR_SLASH}
            disabled={isDatePickerDisabled}
          />

          {updateStartDateLoading && <i className="fa-solid fa-circle-notch fa-spin fa-lg ms-2"></i>}

          {!updateStartDateLoading && (
            <DateActions
              isDatePickerDisabled={isDatePickerDisabled}
              setIsDatePickerDisabled={setIsDatePickerDisabled}
              selectedDate={selectedDate}
            />
          )}
        </div>
      </div>
    </div>
  );
}
