import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { PlantListActions } from "../../redux-slice/plant/PlantListSlice";
import { PlantActions } from "../../redux-slice/plant/PlantSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import {
  addPlantApi,
  updatePlantByIdApi,
  updatePlantStatusByIdApi,
  getPlantInfoByIdApi,
} from "../../api/plant/PlantAPI";

import { getPlantListApi } from "../../api/plant/PlantListAPI";

// Add Plant
function* addPlant(action) {
  try {
    const { plantData } = action.payload;

    yield addPlantApi(plantData);

    yield put(PlantActions.addPlantSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.addPlantFailure({ error }));
  }
}

// Update Plant By Id
function* updatePlantById(action) {
  try {
    const { plantData, plantId } = action.payload;

    yield updatePlantByIdApi(plantData, plantId);

    const plantInfo = yield getPlantInfoByIdApi(plantId);

    yield put(PlantActions.getPlantInfoSuccess({ plantInfo }));

    yield put(PlantActions.updatePlantSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.updatePlantFailure({ error }));
  }
}

// Update Plant Status By Id
function* updatePlantStatusById(action) {
  try {
    const { orgId, plantId, status, pageNumber, pageSize } = action.payload;

    yield updatePlantStatusByIdApi(plantId, status);

    const { items, pagination } = yield getPlantListApi(orgId, pageNumber, pageSize);

    yield put(
      PlantListActions.getPlantListSuccess({
        plantList: items,
        plantListPagination: pagination,
      })
    );

    yield put(PlantActions.updatePlantStatusSuccess({ plantId }));
  } catch (error) {
    const { plantId } = action.payload;

    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.updatePlantStatusFailure({ error, plantId }));
  }
}

// Get Plant Info By Id
function* getPlantInfoById(action) {
  try {
    const { plantId } = action.payload;

    const plantInfo = yield getPlantInfoByIdApi(plantId);

    // Dispatching Action
    yield put(PlantActions.getPlantInfoSuccess({ plantInfo }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantActions.getPlantInfoFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantActions.addPlant.type, addPlant),
    takeLatest(PlantActions.updatePlant.type, updatePlantById),
    takeEvery(PlantActions.updatePlantStatus.type, updatePlantStatusById),
    takeLatest(PlantActions.getPlantInfo.type, getPlantInfoById),
  ]);
}
