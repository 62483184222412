// Organization Types
export const OrgTypes = {
  PLATFORM: {
    id: "PLATFORM",
    name: "IEAC",
    nameOfList: "",
    nameLowercase: "platform",
  },
  AUDIT: {
    id: "AUDIT",
    name: "Audit Firm",
    nameOfList: "Audit Firms",
    nameLowercase: "audit",
  },
  INDUSTRY: {
    id: "INDUSTRY",
    name: "Industry",
    nameOfList: "Industries",
    nameLowercase: "industry",
  },
};

// Pagination
export const DefaultPagination = {
  pageNumber: 1,
  pageSize: 10,
  totalCount: 0,
  maxPageSize: 1000,
};

export const QueryParamsKeys = {
  pageNumber: "pn",
  pageSize: "ps",
  orgType: "orgType",
  type: "type",
  searchText: "search",
  tabId: "tabId",
  tabName: "tabName",
  sectionId: "sectionId",
  componentType: "componentType",
  componentTypeId: "componentTypeId",
};

export const DataPreLoadKeys = {
  MEASUREMENTS_AND_UNITS: "MEASUREMENTS_AND_UNITS",
  COMPONENT_TYPES: "COMPONENT_TYPES",
  RECOMMENDATION_CODES: "RECOMMENDATION_CODES",
  MATERIALS: "MATERIALS",
  PRODUCTS: "PRODUCTS",
  OBSERVATION_CODES: "OBSERVATION_CODES",
};

// Server Errors
export const ServerErrorCodes = [500, 502, 503];

// Page Errors
export const PageErrorCodes = [401, 404];

// Layout Ids
export const LayoutIds = {
  AUDIT: "audit",
  PLANT: "plant",
  USER: "user",
};

// Data Types
export const DataTypes = ["DOUBLE", "FLOAT", "INTEGER"];

// Value Types
export const ValueTypes = {
  DATE: "DATE",
  TIME: "TIME",
  DURATION: "DURATION",
  FORMULA: "FORMULA",
};

// Date Format
export const DateFormat = {
  DATE_MONTH_YEAR: "DD-MM-YYYY",
  DATE_MONTH_YEAR_SLASH: "DD/MM/YYYY",
};

// Common Date and Time Format
export const DateTimeFormat = {
  date_time: "lll",
};

// Audit Report
export const AuditReportTypes = {
  PERF_ANALYSIS: "PERF_ANALYSIS",
};

export const AuditReportContentType = {
  TEXT_HTML: "Text/HTML",
  PDF: "PDF",
};

// File Content Types
export const FILE_CONTENT_TYPES = {
  jpeg: "image/jpeg",
  png: "image/png",
  pdf: "application/pdf",
};
