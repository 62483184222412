import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";

// Actions
import { AuthActions } from "../../redux-slice/auth/AuthSlice";

// Urls
import { RedirectTo } from "../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../components/button/Button";
import Input from "../../components/input/Input";

// Images
import LOGO_IITM from "../../assests/images/branding/IIT_M_LOGO.png";
import KISEM_LOGO from "../../assests/images/branding/KISEM_LOGO.png";
import KOTAK_CSR_LOGO from "../../assests/images/branding/KOTAK_CSR_LOGO.png";

// Validation Schema
const formValidationSchema = yup.object({
  email: yup.string().email("Please provide valid email").required("Please fill the User Name"),
  password: yup.string().required("Please fill the Password"),
});

// Page Components
function LoginError({ loginError }) {
  //If No error
  if (!loginError) {
    return null;
  }

  return (
    <div className="card bg-secondary-subtle">
      <div className="card-body text-center text-danger">
        <i className="fa-solid fa-circle-xmark me-2"></i>
        Username/Password is incorrect
      </div>
    </div>
  );
}

function UserNameInputSection({ formik }) {
  const { values, handleChange, handleBlur, errors, touched } = formik;

  return (
    <div className="mb-3">
      <label>Username</label>
      <Input
        name="email"
        className="form-control mt-2 shadow-none"
        placeholder="Enter username"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && errors.email}
      />
    </div>
  );
}

function PasswordInputSection({ formik }) {
  const { values, handleChange, handleBlur, errors, touched } = formik;

  return (
    <div className="mb-3">
      <label>Password</label>
      <div className=" mt-2">
        {/* Input */}
        <Input
          name="password"
          type="password"
          className="shadow-none"
          placeholder="Enter password"
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.password && errors.password}
        />
      </div>
    </div>
  );
}

function ForgotPasswordSection() {
  return (
    <div className="mt-4 text-center d-none">
      <Link to={RedirectTo.ForgotPasswordPageUrl} className="text-muted text-decoration-none">
        <i className="fa-solid fa-lock me-2"></i>
        Forgot your password?
      </Link>
    </div>
  );
}

function LoginCardBody() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Selector State
  const loginLoading = useSelector((state) => state.auth.loginLoading);
  const loginError = useSelector((state) => state.auth.loginError);

  // Form Validation
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: formValidationSchema,
    onSubmit: (user) => {
      const { email = "", password = "" } = user || {};

      const loginFormData = new FormData();
      loginFormData.set("username", email);
      loginFormData.set("password", password);

      // Dispatch
      dispatch(AuthActions.login({ loginFormData, navigate }));
    },
  });

  const { handleSubmit } = formik;

  return (
    <div className="position-relative">
      <div className="p-2 pt-3">
        <form onSubmit={handleSubmit}>
          {/* User Name Input Section */}
          <UserNameInputSection formik={formik} />

          {/* Password Input Section */}
          <PasswordInputSection formik={formik} />

          {/* Login Error */}
          <LoginError loginError={loginError} />

          <div className="mt-4 d-grid">
            {/* Button Component */}
            <Button
              className="btn-block"
              type="submit"
              label="Login"
              loading={loginLoading}
              disabled={loginLoading}
              color="primary"
            />
          </div>

          {/* Forgot Password Section */}
          <ForgotPasswordSection />
        </form>
      </div>
    </div>
  );
}

function LoginCardFooter() {
  return (
    <div className="mt-5 text-center text-white">
      <p>© {new Date().getFullYear()} KISEM </p>
    </div>
  );
}

function LoginCard() {
  return (
    <div className="card mt-4">
      <div className="card-body">
        {/* Login Card Body */}
        <LoginCardBody />
      </div>
    </div>
  );
}

function PageHeaderContent() {
  return (
    <div className="card shadow-lg m-4 mx-auto w-50">
      <div className="card-body bg-info bg-opacity-10">
        <div className="d-flex justify-content-center align-items-center">
          {/* KISEM Logo */}
          <img src={KISEM_LOGO} alt="KISEM" height={80} />

          <span className="mx-4 px-3 text-center">
            <div className="fw-semibold fs-3">KISEM Energy Audit Tool</div>
            <div className="border border-top border-1 my-2"></div>
            <div className="fs-5 text-success">Indian Institue of Technology Madras</div>
          </span>

          {/* IIT Logo */}
          <img src={LOGO_IITM} alt="IIT" height={80} />
        </div>
      </div>
    </div>
  );
}

function PageContent() {
  return (
    <div className="m-4 mt-5 fs-5 text-center">
      <p>
        The Energy Audit Tool developed by Kotak - IIT Madras Save Energy Mission (KISEM) under the CSR initiative of
        Kotak Mahindra Bank is a first of its kind digital platform made exclusively for performing various forms of
        Energy Assessment Studies such as Baseline Energy Survey, Walk-Through Energy Audit, Detailed Energy Audit,
        Performance Study of Utilities, Energy Efficient Technology Intervention Assessment, Techno-Economic Assessment
        of Energy Conservation Measures, Baseline GHG Emission Study, etc for all kind of Process Industries, Commercial
        Spaces and all other Bulk Energy Consumers. This tool reduces the manual intervention in the overall assessment
        activity to a great extent by producing accurate results in a limited time span when compared to existing
        conventional energy audit process.
      </p>
      <p>
        This Energy Audit Tool is pre-loaded with 25 plus utilities found in most of the process industries and
        commercial spaces that support the main process and being the significant energy consumers. Each and every
        utility will have pre-defined standard set of data collection fields and modules such as Design Data, Field
        Data, Performance Module, Observation & Recommendation Module and Savings Module, loaded with necessary formulas
        in the backend to instantly evaluate its overall performance and energy saving potential. Assessment reports can
        be immediately generated from the tool in the required formats.
      </p>
      <p>
        On the whole, this KISEM Energy Audit Tool comes as a complete package for conducting wide range of Energy and
        Emission studies for Industries, Commercial Establishments & all other Bulk Energy Consumers
      </p>

      <div className="text-center mt-5">
        <img src={KOTAK_CSR_LOGO} height={100} alt="Kotak CSR" />
      </div>
    </div>
  );
}

/**
 * Login Page
 */
export default function LoginPage() {
  return (
    <div className="container-fluid">
      <div className="row vh-100">
        <div className="col-8 p-5">
          {/* Page Header Content */}
          <PageHeaderContent />

          {/* Page Content */}
          <PageContent />
        </div>
        <div className="col-4 bg-dark p-5 d-flex align-items-center justify-content-center">
          <div>
            <div className="text-white fs-5">Sign in to continue to KISEM.</div>

            {/* Login Card */}
            <LoginCard />

            {/* Login Footer */}
            <LoginCardFooter />
          </div>
        </div>
      </div>
    </div>
  );
}
