import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// Actions
import { AuditActions } from "../../../../redux-slice/audit/AuditSlice";

// Constants
import { DataPreLoadKeys } from "../../../../constants/GeneralConstants";

// Utils
import { getItem } from "../../../../app/LocalStorage";

// Components
import Loader from "../../../../components/loader/Loader";
import TableHeaders from "../../../../components/table/TableHeader";
import TableDataNotFound from "../../../../components/table/TableDataNotFound";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "S.No.",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Image",
    },
  },
  {
    title: {
      displayName: "Walkthrough Remarks",
    },
  },
  {
    title: {
      displayName: "Data Files",
    },
  },
  {
    title: {
      displayName: "Measurements",
    },
  },
  {
    title: {
      displayName: "Observations",
    },
  },
  {
    title: {
      displayName: "Recommendations",
    },
  },
];

// Page Components
function ComponentsTableBodyChunkHeaderRow({ chunckHeader }) {
  return (
    <tr className="tr-sec-header">
      <td colSpan={tableHeaders.length}>{chunckHeader}</td>
    </tr>
  );
}

function ComponentsTableBodyChunkDataRow({ componentsList }) {
  return componentsList.map((component, index) => {
    const {
      name = "",
      internalId = "",
      imageURL = "",
      notes = "",
      dataFilesCount = 0,
      measurementsCount = 0,
      observationsCount = 0,
      recommendationsCount = 0,
    } = component || {};

    const measurementsCountClasName = measurementsCount === 0 ? "table-danger" : "";

    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>
          {name}
          <span className="sub-text"># {internalId}</span>
        </td>
        <td>
          {imageURL && ( //
            <img alt="" src={imageURL} height={40} className="me-2" />
          )}
        </td>
        <td>{notes || "-"}</td>

        <td>{dataFilesCount}</td>

        <td className={measurementsCountClasName}>{measurementsCount}</td>
        <td>{observationsCount}</td>
        <td>{recommendationsCount}</td>
      </tr>
    );
  });
}

function ComponentsTableBodyChunk({ componentTypeInfo = {}, componentsList = [] }) {
  // Data
  const { code, name } = componentTypeInfo;

  // Getting number of components
  const componentsListLen = componentsList?.length;

  // Header for each component type in table
  const chunckHeader = name + " - " + code + " (" + componentsListLen + ")";

  return (
    <>
      {/* Components Table Body Chunk Header Row */}
      <ComponentsTableBodyChunkHeaderRow key={code} code={code} chunckHeader={chunckHeader} />

      {/* Components Table Body Chunk Data Row */}
      <ComponentsTableBodyChunkDataRow componentsList={componentsList} />
    </>
  );
}

function AuditStatusTableBody({ auditDetailedProgress = {}, componentTypes = [] }) {
  const auditDetailedProgressKeys = Object.keys(auditDetailedProgress);

  // If there are no components added
  if (auditDetailedProgressKeys.length === 0) {
    return <TableDataNotFound message="No components present !!" colSpan={8} />;
  }

  return Object.keys(auditDetailedProgress).map((componentTypeId) => {
    // Getting the component type information
    const componentTypeInfo =
      componentTypes.find((componentType) => componentType.id === parseInt(componentTypeId)) || {};

    const componentsList = auditDetailedProgress[componentTypeId] || [];

    return (
      <ComponentsTableBodyChunk
        key={componentTypeId}
        componentTypeInfo={componentTypeInfo}
        componentsList={componentsList}
      />
    );
  });
}

/**
 * Audit Status Page
 */
export default function AuditStatusPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Selector State
  const auditDetailedProgress = useSelector((state) => state.audit.auditDetailedProgress);
  const auditDetailedProgressLoading = useSelector((state) => state.audit.auditDetailedProgressLoading);

  // Definitions Data
  const { componentTypes } = JSON.parse(getItem(DataPreLoadKeys.COMPONENT_TYPES) || "{}");

  // use Effect
  useEffect(() => {
    dispatch(AuditActions.getAuditDetailedProgress({ auditId }));
  }, [dispatch, auditId]);

  // Loader
  if (auditDetailedProgressLoading) {
    return <Loader />;
  }

  return (
    <div className="page-content">
      <div>{/** Current Status text */}</div>
      <div className="table-responsive">
        <table className="table table-compact text-center">
          {/* Table Headers */}
          <TableHeaders tableHeaders={tableHeaders} />

          <tbody>
            {/* Audit Status Table Body */}
            <AuditStatusTableBody auditDetailedProgress={auditDetailedProgress} componentTypes={componentTypes} />
          </tbody>
        </table>
      </div>
    </div>
  );
}
