import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";
import { PlantActions } from "../../../redux-slice/plant/PlantSlice";

// Utils
import URLs from "../../../app/utils/URLs";
import { bcForPlantCreatePage } from "../../../utils/BreadcrumbUtils";

// URLs
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import Form, { FormTypes } from "../../../components/form/Form";
import PageHeader from "../../../components/page-header/PageHeader";

// Form Schema
import { PlantFormSchema, PlantFormValidationSchema } from "../../../form-schema/PlantFormSchema";

// Page Components
function PageHeaderSection() {
  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Organization name and Id
  const { id: orgId = "", name: orgName = "" } = orgInfo;

  return <PageHeader breadcrumbList={bcForPlantCreatePage(orgId, orgName)} className="mt-2" actions={<></>} />;
}

/**
 * Add Plant Page
 */
export default function PlantCreatePage() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Params
  const params = useParams();
  const { orgId = "" } = params;

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Add Plant Selector State
  const addPlantLoading = useSelector((state) => state.plant.addPlantLoading);
  const addPlantSuccess = useSelector((state) => state.plant.addPlantSuccess);

  // useEffect
  useEffect(() => {
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
  }, [dispatch, orgId]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  useEffect(() => {
    if (addPlantSuccess) {
      toast.success("Plant Added Successfully");

      // Navigate
      const plantListPageURL = URLs.format(RedirectTo.PlantListPageUrl, { orgId });
      navigate(plantListPageURL);
    }
  }, [addPlantSuccess, orgId]);

  // onSubmit function
  function formSubmit(plantData) {
    plantData = { ...plantData, orgId: parseInt(orgId) };
    dispatch(PlantActions.addPlant({ plantData, navigate }));
  }

  return (
    <div className="page-content">
      {/* Form */}
      <Form
        type={FormTypes.CREATE}
        showCancelButton={false}
        loading={addPlantLoading}
        formInputItems={PlantFormSchema}
        formSubmit={formSubmit}
        formValidationSchema={PlantFormValidationSchema}
      />
    </div>
  );
}
