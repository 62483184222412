import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantThermalBillActions } from "../../redux-slice/plant/ThermalBillSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Utils
import { saveFile } from "../../utils/FileUtils";

// APIs
import {
  createPlantThermalBillApi,
  downloadPlantThermalBillByIdApi,
  getPlantThermalBillListByIdApi,
  updatePlantThermalBillByIdApi,
  uploadPlantThermalBillByIdApi,
} from "../../api/plant/ThermalBillAPI";

// Get Plant Thermal Bill List By Id
function* getPlantThermalBillListById(action) {
  try {
    const { pageNumber, pageSize, searchText, plantId } = action.payload;

    const { items, pagination } = yield getPlantThermalBillListByIdApi(plantId, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      PlantThermalBillActions.getPlantThermalBillListSuccess({
        plantThermalBillList: items,
        plantThermalBillListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.getPlantThermalBillListFailure({ error }));
  }
}

// Create Plant Thermal Bill
function* createPlantThermalBill(action) {
  try {
    const { formData, plantId } = action.payload;

    yield createPlantThermalBillApi(formData, plantId);

    const { items, pagination } = yield getPlantThermalBillListByIdApi(plantId);

    yield put(
      PlantThermalBillActions.getPlantThermalBillListSuccess({
        plantThermalBillList: items,
        plantThermalBillListPagination: pagination,
      })
    );

    yield put(PlantThermalBillActions.upsertPlantThermalBillSucess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.createPlantThermalBillFailure({ error }));
  }
}

// Update Plant Thermal Bill
function* updatePlantThermalBillById(action) {
  try {
    const { formData, plantId, thermalBillId } = action.payload;

    yield updatePlantThermalBillByIdApi(formData, plantId, thermalBillId);

    const { items, pagination } = yield getPlantThermalBillListByIdApi(plantId);

    yield put(
      PlantThermalBillActions.getPlantThermalBillListSuccess({
        plantThermalBillList: items,
        plantThermalBillListPagination: pagination,
      })
    );

    yield put(PlantThermalBillActions.upsertPlantThermalBillSucess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.updatePlantThermalBillFailure({ error }));
  }
}

// Upload Plant Thermal Bill By Id
function* uploadPlantThermalBillById(action) {
  const { formData, plantId, thermalBillId } = action.payload;

  try {
    yield uploadPlantThermalBillByIdApi(formData, plantId, thermalBillId);

    const { items, pagination } = yield getPlantThermalBillListByIdApi(plantId);

    yield put(
      PlantThermalBillActions.getPlantThermalBillListSuccess({
        plantThermalBillList: items,
        plantThermalBillListPagination: pagination,
      })
    );

    yield put(PlantThermalBillActions.uploadPlantThermalBillSuccess({ thermalBillId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.uploadPlantThermalBillFailure({ thermalBillId, error }));
  }
}

// Download Plant Thermal Bill By Id
function* downloadPlantThermalBillById(action) {
  const { apiUrl = "", thermalBillId } = action.payload;

  try {
    const data = yield downloadPlantThermalBillByIdApi(apiUrl);

    saveFile("Thermal Bill", data);

    yield put(PlantThermalBillActions.downloadPlantThermalBillSuccess({ thermalBillId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantThermalBillActions.downloadPlantThermalBillFailure({ thermalBillId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantThermalBillActions.getPlantThermalBillList.type, getPlantThermalBillListById),
    takeLatest(PlantThermalBillActions.createPlantThermalBill.type, createPlantThermalBill),
    takeLatest(PlantThermalBillActions.updatePlantThermalBill.type, updatePlantThermalBillById),
    takeLatest(PlantThermalBillActions.uploadPlantThermalBill.type, uploadPlantThermalBillById),
    takeLatest(PlantThermalBillActions.downloadPlantThermalBill.type, downloadPlantThermalBillById),
  ]);
}
