// Urls
import {
  addPlantComponentByIdUrl,
  updatePlantComponentByIdUrl,
  plantComponentImageByIdUrl,
  decommissionComponentUrl,
} from "../../urls/api-urls/PlantURL";

// App Client
import HttpClientFactory from "../../app/http/HttpClientFactory";

// App Constants
import { ContentType } from "../../app/http/HttpConstants";

// Utils
import URLs from "../../app/utils/URLs";

// Add Plant Component
export async function addPlantComponentByIdApi(componentData, plantId) {
  const url = URLs.format(addPlantComponentByIdUrl, { plantId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, componentData);
}

// Update Plant Component
export async function updatePlantComponentByIdApi(componentData, componentId, plantId) {
  const url = URLs.format(updatePlantComponentByIdUrl, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url, {}, componentData);
}

// Upload Plant Component Image By Id Api
export async function uploadPlantComponentImageByIdApi(formData, plantId, componentId) {
  const url = URLs.format(plantComponentImageByIdUrl, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.post(url, {}, formData, ContentType.APP_FORM_DATA);
}

// Download Plant Component Image By Id Api
export async function downloadPlantComponentImageByIdApi(url) {
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.downloadPublicFile(url);
}

// Decommission Component Api
export async function decommissionComponentApi(plantId, componentId) {
  const url = URLs.format(decommissionComponentUrl, { plantId, componentId });
  const httpClient = HttpClientFactory.getInstance();

  return httpClient.put(url);
}
