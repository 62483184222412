import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { Divider, Modal } from "antd";

// Actions
import { AuditObservationAndRecommendationActions } from "../../../../redux-slice/audit/ObservationAndRecommendationSlice";

// Constants
import { DataPreLoadKeys } from "../../../../constants/GeneralConstants";

// Utils
import { getItem } from "../../../../app/LocalStorage";

// Components
import { Button } from "../../../../components/button/Button";
import DataTable from "../../../../components/antd/table/DataTable";

// css
import "./AuditObservationModal.scss";

const tableHeaders = [
  { title: "Code", dataIndex: "code" },
  {
    title: "Description",
    dataIndex: "description",
  },
];

function RecommendationsTable({ recommendationList = [], selectedRecommendationIds, setSelectedRecommendationIds }) {
  const rowSelection = {
    selectedRowKeys: selectedRecommendationIds,
    onChange: (newSelectedRowKeys) => {
      setSelectedRecommendationIds(newSelectedRowKeys);
    },
  };

  return (
    <div className="mt-3">
      <h5 className="sec-title mb-3">Recommendations</h5>

      <DataTable
        className="table-class"
        rows={recommendationList}
        columns={tableHeaders}
        applyTableBorder={true}
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
        }}
      />
    </div>
  );
}

function AuditObservationModalBodyNotes({ notes, setNotes }) {
  return (
    <div>
      <label className="form-label fw-semibold">Observation Notes</label>

      <textarea
        value={notes}
        onChange={({ target }) => setNotes(target.value)}
        className="d-block form-control shadow-none"
        cols="30"
        rows="5"
      />
    </div>
  );
}

function ModalFooter({ handleSubmit = () => {}, btnLoading = false }) {
  return (
    <div className="mt-4">
      <Button
        label="save"
        loading={btnLoading}
        disabled={btnLoading}
        color="success"
        className="mb-2"
        onClick={handleSubmit}
      />
    </div>
  );
}

function ModalTitle({ componentName, componentType }) {
  return (
    <>
      <div>{`${componentName} (${componentType})`}</div>
      <Divider className="modal-title-divider" />
    </>
  );
}

/**
 * Audit Observation Modal
 */
export default function AuditObservationModal({ selectedObservation, showModal = false, setShowModal = () => {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { auditId = "" } = params;

  // State
  const [notes, setNotes] = useState("");
  const [recommendationList, setRecommendationList] = useState([]);
  const [selectedRecommendationIds, setSelectedRecommendationIds] = useState([]);

  // Getting observation details
  const {
    notes: observationNotes = "",
    isObserved = false,
    observationId = "",
    observationCodeId = "",
    observationCode = "",
    description = "",
  } = selectedObservation || {};

  // Component Info Selector State
  const { component: componentInfo = {} } = useSelector((state) => state.auditComponent.auditComponentInfo);

  // Update Observation And Recommendation Selector State
  const loading = useSelector((state) => state.auditObsAndRec.updateObsAndRecLoading[observationId]);

  // Recommendation Codes Data
  const { recommendationCodesByComponent = {} } =
    JSON.parse(getItem(DataPreLoadKeys.RECOMMENDATION_CODES || "{}")) || {};

  // Component Info
  const {
    id: componentId = "",
    name: componentName = "",
    internalId: componentType = "",
    componentTypeId = "",
  } = componentInfo || {};

  // use Memo
  const recCodeList = useMemo(() => {
    return recommendationCodesByComponent[componentTypeId] || [];
  }, [componentTypeId]);

  // use Effect
  useEffect(() => {
    setNotes(observationNotes);
    const { selectedRecommendationIds, recommendationList } = initialiseRecommendationDetails();
    setSelectedRecommendationIds(selectedRecommendationIds);
    setRecommendationList(recommendationList);
  }, [showModal]);

  // Function construct recommendation code Map
  function initialiseRecommendationDetails() {
    const selectedRecommendationIds = [];
    const selectedRecommendations = selectedObservation.recommendations || [];

    const recommendationList = recCodeList.map((recommendation) => {
      const { id = "", code = "", description = "" } = recommendation || {};

      const isChecked = selectedRecommendations.some((recommendation) => recommendation.codeId === parseInt(id));

      if (isChecked) {
        selectedRecommendationIds.push(id);
      }

      // NOTE: we are adding one extra key i.e "key" for AntD requirement..
      return { id, code, description, key: id };
    });

    return { selectedRecommendationIds, recommendationList };
  }

  // Function save recommendations
  function saveRecommendations() {
    const observationData = {
      id: observationId,
      auditInfoId: auditId,
      elementStr: "COMPONENT",
      elementId: componentId,
      elementTypeId: componentTypeId,
      observationCodeId,
      isObserved,
      notes,
      recommendationCodeIds: selectedRecommendationIds,
    };

    // Dispatch
    dispatch(
      AuditObservationAndRecommendationActions.updateAuditObsAndRec({
        observationData,
        observationId,
      })
    );
  }

  function handleCancel() {
    setShowModal(false);
    setSelectedRecommendationIds([]);
  }

  return (
    <Modal
      // className="custom-modal"
      forceRender
      open={showModal}
      title={<ModalTitle componentName={componentName} componentType={componentType} />}
      width={1000}
      footer={() => <ModalFooter handleSubmit={saveRecommendations} btnLoading={loading} />}
      onCancel={handleCancel}
    >
      <div className="d-flex  mb-4 align-items-center justify-content-between">
        <div>
          <b>Observation Code :</b> {observationCode}
          <div className="mt-2">
            <b>Description :</b> {description}
          </div>
        </div>

        {/* Button */}
      </div>

      {/* Audit Observation Modal Body Notes */}
      <AuditObservationModalBodyNotes notes={notes} setNotes={setNotes} />

      <RecommendationsTable
        recommendationList={recommendationList}
        selectedRecommendationIds={selectedRecommendationIds}
        setSelectedRecommendationIds={setSelectedRecommendationIds}
      />
    </Modal>
  );
}
