// Components
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../breadcrumb/Breadcrumb";

// Page Components
function TitleSection({ body = "" }) {
  // if "body" is String then return :
  if (typeof body === "string") {
    return (
      <h2 title={body} className="title text-truncate">
        {body}
      </h2>
    );
  }

  // returning component itself
  return <div>{body}</div>;
}

/**
 * Page Header
 * @param {*} title : Title to be displayed
 * @param {*} actions : To display buttons
 * @param {*} shadow : Boolean value
 * @param {*} border : Boolean value
 * @param {*} breadcrumbList : Array for breadcrumbs
 * @param {*} className
 */
export default function PageHeader({
  title,
  actions,
  shadow = false,
  border = false,
  breadcrumbList = [],
  backButtonConfig = {},
  className = "",
}) {
  const navigate = useNavigate();
  // Props
  const shadowCls = shadow ? "shadow" : "";
  const borderCls = border ? "border" : "";

  const backButtonEnabled = Object.keys(backButtonConfig).length > 0;
  const { redirectUrl } = backButtonConfig;

  return (
    <div className={`page-header ${shadowCls} ${borderCls} ${className}`}>
      {/* Breadcrumb */}
      {breadcrumbList.length !== 0 && <Breadcrumb items={breadcrumbList} />}

      {/* Back button */}
      {backButtonEnabled && (
        <div className="cursor-pointer fs-5 mt-1 ps-3 pe-2" onClick={() => navigate(redirectUrl)}>
          <i className="fa fa-arrow-left" />
        </div>
      )}

      {/** Title */}
      <TitleSection body={title} />

      {/** Actions */}
      {actions && <div className="page-actions">{actions}</div>}
    </div>
  );
}
