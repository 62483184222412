import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.upsertUserSuccess = false;
  state.updateUserStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // User Info
  userInfo: {},
  userInfoLoading: false,
  userInfoError: "",

  // Add user
  addUserLoading: false,
  addUserError: "",

  // Update user
  updateUserLoading: false,
  updateUserError: "",

  upsertUserSuccess: false,

  // Assign role to user
  assignRoleToUserLoading: false,
  assignRoleToUserError: "",

  // Update user status
  updateUserStatusLoadingMap: {},
  updateUserStatusSuccessMap: {},
  updateUsertStatusError: "",
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    // User Info
    getUserInfo: (state) => {
      state.userInfoLoading = true;
    },

    getUserInfoSuccess: (state, action) => {
      state.userInfoLoading = false;
      state.userInfo = action.payload.userInfo;
    },

    getUserInfoFailure: (state, action) => {
      state.userInfoLoading = false;
      state.userInfoError = action.payload.error;
    },

    // Add user
    addUser: (state) => {
      state.upsertUserSuccess = false;
      state.addUserLoading = true;
    },

    addUserSuccess: (state) => {
      state.upsertUserSuccess = true;
      state.addUserLoading = false;
    },

    addUserFailure: (state, action) => {
      state.addUserLoading = false;
      state.addUserError = action.payload.error;
    },

    // Assign role to user
    assignUserRole: (state) => {
      state.assignRoleToUserLoading = true;
    },

    assignUserRoleSuccess: (state) => {
      state.assignRoleToUserLoading = false;
    },

    assignUserRoleFailure: (state, action) => {
      state.assignRoleToUserLoading = false;
      state.assignRoleToUserError = action.payload.error;
    },

    // Update user
    updateUser: (state) => {
      state.upsertUserSuccess = false;
      state.updateUserLoading = true;
    },

    updateUserSuccess: (state) => {
      state.upsertUserSuccess = true;
      state.updateUserLoading = false;
    },

    updateUserFailure: (state, action) => {
      state.updateUserLoading = false;
      state.updateUserError = action.payload.error;
    },

    // Update User status
    updateUserStatus: (state, action) => {
      const { userId } = action.payload;
      state.updateUserStatusSuccessMap[userId] = false;
      state.updateUserStatusLoadingMap[userId] = true;
      state.updateUsertStatusError = "";
    },

    updateUserStatusSuccess: (state, action) => {
      const { userId } = action.payload;
      state.updateUserStatusSuccessMap[userId] = true;
      delete state.updateUserStatusLoadingMap[userId];
    },

    updateUserStatusFailure: (state, action) => {
      const { userId, error } = action.payload;
      delete state.updateUserStatusLoadingMap[userId];
      state.updateUsertStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const UserReducer = userSlice.reducer;

// Actions
export const UserActions = userSlice.actions;
