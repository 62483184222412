import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

// Actions
import { OrganizationActions } from "../../../redux-slice/organization/OrganizationSlice";
import { PlantActions } from "../../../redux-slice/plant/PlantSlice";

// Utils
import { bcForPlantViewPage } from "../../../utils/BreadcrumbUtils";

// Components
import Loader from "../../../components/loader/Loader";
import Form from "../../../components/form/Form";
import PageHeader from "../../../components/page-header/PageHeader";

// Form Schema
import { PlantFormSchema, PlantFormValidationSchema } from "../../../form-schema/PlantFormSchema";

// Page Components
function PlantViewPageCard({ formSubmit, data, plantInfoLoading, updatePlantLoading }) {
  const updatePlantSuccess = useSelector((state) => state.plant.updatePlantSuccess);

  return (
    <div className="card shadow p-3 mb-5 mt-4 rounded col-12">
      {/* Loader */}
      {plantInfoLoading && <Loader containerClassName="m-5" />}

      {!plantInfoLoading && (
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <span className="fw-bold">Plant Information</span>
          </div>

          {/*Form */}
          <Form
            loading={updatePlantLoading}
            formInputItems={PlantFormSchema}
            data={data}
            formSubmit={formSubmit}
            formValidationSchema={PlantFormValidationSchema}
            changeToReadMode={updatePlantSuccess}
          />
        </div>
      )}
    </div>
  );
}

/**
 * Plant View Page
 */
export default function PlantViewPage() {
  // Dispatch
  const dispatch = useDispatch();

  //Navigate
  const navigate = useNavigate();

  // Params
  const params = useParams();
  const { orgId, plantId } = params;

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Organization Info Selector State
  const plantInfo = useSelector((state) => state.plant.plantInfo);
  const plantInfoLoading = useSelector((state) => state.plant.plantInfoLoading);

  // Update Plant Loading
  const updatePlantLoading = useSelector((state) => state.plant.updatePlantLoading);
  const updatePlantSuccess = useSelector((state) => state.plant.updatePlantSuccess);

  // Orgnization Info Selector State
  const organizationInfo = useSelector((state) => state.organization.organizationInfo);

  const { name = "" } = plantInfo;
  const { name: orgName = "" } = organizationInfo;

  // useEffect
  useEffect(() => {
    dispatch(PlantActions.getPlantInfo({ plantId }));
    dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
  }, [dispatch, plantId, orgId]);

  useEffect(() => {
    setHeaderComponent(<PageHeader breadcrumbList={bcForPlantViewPage(orgId, orgName, name)} className="mt-2" />);
  }, [orgId, orgName, name]);

  useEffect(() => {
    if (updatePlantSuccess) {
      toast.success("Plant Updated Successfully");
    }
  }, [updatePlantSuccess]);

  // Form onSubmit
  function formSubmit(plantData) {
    // Adding organization id to plant data
    plantData.orgId = orgId;

    // Dispatch
    dispatch(PlantActions.updatePlant({ plantData, plantId, navigate }));
  }

  return (
    <div className="page-content">
      {/* Plant View Page Card */}
      <PlantViewPageCard
        updatePlantLoading={updatePlantLoading}
        plantInfoLoading={plantInfoLoading}
        data={plantInfo}
        formSubmit={formSubmit}
      />
    </div>
  );
}
