import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { AuditReportsActions } from "../../../../redux-slice/audit/ReportsSlice";
import { AuditComponentActions } from "../../../../redux-slice/audit/ComponentSlice";

// Constants
import { ReportHTMLHead, ReportPDFPrintStyle, ReportPDFStyle } from "./ReportConstants";
import { AuditReportTypes } from "../../../../constants/GeneralConstants";

// Urls
import { RedirectTo } from "../../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";

// Page Components
import AuditReportPDFDownloadBtn from "../../../../page-components/audit-report/AuditReportPDFDownloadBtn";

// Section
import Report from "./Report";

// Helper Functions
function getSavedReport() {
  return document.querySelector("#report") ?? {};
}

function cloneHTMLReport(report) {
  const div = document.createElement("div");
  div.setAttribute("class", "d-none");
  div.setAttribute("id", "cloned-report");
  div.innerHTML = report?.innerHTML || "";
  document.body.append(div);
}

function removeAllEditorInstances() {
  const editor = document.querySelectorAll("#cloned-report .report-editor") ?? [];
  editor.forEach((eachDiv) => eachDiv.remove());
}

function getUpdatedReport() {
  const clonedReport = document.querySelector("#cloned-report") ?? {};
  const clonedReportInnerHTMl = clonedReport?.innerHTML || <></>;

  const updatedContent = `
      <!DOCTYPE html>
        <html lang="en">
        ${ReportHTMLHead}

        ${ReportPDFPrintStyle}

        ${ReportPDFStyle}

        <body>
          ${clonedReportInnerHTMl}
        </body>
        </html>
    `;

  return { updatedContent, clonedReport };
}

function generateReportDataForAPI(reportHTML, updatedContent) {
  return { ...reportHTML, content: updatedContent };
}

// helper functions

function dispatchToAPI(dispatch, auditId, reportId, reportData) {
  dispatch(AuditReportsActions.updateReport({ auditId, reportId, reportData }));
}

function removeClonedReportFromDOM(clonedReport) {
  clonedReport.remove();
}

// Page Components
function ReportSaveBtn() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "", reportId = "" } = useParams();

  // Report HTML Selector State
  const reportHTML = useSelector((state) => state.auditReports.reportHTML);
  const { reportType = "" } = reportHTML || {};

  // Update Report Selector State
  const updateReportLoading = useSelector((state) => state.auditReports.updateReportLoading);
  const updateReportSuccess = useSelector((state) => state.auditReports.updateReportSuccess);

  // Icon Classname
  const iconClassName = updateReportLoading ? "fa-circle-notch fa-spin" : "fa-floppy-disk";

  useEffect(() => {
    if (updateReportSuccess) {
      toast.success("Report Updated Successfully");
    }
  }, [dispatch, updateReportSuccess]);

  // Save Report
  function saveReport() {
    // Getting Report
    const report = getSavedReport();

    // If there is no report element
    if (!report) {
      return;
    }

    // Creating a div element and appending to body(hidden
    cloneHTMLReport(report);

    // From the cloned report, looping over all the editor and removing it
    removeAllEditorInstances();

    // After removing the editor, the final report is sent
    const { updatedContent, clonedReport } = getUpdatedReport();

    const reportData = generateReportDataForAPI(reportHTML, updatedContent);

    // Dispatch
    dispatchToAPI(dispatch, auditId, reportId, reportData);

    // Removing the cloned report from the body
    removeClonedReportFromDOM(clonedReport);
  }

  return (
    <Button
      label="Save"
      color="success"
      className="rounded rounded-3"
      size="lg"
      disabled={updateReportLoading}
      onClick={saveReport}
    >
      <i className={`fas ${iconClassName}`} />
    </Button>
  );
}

/**
 * Audit Report Editor Page
 */
export default function AuditReportEditorPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "", reportId = "" } = useParams();

  // Report HTML Selector State
  const reportHTMLLoading = useSelector((state) => state.auditReports.reportHTMLLoading);

  // Report Data Selector State
  const reportDataLoading = useSelector((state) => state.auditReports.reportDataLoading);

  // use Effect
  useEffect(() => {
    dispatch(AuditReportsActions.getReportData({ auditId, reportType: AuditReportTypes.PERF_ANALYSIS }));
    dispatch(AuditReportsActions.getReportHTML({ auditId, reportId }));
    dispatch(AuditComponentActions.getComponentsDetail({ auditId }));
  }, [dispatch, auditId, reportId]);

  useEffect(() => {
    return () => dispatch(AuditReportsActions.resetAllApiSuccessState({}));
  }, [dispatch]);

  // Loading
  if (reportHTMLLoading || reportDataLoading) {
    return <Loader containerClassName="mt-5" />;
  }

  return (
    <div className="page-content">
      <div className="fixed-bottom mb-5 me-5 text-end">
        {/* Report Save Btn */}
        <ReportSaveBtn />

        {/* Audit Report PDF Download Btn */}
        <AuditReportPDFDownloadBtn
          reportId={reportId}
          auditId={auditId}
          btnSize="lg"
          btnClassName="rounded rounded-3 ms-2"
        />
      </div>

      <div className="row">
        <div className="col-3"></div>

        <div className="col-6">
          <h3 className="my-3">Preview</h3>

          {/* Report */}
          <Report />
        </div>
      </div>
    </div>
  );
}
