import { all, put, takeEvery } from "redux-saga/effects";

// Actions
import { ProductListActions } from "../../redux-slice/product/ProductListSlice";
import { ProductActions } from "../../redux-slice/product/ProductSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { updateProductStatusByIdApi } from "../../api/product/ProductAPI";
import { getProductListApi } from "../../api/product/ProductListAPI";

// Update Product Status By Id
function* updateProductStatusById(action) {
  try {
    const { productId, status, pageNumber, pageSize } = action.payload;

    yield updateProductStatusByIdApi(productId, status);

    const { items, pagination } = yield getProductListApi(pageNumber, pageSize);

    yield put(ProductListActions.getProductListSuccess({ productList: items, productListPagination: pagination }));

    yield put(ProductActions.updateProductStatusSuccess({ productId }));
  } catch (error) {
    const { productId } = action.payload;

    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(ProductActions.updateProductStatusFailure({ productId, error }));
  }
}

export default function* root() {
  yield all([takeEvery(ProductActions.updateProductStatus.type, updateProductStatusById)]);
}
