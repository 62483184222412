import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { AuthActions } from "../../redux-slice/auth/AuthSlice";

// Utils
import { LocalStorageKeys, getItem } from "../../app/LocalStorage";

// Components
import Label from "../../components/label/Label";

// Sections
import UpdateUserPasswordForm from "../../page-components/user/UpdateUserPasswordForm";

// Page Component
function ProfileCard({ data = {} }) {
  // User Information
  const { firstName = "", lastName = "", phoneNo = "", emailId = "", designation = "" } = data || {};

  return (
    <>
      <h2>Profile</h2>

      <div className="row mt-3">
        <div className="col-6">
          <Label title="Name" className="fw-bold" />
          <div>
            {firstName} {lastName}
          </div>
        </div>
        <div className="col-6">
          <Label title="Designation" className="fw-bold" />
          <div>{designation}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-6 mt-4">
          <Label title="Email" className="fw-bold" />
          <div>{emailId}</div>
        </div>
        <div className="col-6 mt-4">
          <Label title="Phone Number" className="fw-bold" />
          <div>{phoneNo}</div>
        </div>
      </div>
    </>
  );
}

/**
 * Profile Page
 */
export default function ProfilePage() {
  const dispatch = useDispatch();

  // Selector State
  const resetPasswordLoading = useSelector((state) => state.auth.resetPasswordLoading);
  const resetPasswordSuccess = useSelector((state) => state.auth.resetPasswordSuccess);

  // Getting user information
  const authUserInfo = JSON.parse(getItem(LocalStorageKeys.AUTH_USER) || "{}") || {};

  useEffect(() => {
    if (resetPasswordSuccess) {
      toast.success("Reset Password Success");
    }
  }, [resetPasswordSuccess]);

  useEffect(() => {
    return () => dispatch(AuthActions.resetAllApiSuccessState());
  }, [dispatch]);

  return (
    <div className="container-fluid mt-4">
      <div className="row page-content gx-4">
        <div className="col-2"></div>
        <div className="col-8 card p-4">
          <div className="card-body p-0">
            <ProfileCard data={authUserInfo} />
          </div>
        </div>

        <div className="col-4 d-none">
          <div className="card p-4">
            <h2>Reset Password</h2>

            <UpdateUserPasswordForm
              userInfo={authUserInfo}
              loading={resetPasswordLoading}
              dispatchFn={AuthActions.resetPassword}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
