import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { toast } from "react-toastify";

// Constants
import { FILE_CONTENT_TYPES } from "../../constants/GeneralConstants";

// Components
import { Button } from "../../components/button/Button";
import Label from "../../components/label/Label";
import SelectInput from "../../components/select-input/SelectInput";
import Textarea from "../../components/textarea/Textarea";
import Input from "../../components/input/Input";

// Page Constants
const DEFAULT_INPUT_VALUES = {
  type: "",
  notes: "",
  file: "",
};

const DEFAULT_INPUT_ERRORS = {
  type: { isError: false, message: "Please select a type !!" },
  file: { isError: false, message: "Please select a file !!" },
};

const INPUT_TYPES = {
  type: "type",
  notes: "notes",
  file: "file",
};

function constructOptionsArray(optionObj = {}) {
  return Object.entries(optionObj).map(([key, value]) => ({
    id: key,
    value,
  }));
}

function getErrorStatus(values = {}, errors = {}) {
  const { id = "", file = {} } = values;
  const cloneDefaultErrors = structuredClone(DEFAULT_INPUT_ERRORS);

  updateErrorStatus();

  return cloneDefaultErrors;

  // Helper Functions
  function updateErrorStatus() {
    // Checking whether file is present or not
    const isFilePresent = checkFileStatus();

    Object.keys(errors).forEach((eachKey) => {
      // For the view modal, if the file is already present, there is no error.
      // In the create modal, it checks whether the file is present or not.
      if (eachKey === INPUT_TYPES.file) {
        cloneDefaultErrors[eachKey].isError = isFilePresent ? false : true;
        return;
      }

      cloneDefaultErrors[eachKey].isError = values[eachKey] ? false : true;
    });
  }

  function checkFileStatus() {
    const { name = "", type: fileType = "" } = file;
    return id || name || fileType;
  }
}

function checkErrorStatus(errorStatus = {}) {
  const isError = Object.values(errorStatus).some(({ isError }) => isError);
  return isError;
}

function constructFormData(values = {}) {
  const { id = "", notes = "", type = "", file = {} } = values;

  const formData = new FormData();
  formData.append(INPUT_TYPES.type, type);
  formData.append(INPUT_TYPES.notes, notes);

  if (!id) {
    formData.append(INPUT_TYPES.file, file);
  }
  return formData;
}

// Page Components
function ViewFileSection({ values = {} }) {
  const { url = "", contentType = "" } = values;

  const isImage = contentType === FILE_CONTENT_TYPES.jpeg || contentType === FILE_CONTENT_TYPES.png;

  if (isImage) {
    return <img src={url} alt="Image" className="img-fluid" />;
  }

  if (contentType === FILE_CONTENT_TYPES.pdf) {
    return <iframe className="w-100" height={600} src={url} />;
  }

  return <div>Content Type not available !!</div>;
}

function FileTypeAndNotesInput({ values = {}, error = {}, handleChange = () => {}, fileTypes = {} }) {
  const [options, setOptions] = useState([]);

  const { type = "", notes = "" } = values;

  const { isError = false, message = "" } = error;

  useEffect(() => {
    const optionsArray = constructOptionsArray(fileTypes);
    setOptions(optionsArray);
  }, [fileTypes]);

  return (
    <>
      {/* Select File Type Input */}
      <Label title="Type" isMandatory={true} />
      <SelectInput
        value={type}
        options={options}
        placeholder="Select file type"
        onChange={(e) => handleChange(e, INPUT_TYPES.type)}
        error={isError && message}
      />

      {/* Notes Textarea */}
      <Label title="Notes" className="mt-3" />
      <Textarea value={notes} onChange={(e) => handleChange(e, INPUT_TYPES.notes)} />
    </>
  );
}

function FileSelectInput({ selectedFileId = "", error = {}, handleChange = () => {}, fileInputRef }) {
  const { isError = false, message = "" } = error;

  if (selectedFileId) {
    return null;
  }

  return (
    <>
      <Label title="Files" isMandatory={true} className="mt-3" />
      <Input
        inputRef={fileInputRef}
        type="file"
        onChange={(e) => handleChange(e, INPUT_TYPES.file)}
        error={isError && message}
      />
    </>
  );
}
/**
 * File Upload Modal
 */
export default function FileUploadModal({
  selectedFile = {},
  dispatchActions = {},
  reducerKey = "",
  fileTypes = {},
  showModal = false,
  setShowModal = () => {},
}) {
  const [values, setValues] = useState(DEFAULT_INPUT_VALUES);
  const [errors, setErrors] = useState(DEFAULT_INPUT_ERRORS);

  const fileInputRef = useRef(null);

  const createFileLoading = useSelector((state) => state[reducerKey].createFileLoading);
  const updateFileLoading = useSelector((state) => state[reducerKey].updateFileLoading);
  const upsertFileSuccess = useSelector((state) => state[reducerKey].upsertFileSuccess);

  const { id: selectedFileId = "" } = selectedFile;

  const loadingStatus = createFileLoading || updateFileLoading || false;

  const modalSize = selectedFileId ? 1000 : 600;

  const { uploadFileFn = () => {} } = dispatchActions;

  useEffect(() => {
    if (showModal) {
      setValues(selectedFile);
    } else {
      resetFormModal();
    }
  }, [showModal, selectedFile]);

  useEffect(() => {
    if (upsertFileSuccess && !selectedFileId) {
      toast.success("File created successfully");
      setShowModal(false);
    }
  }, [upsertFileSuccess, selectedFileId]);

  useEffect(() => {
    if (upsertFileSuccess && selectedFileId) {
      toast.success("File updated successfully");
      setShowModal(false);
    }
  }, [upsertFileSuccess, selectedFileId]);

  function handleChange({ target }, key) {
    if (key === INPUT_TYPES.file) {
      setValues((prev) => ({ ...prev, file: target.files[0] }));
      return;
    }

    setValues((prev) => ({ ...prev, [key]: target.value }));
  }

  function submitAction() {
    const errorStatus = getErrorStatus(values, errors);
    setErrors(errorStatus);

    const isErrorPresent = checkErrorStatus(errorStatus);

    if (isErrorPresent) {
      return;
    }

    const formData = constructFormData(values);

    uploadFileFn(formData, selectedFileId);
  }

  function resetFormModal() {
    setErrors(DEFAULT_INPUT_ERRORS);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  }

  function closeModal() {
    setShowModal(false);
  }

  return (
    <Modal title="Upload File" width={modalSize} open={showModal} onCancel={closeModal} footer={<></>}>
      <div className="page-content">
        <div className="row">
          <div className="col">
            <FileTypeAndNotesInput
              values={values}
              error={errors[INPUT_TYPES.type]}
              handleChange={handleChange}
              fileTypes={fileTypes}
            />

            <FileSelectInput
              selectedFileId={selectedFileId}
              error={errors[INPUT_TYPES.file]}
              handleChange={handleChange}
              fileInputRef={fileInputRef}
            />

            <Button
              label="Submit"
              color="success"
              className="mt-3 float-end mb-3"
              onClick={submitAction}
              loading={loadingStatus}
              disabled={loadingStatus}
            />
          </div>

          {selectedFileId && (
            <div className="col-6">
              <ViewFileSection values={values} />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
