import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "antd";

// Hooks
import { useUpdateQueryParams } from "../../../../hooks/UpdateQueryParams";

// Actions
import { PlantThermalBillActions } from "../../../../redux-slice/plant/ThermalBillSlice";
import { MaterialListActions } from "../../../../redux-slice/material/MaterialListSlice";

// Constants
import { QueryParamsKeys } from "../../../../constants/GeneralConstants";

// Components
import { Button } from "../../../../components/button/Button";
import Form, { FormTypes } from "../../../../components/form/Form";

// Section
import PlantThermalBillListTable from "./PlantThermalBillListTable";

// Form Schema
import { ThermalBillFormSchema, ThermalBillFormValidationSchema } from "../../../../form-schema/ThermalBillFormSchema";

// Page Constants
const MATERIAL_ID_KEY = "materialId";
const UNITS_CONSUMEND_KEY = "unitsConsumed";
const MODAL_SIZE = 1000;

// Page Component
function PlantThermalBillsPageHeader({ setShowCreateThermalBillModal = () => {} }) {
  return (
    <div className="d-flex align-items-center justify-content-between py-3">
      <div className="col-3">
        <h4>Monthly Thermal Bills</h4>
      </div>

      {/* Button */}
      <Button label="Add Thermal Bill" color="dark" onClick={() => setShowCreateThermalBillModal(true)}>
        <i className="fa-solid fa-plus me-2"></i>
      </Button>
    </div>
  );
}

/**
 * Plant Thermal Bills Tab Conent
 */
export default function PlantThermalBillsTabContent() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const params = useParams();
  const { plantId = "" } = params;

  // Search Params
  const [searchParams] = useUpdateQueryParams();

  // State
  const [selectedThermalBill, setSelectedThermalBill] = useState({});
  const [showViewThermalBillModal, setShowViewThermalBillModal] = useState(false);
  const [showCreateThermalBillModal, setShowCreateThermalBillModal] = useState(false);

  const { id = "" } = selectedThermalBill;

  // Material List Selector State
  const materialList = useSelector((state) => state.materialList.materialList);
  const materialListLoading = useSelector((state) => state.materialList.materialListLoading);

  // Upsert Thermal Bill Selector State
  const upsertPlantThermalBillLoading = useSelector((state) => state.plantThermalBill.upsertPlantThermalBillLoading);
  const upsertPlantThermalBillSucess = useSelector((state) => state.plantThermalBill.upsertPlantThermalBillSucess);

  // From Url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // use Effect
  useEffect(() => {
    dispatch(PlantThermalBillActions.getPlantThermalBillList({ plantId, pageNumber, pageSize, searchText }));
  }, [dispatch, plantId, pageNumber, pageSize, searchText]);

  useEffect(() => {
    dispatch(MaterialListActions.getMaterialList({ type: "FUEL" }));
  }, [dispatch]);

  useEffect(() => {
    if (!materialListLoading) {
      updateThermalBillFormSchema();
    }
  }, [materialListLoading]);

  useEffect(() => {
    return () => dispatch(PlantThermalBillActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    if (upsertPlantThermalBillSucess && !id) {
      setShowCreateThermalBillModal(false);
      toast.success("Thermal Bill Added Successfully");
    }
  }, [dispatch, upsertPlantThermalBillSucess]);

  useEffect(() => {
    if (upsertPlantThermalBillSucess && id) {
      setShowViewThermalBillModal(false);
      toast.success("Thermal Bill Updated Successfully");
    }
  }, [dispatch, upsertPlantThermalBillSucess]);

  // Update Thermal Bill Form Schema
  function updateThermalBillFormSchema(unitSymbol = "") {
    ThermalBillFormSchema[0]?.inputItems.forEach((input) => {
      // Adding options only to material input
      if (input.key === MATERIAL_ID_KEY) {
        input.options = materialList.map(({ id = "", name: value = "" }) => ({ id, value }));
        return;
      }

      // Adding options only to material input
      if (input.key === UNITS_CONSUMEND_KEY && unitSymbol) {
        input.label = `Units Consumed (${unitSymbol})`;
        return;
      }

      return [];
    });
  }

  // Thermal Bill Form OnChange
  function thermalBillFormChange(e) {
    const { value = "", name = "" } = e?.target || {};

    if (name === MATERIAL_ID_KEY && value) {
      const { unitSymbol = "" } = materialList.find(({ id = "" }) => id === parseInt(value));

      updateThermalBillFormSchema(unitSymbol);
    }
  }

  // Function for adding thermal bills
  function createThermalBill(thermalBillData) {
    const formData = { ...thermalBillData, plantId };

    // Dispatch
    dispatch(PlantThermalBillActions.createPlantThermalBill({ formData, plantId }));
  }

  // Function for editing thermal bills
  function editThermalBill(thermalBillData) {
    const formData = { ...thermalBillData, plantId };

    // Dispatch
    dispatch(
      PlantThermalBillActions.updatePlantThermalBill({ formData, plantId, thermalBillId: selectedThermalBill.id })
    );
  }

  return (
    <div className="page-content">
      {/* Plant Thermal Bills Page Header */}
      <PlantThermalBillsPageHeader setShowCreateThermalBillModal={setShowCreateThermalBillModal} />

      <div className="mt-4 table-responsive">
        {/* Plant Thermal Bill List Table */}
        <PlantThermalBillListTable
          setSelectedThermalBill={setSelectedThermalBill}
          setShowViewThermalBillModal={setShowViewThermalBillModal}
        />
      </div>

      {/* Create Thermal Bill Modal */}
      <Modal
        title="Create Thermal Bill"
        open={showCreateThermalBillModal}
        width={MODAL_SIZE}
        destroyOnClose={true}
        footer={null}
        onCancel={() => setShowCreateThermalBillModal(false)}
      >
        <Form
          type={FormTypes.CREATE}
          formInputItems={ThermalBillFormSchema}
          formValidationSchema={ThermalBillFormValidationSchema}
          formSubmit={createThermalBill}
          loading={upsertPlantThermalBillLoading && !id}
          showCancelButton={false}
          onChange={thermalBillFormChange}
          showModal={showCreateThermalBillModal}
        />
      </Modal>

      {/* View Thermal Bill Modal */}
      <Modal
        title="View Thermal Bill"
        open={showViewThermalBillModal}
        width={MODAL_SIZE}
        destroyOnClose={true}
        footer={null}
        onCancel={() => setShowViewThermalBillModal(false)}
      >
        <Form
          data={selectedThermalBill}
          formInputItems={ThermalBillFormSchema}
          formValidationSchema={ThermalBillFormValidationSchema}
          formSubmit={editThermalBill}
          loading={upsertPlantThermalBillLoading && id}
          showModal={showViewThermalBillModal}
        />
      </Modal>
    </div>
  );
}
