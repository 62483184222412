import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { AuditReportsListActions } from "../../../../redux-slice/audit/ReportsListSlice";
import { AuditReportsActions } from "../../../../redux-slice/audit/ReportsSlice";

// Constants
import { AuditReportContentType, AuditReportTypes } from "../../../../constants/GeneralConstants";

// Utils
import URLs from "../../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";
import AuditReportPDFDownloadBtn from "../../../../page-components/audit-report/AuditReportPDFDownloadBtn";

// Page Constants
const CREATE_REPORT_BTN = {
  label: "Create",
  btnColor: "primary",
  iconClassName: "fa-plus",
};

const EDIT_REPORT_BTN = {
  label: "Edit",
  btnColor: "warning",
  iconClassName: "fa-pen",
};

const REPORTS = [
  {
    title: "Performance Analysis Report",
    reportType: AuditReportTypes.PERF_ANALYSIS,
    contentType: AuditReportContentType.TEXT_HTML,
  },
];

// Page Components
function TitleWithSubText({ title = "", subText = "" }) {
  return (
    <div>
      <span className="fw-medium">{title}</span>
      <div className="fw-light">{subText}</div>
    </div>
  );
}

function AuditReportListCard({ report = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Params
  const { auditId = "" } = useParams();

  const { title = "", contentType = "", reportType = "" } = report;

  // Report List Selector State
  const reportsList = useSelector((state) => state.auditReportsList.reportsList) || [];

  // Create Report Selector State
  const { id = "" } = useSelector((state) => state.auditReports.createdReportInfo);
  const createReportLoading = useSelector((state) => state.auditReports.createReportLoading[reportType]);
  const createReportSuccess = useSelector((state) => state.auditReports.createReportSuccess[reportType]);

  // Report Information

  // Finding whether report is already generated or not
  const { id: reportId = "" } =
    reportsList.find((generatedReport = {}) => generatedReport?.reportType || "" === reportType) || {};

  // Btn Information
  const { label = "", btnColor = "", iconClassName = "" } = reportId ? EDIT_REPORT_BTN : CREATE_REPORT_BTN;

  useEffect(() => {
    if (createReportSuccess) {
      toast.success("Report Created Successfully");
      dispatch(AuditReportsActions.resetAllApiSuccessState({ reportType }));

      const url = URLs.format(RedirectTo.AuditReportEditorPageUrl, { auditId, reportId: id });
      navigate(url);
    }
  }, [dispatch, createReportSuccess, id]);

  // Function for navigating to report edit page
  async function navigateToReportEditPage() {
    // If report is already present
    if (reportId) {
      const url = URLs.format(RedirectTo.AuditReportEditorPageUrl, { auditId, reportId });
      navigate(url);
      return;
    }

    // Report Data Object
    const reportData = {
      auditInfoId: auditId,
      reportType,
      contentType,
      content: "",
    };

    // Dispatch
    dispatch(AuditReportsActions.createReport({ auditId, reportData }));
  }

  return (
    <div className="card shadow-sm">
      <div className="card-body d-flex justify-content-between align-items-center">
        <i className="fas fa-file fa-xl" />

        <div className="fw-semibold">{title}</div>

        {/* TODO : Change when backend is ready */}
        {/* <TitleWithSubText title="Created on" subText="Jun 17, 2024 2:30 PM" /> */}

        {/* TODO : Change when backend is ready */}
        {/* <TitleWithSubText title="Updated on" subText="Jun 17, 2024 2:30 PM" /> */}

        <div>
          {/* Create or Edit Report Btn */}
          <Button
            label={label}
            size="sm"
            color={btnColor}
            loading={createReportLoading}
            disabled={createReportLoading}
            onClick={navigateToReportEditPage}
            className="me-3"
          >
            <i className={`fas ${iconClassName}`} />
          </Button>

          {/* Download PDF Btn */}
          {reportId && <AuditReportPDFDownloadBtn reportId={reportId} auditId={auditId} />}
        </div>
      </div>
    </div>
  );
}

/**
 * Audit Reports Page
 */
export default function AuditReportsListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Params
  const { auditId = "" } = useParams();

  // Selector State
  const reportsListLoading = useSelector((state) => state.auditReportsList.reportsListLoading);

  // use Effect
  useEffect(() => {
    dispatch(AuditReportsListActions.getReportsList({ auditId }));
  }, [dispatch, auditId]);

  // Loading
  if (reportsListLoading) {
    return <Loader containerClassName="mt-5" />;
  }

  return (
    <div className="page-content">
      <h3 className="mb-4">Reports</h3>

      {/* Audit Report List Card */}
      {REPORTS.map((report, idx) => {
        return <AuditReportListCard key={idx} report={report} />;
      })}
    </div>
  );
}
