import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { ComponentTypeActions } from "../../../redux-slice/component-type/ComponentTypeSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Utils
import { formatText } from "../../../utils/StringUtils";
import tableSerialNumber from "../../../utils/TableUtils";

// Components
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableLoader from "../../../components/table/TableLoader";
import ToggleButton from "../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Id",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Data Type",
    },
  },
  {
    title: {
      displayName: "Allowed Values",
    },
  },
  {
    title: {
      displayName: "Unit",
    },
  },
  {
    title: {
      displayName: "Decimals",
    },
  },
  {
    title: {
      displayName: "Is Mandatory",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function ParameterListFormulaCell({ formulaStr }) {
  // If there is no formula present
  if (!formulaStr) {
    return null;
  }

  return <td>{formulaStr}</td>;
}

function ParameterListActionsCell({ parameterId = "", isActive = false }) {
  // Params
  const { componentTypeId = "" } = useParams();

  // Dispatch
  const dispatch = useDispatch();

  // Query Params
  const [searchParams] = useUpdateQueryParams();

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);
  const searchText = searchParams.get(QueryParamsKeys.searchText);
  const tabId = searchParams.get(QueryParamsKeys.tabId) || "PROPERTY";

  const status = !isActive;

  // Selector State
  const updateParameterStatusLoading = useSelector(
    (state) => state.componentType.updateParameterStatusLoadingMap[parameterId]
  );
  const updateParameterStatusSuccess = useSelector(
    (state) => state.componentType.updateParameterStatusSuccessMap[parameterId]
  );

  function updateParameterTypeStatus() {
    dispatch(
      ComponentTypeActions.updateParameterTypeStatus({
        componentTypeId,
        parameterType: tabId,
        parameterId,
        status,
        pageNumber,
        pageSize,
        searchText,
      })
    );
  }

  useEffect(() => {
    if (updateParameterStatusSuccess) {
      toast.success("Status Changed Successfully");
    }
  }, [updateParameterStatusSuccess]);

  return (
    <td>
      <ToggleButton isActive={isActive} loading={updateParameterStatusLoading} onClick={updateParameterTypeStatus} />
    </td>
  );
}

function ParameterListAllowedValuesCell({ allowedValues = [] }) {
  if (allowedValues.length === 0) {
    return <td>--</td>;
  }

  // Constructing string of allowed values from array
  const allowedValuesString = allowedValues?.reduce((acc, value, index) => {
    // For first value comma is not required
    if (index === 0) {
      return `${value}`;
    }
    return `${acc} , ${value}`;
  }, "");

  return <td>{allowedValuesString}</td>;
}

function ParameterListTableRow({ serialNo, parameter }) {
  const {
    id,
    name,
    dataValueType,
    unitSymbol = "",
    allowedValues,
    noOfDecimals = "",
    isMandatory,
    isActive,
    formulaStr = "",
    code = "",
  } = parameter;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>
      <td>{code}</td>
      <td>{dataValueType}</td>

      {/* Parameter List Allowed Values Cell */}
      <ParameterListAllowedValuesCell allowedValues={allowedValues} />

      <td>{formatText(unitSymbol)}</td>

      <td>{noOfDecimals}</td>
      <td>{isMandatory ? "yes" : "no"}</td>

      {/* Parameter List Formula Cell */}
      <ParameterListFormulaCell formulaStr={formulaStr} />

      {/* Parameter List Table cell */}
      <ParameterListActionsCell parameterId={id} isActive={isActive} />
    </tr>
  );
}

function ParameterListTableBody({ pageNumber, pageSize, parameterList, parameterListLoading }) {
  // Loader
  if (parameterListLoading) {
    return <TableLoader colSpan={8} />;
  }

  // If no parameter present
  if (parameterList.length === 0) {
    return <TableDataNotFound message="No parameters present" colSpan={8} />;
  }

  return (
    <>
      {parameterList.map((parameter, index) => {
        // Serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        // Parameter List Table Row
        return <ParameterListTableRow serialNo={serialNo} key={parameter.id} parameter={parameter} />;
      })}
    </>
  );
}

/**
 * Parameter List Table
 */
export default function ParameterListTable({
  parameterList,
  parameterListLoading,
  parameterListPagination,
  pageNumber,
  parameterType,
}) {
  // Pagination
  const { totalCount, pageNo, pageSize } = parameterListPagination;

  // Adding formula column before action column
  const calculationTableHeaders = [
    ...tableHeaders.slice(0, tableHeaders.length - 1),
    {
      title: {
        displayName: "Formula",
      },
    },
    {
      title: {
        displayName: "Action",
      },
    },
  ];

  // For calculation tab formula column is added
  const updatedTableHeaders = parameterType === "CALCULATION" ? calculationTableHeaders : tableHeaders;

  return (
    <>
      <table className="table table-bordered">
        {/* Table Headers */}
        <TableHeaders tableHeaders={updatedTableHeaders} />

        <tbody>
          {/* Parameter List Table body */}
          <ParameterListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            parameterList={parameterList}
            parameterListLoading={parameterListLoading}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
