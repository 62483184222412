import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Actions
import { ComponentTypeActions } from "../../../redux-slice/component-type/ComponentTypeSlice";

//Utils
import tableSerialNumber from "../../../utils/TableUtils";

// Components
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TableHeaders from "../../../components/table/TableHeader";
import TablePagination from "../../../components/table/TablePagination";
import TableLoader from "../../../components/table/TableLoader";
import ToggleButton from "../../../components/antd/button/ToggleButton";

//Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "Id",
    },
  },
  {
    title: {
      displayName: "Code",
    },
  },
  {
    title: {
      displayName: "Description",
    },
  },
  {
    title: {
      displayName: "Energy Saving Formula",
    },
  },
  {
    title: {
      displayName: "Unit Cost Formula",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

//Page Components
function RecommendationCodeListActionsCell({ recCodeId, isActive }) {
  // Params
  const params = useParams();
  const { componentTypeId } = params;

  //Dispatch
  const dispatch = useDispatch();

  // Selector State
  const updateRecommendationCodeStatusLoading = useSelector(
    (state) => state.componentType.updateRecommendationCodeStatusLoadingMap[recCodeId]
  );
  const updateRecommendationCodeStatusSuccess = useSelector(
    (state) => state.componentType.updateRecommendationCodeStatusSuccessMap[recCodeId]
  );

  // Recommendation Code List Selector State
  const recommendationCodeListPagination = useSelector(
    (state) => state.componentTypeList.recommendationCodeListPagination
  );

  const { pageNo, pageSize } = recommendationCodeListPagination;

  // Changing Status
  const status = !isActive;

  function updateRecommendationCodeStatus() {
    dispatch(
      ComponentTypeActions.updateRecommendationCodeStatus({
        componentTypeId,
        recCodeId,
        status,
        pageNumber: pageNo,
        pageSize,
      })
    );
  }

  useEffect(() => {
    if (updateRecommendationCodeStatusSuccess) {
      toast.success("Recommendation Status Changed Successfully");
    }
  }, [updateRecommendationCodeStatusSuccess]);

  return (
    <td>
      <ToggleButton
        isActive={isActive}
        loading={updateRecommendationCodeStatusLoading}
        onClick={updateRecommendationCodeStatus}
      />
    </td>
  );
}

function RecommendationCodeListTableRow({ serialNo, recommendationCode }) {
  const {
    id,
    code,
    description,
    isActive,
    energySavingsFormulaStr = "--",
    unitCostFormulaStr = "--",
  } = recommendationCode;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{code}</td>
      <td>{description}</td>
      <td>{energySavingsFormulaStr}</td>
      <td>{unitCostFormulaStr}</td>

      {/* Recommendation Code List Table cell */}
      <RecommendationCodeListActionsCell recCodeId={id} isActive={isActive} />
    </tr>
  );
}

function RecommendationCodeListTableBody({ pageNumber, pageSize, recCodeList, recommendationCodeListLoading }) {
  //Loader
  if (recommendationCodeListLoading) {
    return <TableLoader colSpan={5} />;
  }

  //if no recommendation codes present
  if (recCodeList.length === 0) {
    return <TableDataNotFound message="No recommendation codes present" colSpan={5} />;
  }

  return (
    <>
      {recCodeList.map((recommendationCode, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        // Recommendation Code List Table Row
        return (
          <RecommendationCodeListTableRow
            serialNo={serialNo}
            key={recommendationCode.id}
            recommendationCode={recommendationCode}
          />
        );
      })}
    </>
  );
}

/**
 * Recommendation Code List Table
 */
export default function RecommendationCodeListTable({
  recCodeList,
  recommendationCodeListLoading,
  recommendationCodeListPagination,
  pageNumber,
}) {
  //Pagination
  const { totalCount, pageNo, pageSize } = recommendationCodeListPagination;

  return (
    <>
      <table className="table table-bordered">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />

        <tbody>
          {/* Recommendation Code List Table body */}
          <RecommendationCodeListTableBody
            pageNumber={pageNo}
            pageSize={pageSize}
            recCodeList={recCodeList}
            recommendationCodeListLoading={recommendationCodeListLoading}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
