import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantFileActions } from "../../redux-slice/plant/FileSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Utils
import { saveFile } from "../../utils/FileUtils";

// APIs
import {
  createPlantFileApi,
  deletePlantFileByIdApi,
  downloadPlantFileByIdApi,
  getPlantFileListApi,
  updatePlantFileByIdApi,
} from "../../api/plant/FileAPI";

// Get Plant File List
function* getPlantFileList(action) {
  try {
    const { plantId, pageNumber, pageSize, type = "" } = action.payload;

    const { items, pagination } = yield getPlantFileListApi(plantId, pageNumber, pageSize, type);

    // Dispatching Action
    yield put(
      PlantFileActions.getPlantFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.getPlantFileListFailure({ error }));
  }
}

// Create Plant File
function* createPlantFile(action) {
  const { plantId, pageNumber, pageSize, formData, type = "" } = action.payload;

  try {
    yield createPlantFileApi(plantId, formData);

    const { items, pagination } = yield getPlantFileListApi(plantId, pageNumber, pageSize, type);

    yield put(
      PlantFileActions.getPlantFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(PlantFileActions.createPlantFileSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.createPlantFileFailure({ error }));
  }
}

// Update Plant File By Id
function* updatePlantFileById(action) {
  const { plantId, fileId, pageNumber, pageSize, formData, type = "", modalId = "" } = action.payload;

  try {
    yield updatePlantFileByIdApi(plantId, fileId, formData);

    const { items, pagination } = yield getPlantFileListApi(plantId, pageNumber, pageSize, type);

    yield put(
      PlantFileActions.getPlantFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(PlantFileActions.updatePlantFileByIdSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.updatePlantFileByIdFailure({ error }));
  }
}

// Download Plant File By Id
function* downloadPlantFileById(action) {
  const { fileId, apiUrl = "", fileName = "" } = action.payload;

  try {
    const data = yield downloadPlantFileByIdApi(apiUrl);

    // Save File
    saveFile(fileName, data);

    yield put(PlantFileActions.downloadPlantFileByIdSuccess({ fileId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.downloadPlantFileByIdFailure({ fileId, error }));
  }
}

// Delete Plant File By Id
function* deletePlantFileById(action) {
  const { plantId, fileId, pageNumber, pageSize, type = "" } = action.payload;

  try {
    yield deletePlantFileByIdApi(plantId, fileId);

    const { items, pagination } = yield getPlantFileListApi(plantId, pageNumber, pageSize, type);

    yield put(
      PlantFileActions.getPlantFileListSuccess({
        fileList: items,
        fileListPagination: pagination,
      })
    );

    yield put(PlantFileActions.deletePlantFileByIdSuccess({ fileId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantFileActions.deletePlantFileByIdFailure({ fileId, error }));
  }
}

/**
 * Root Saga
 */
export default function* root() {
  yield all([
    takeLatest(PlantFileActions.getPlantFileList.type, getPlantFileList),
    takeLatest(PlantFileActions.createPlantFile.type, createPlantFile),
    takeLatest(PlantFileActions.updatePlantFileById.type, updatePlantFileById),
    takeLatest(PlantFileActions.downloadPlantFileById.type, downloadPlantFileById),
    takeLatest(PlantFileActions.deletePlantFileById.type, deletePlantFileById),
  ]);
}
