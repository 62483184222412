import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.auditCreateSuccess = false;
  state.updatePlantInchargeSuccess = false;
  state.updateAuditInchargeSuccess = false;
  state.startAuditPreAssessmentSuccess = false;
  state.startPreAuditSuccess = false;
  state.addAuditorSuccess = false;
  state.updateStartDateSuccess = false;
}

// Initial State
const initialState = {
  // Audit Create
  auditCreateLoading: false,
  auditCreateError: "",
  auditCreateSuccess: false,

  // Get Audit Information By Id
  auditInfo: {},
  auditInfoLoading: false,
  auditInfoError: "",

  // Get Audit Detailed Progress By Id
  auditDetailedProgress: {},
  auditDetailedProgressLoading: false,
  auditDetailedProgressError: "",

  // Update Plant Incharge By Id
  updatePlantInchargeLoading: false,
  updatePlantInchargeError: "",
  updatePlantInchargeSuccess: false,

  // Update Audit Incharge By Id
  updateAuditInchargeLoading: false,
  updateAuditInchargeError: "",
  updateAuditInchargeSuccess: false,

  // Start Audit Pre Assessment By Id
  startAuditPreAssessmentLoading: false,
  startAuditPreAssessmentError: "",
  startAuditPreAssessmentSuccess: false,

  // Start Pre Audit By Id
  startPreAuditLoading: false,
  startPreAuditError: "",
  startPreAuditSuccess: false,

  // Add Auditor By Id
  addAuditorLoading: false,
  addAuditorError: "",
  addAuditorSuccess: false,

  // Remove Auditor By Id
  removeAuditorLoading: {},
  removeAuditorError: "",

  // Update Start Date
  updateStartDateLoading: false,
  updateStartDateSuccess: false,
  updateStartDateError: {},
};

// Audit Slice
const auditSlice = createSlice({
  name: "AuditSlice",
  initialState,
  reducers: {
    // Audit Create
    createAudit: (state) => {
      state.auditCreateSuccess = false;
      state.auditCreateLoading = true;
    },

    createAuditSuccess: (state) => {
      state.auditCreateSuccess = true;
      state.auditCreateLoading = false;
    },

    createAuditFailure: (state, action) => {
      state.auditCreateLoading = false;
      state.auditCreateError = action.payload.error;
    },

    // Get Audit Information
    getAuditInfo: (state) => {
      state.auditInfoLoading = true;
    },

    getAuditInfoSuccess: (state, action) => {
      state.auditInfoLoading = false;
      state.auditInfo = action.payload.auditInfo;
    },

    getAuditInfoFailure: (state, action) => {
      state.auditInfoLoading = false;
      state.auditInfoError = action.payload.error;
    },

    // Get Audit Detailed Progress
    getAuditDetailedProgress: (state) => {
      state.auditDetailedProgressLoading = true;
    },

    getAuditDetailedProgressSuccess: (state, action) => {
      state.auditDetailedProgressLoading = false;
      state.auditDetailedProgress = action.payload.auditDetailedProgress;
    },

    getAuditDetailedProgressFailure: (state, action) => {
      state.auditDetailedProgressLoading = false;
      state.auditDetailedProgressError = action.payload.error;
    },

    // Update Plant Incharge
    updatePlantIncharge: (state) => {
      state.updatePlantInchargeSuccess = false;
      state.updatePlantInchargeLoading = true;
    },

    updatePlantInchargeSuccess: (state) => {
      state.updatePlantInchargeSuccess = true;
      state.updatePlantInchargeLoading = false;
    },

    updatePlantInchargeFailure: (state, action) => {
      state.updatePlantInchargeLoading = false;
      state.updatePlantInchargeError = action.payload.error;
    },

    // Update Audit Incharge
    updateAuditIncharge: (state) => {
      state.updateAuditInchargeSuccess = false;
      state.updateAuditInchargeLoading = true;
    },

    updateAuditInchargeSuccess: (state) => {
      state.updateAuditInchargeSuccess = true;
      state.updateAuditInchargeLoading = false;
    },

    updateAuditInchargeFailure: (state, action) => {
      state.updateAuditInchargeLoading = false;
      state.updateAuditInchargeError = action.payload.error;
    },

    // Start Audit Pre Assessment
    startAuditPreAssessment: (state) => {
      state.startAuditPreAssessmentSuccess = false;
      state.startAuditPreAssessmentLoading = true;
    },

    startAuditPreAssessmentSuccess: (state) => {
      state.startAuditPreAssessmentSuccess = true;
      state.startAuditPreAssessmentLoading = false;
    },

    startAuditPreAssessmentFailure: (state, action) => {
      state.startAuditPreAssessmentLoading = false;
      state.startAuditPreAssessmentError = action.payload.error;
    },

    // Start Pre Audit
    startPreAudit: (state) => {
      state.startPreAuditSuccess = false;
      state.startPreAuditLoading = true;
    },

    startPreAuditSuccess: (state) => {
      state.startPreAuditSuccess = true;
      state.startPreAuditLoading = false;
    },

    startPreAuditFailure: (state, action) => {
      state.startPreAuditLoading = false;
      state.startPreAuditError = action.payload.error;
    },

    // Add Auditor
    addAuditor: (state) => {
      state.addAuditorSuccess = false;
      state.addAuditorLoading = true;
    },

    addAuditorSuccess: (state) => {
      state.addAuditorSuccess = true;
      state.addAuditorLoading = false;
    },

    addAuditorFailure: (state, action) => {
      state.addAuditorLoading = false;
      state.addAuditorError = action.payload.error;
    },

    // Remove Auditor
    removeAuditor: (state, action) => {
      const { userId } = action.payload;
      state.removeAuditorLoading[userId] = true;
      state.removeAuditorError = "";
    },

    removeAuditorSuccess: (state, action) => {
      const { userId } = action.payload;
      delete state.removeAuditorLoading[userId];
    },

    removeAuditorFailure: (state, action) => {
      const { userId, error } = action.payload;
      delete state.removeAuditorLoading[userId];
      state.removeAuditorError = error;
    },

    updateStartDate: (state) => {
      state.updateStartDateSuccess = false;
      state.updateStartDateLoading = true;
    },

    updateStartDateSuccess: (state) => {
      state.updateStartDateLoading = false;
      state.updateStartDateSuccess = true;
    },

    updateStartDateFailure: (state, action) => {
      state.updateStartDateLoading = false;
      state.updateStartDateError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditReducer = auditSlice.reducer;

// Actions
export const AuditActions = auditSlice.actions;
