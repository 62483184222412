import { useState } from "react";
import { useNavigate } from "react-router-dom";

// Constants
import { SidebarMenus, constructSidebarMenuConfig } from "../../constants/LayoutConstants";

// Utils
import { getOrgInfo, getUserInfo } from "../../utils/AuthOrgUtil";
import { constructSideBarUrls } from "../../utils/LayoutUtils";

// hooks
import useSelectedMenu from "../../hooks/useSelectedMenu";

// Actions
import { AuthActions } from "../../redux-slice/auth/AuthSlice";

/**
 * {
 *   itemName: "Some text",
 *   itemIconCls: "far fa-icon",
 *   redirectUrl: "/link",
 *   label: "/link"
 * }
 */
function MenuItem({ menuItem, isSidebarCollapsed, selectedMenu, setSelectedMenu }) {
  // State
  const [showSubMenu, setShowSubMenu] = useState(false);

  // Data
  const { itemName, itemIconCls, id } = menuItem;

  // ClassName for selected menu
  const selectedMenuClassName = selectedMenu === id ? "selected-menu" : "";

  // ClassName for item name
  const itemNameClassName = `link-name ${selectedMenuClassName}`;

  return (
    <li className={`menu-items ${showSubMenu ? "show-sub-menu" : ""}`}>
      <div className="menu-link" onClick={() => setShowSubMenu((prev) => !prev)}>
        <div className={`menu-link`} onClick={() => setSelectedMenu(id)}>
          <i className={`menu-icons ${itemIconCls} ${selectedMenuClassName}`}></i>
          <span className={`${itemNameClassName} ${!isSidebarCollapsed && "d-none"}`}>{itemName}</span>
        </div>
      </div>

      <ul className={`sub-menu ${showSubMenu ? "d-block" : ""}`}>
        <li>
          <div
            className={`${itemNameClassName} ${isSidebarCollapsed && "d-none"} `}
            onClick={() => setSelectedMenu(id)}
          >
            {itemName}
          </div>
        </li>
      </ul>
    </li>
  );
}

/**
 * {
 *   title: "Some text",
 *   items: []
 * }
 */
export function Menu({ menu, index, isSidebarCollapsed, backButtonConfig }) {
  // Navigate
  const navigate = useNavigate();

  const { title = "", items = [] } = menu || {};

  const menuItemsConfig = constructSidebarMenuConfig(items);
  const { selectedKey, handleMenuClick } = useSelectedMenu(menuItemsConfig, AuthActions.logout);

  // Back button config
  const {
    link = "",
    menuName = "Back",
    containerClassName = "",
    iconClassName = "fa-solid fa-chevron-left fa-sm",
  } = backButtonConfig || {};

  // Back button is present only for first menu
  const isBackBtnPresent = link && index === 0;

  return (
    <>
      {/* Back Button */}
      {isBackBtnPresent && (
        <div className="cursor-pointer menu-title pe-auto text-white fs-5" onClick={() => navigate(link)}>
          <i className={`me-2 ${iconClassName}`}></i>
          <span className={isSidebarCollapsed ? "" : "d-none"}>{menuName}</span>
        </div>
      )}

      {/* Menu Title */}
      <li className="menu-title fs-5 text-white" key="t-menu">
        {isSidebarCollapsed ? title : "-"}
      </li>

      {/* Menu Items */}
      {items.map((menuItem, index) => {
        return (
          <MenuItem
            key={index}
            menuItem={menuItem}
            isSidebarCollapsed={isSidebarCollapsed}
            selectedMenu={selectedKey}
            setSelectedMenu={handleMenuClick}
          />
        );
      })}
    </>
  );
}

/**
 * Sidebar Menus
 * @param {*} sidebarMenuList : Array of menus
 * @param {*} backButtonConfig : Object
 * @param {*} showSideBar : Boolean Value
 */
export default function SidebarMenu({ sidebarMenuList = [], backButtonConfig = {}, showSideBar = false }) {
  const { id: orgId = "", type: authOrgType = "" } = getOrgInfo();
  const { id: userId = "" } = getUserInfo();

  // Menus
  const menuList = sidebarMenuList.length !== 0 ? sidebarMenuList : SidebarMenus[authOrgType] || [];

  // replace auditId param in all the sidebar urls..
  const paramMap = { orgId, userId };

  const updatedMenuList = constructSideBarUrls(menuList, paramMap);

  return (
    <ul className={`nav-links ${showSideBar ? "" : "overflow-hidden"}`}>
      {/* Itetrate over Menu group list */}
      {updatedMenuList.map((menuGroup, idx) => {
        return (
          <Menu
            key={idx}
            index={idx}
            menu={menuGroup}
            isSidebarCollapsed={showSideBar}
            backButtonConfig={backButtonConfig}
          />
        );
      })}
    </ul>
  );
}
