// URLs
import { RedirectTo, URL_ORG_LIST_AUDIT_PARTNERS, URL_ORG_LIST_INDUSTRIES } from "../urls/page-urls/RedirectURL";

// Sidebar Images
import LOGO_KISEM from "../assests/images/branding/KISEM_LOGO_1.png";
import LOGO_KISEM_TEXT from "../assests/images/branding/KISEM_LOGO_2.png";

/**
 * Sidebar Menu
 */

export function constructSidebarMenuConfig(sidebarMenuList) {
  const sidebarMenuConfig = {};

  for (const menuItem of sidebarMenuList) {
    const { id, label, redirectUrl = "" } = menuItem;
    sidebarMenuConfig[label] = {
      menuItemId: id,
      redirectUrl,
    };
  }

  return sidebarMenuConfig;
}

export const SidebarMenus = {
  PLATFORM: [
    {
      title: "Audit Home",
      items: [
        {
          id: "Audit_Home_1",
          label: RedirectTo.AuditListPageUrl,
          redirectUrl: RedirectTo.AuditListPageUrl,

          itemName: "Audit Dashboard",
          itemIconCls: "far fa-list-alt",
        },
        {
          id: "Audit_Home_2",
          label: URL_ORG_LIST_AUDIT_PARTNERS,
          redirectUrl: URL_ORG_LIST_AUDIT_PARTNERS,

          itemName: "Audit Firm",
          itemIconCls: "fas fa-user-tie",
        },
        {
          id: "Audit_Home_3",
          label: URL_ORG_LIST_INDUSTRIES,
          redirectUrl: URL_ORG_LIST_INDUSTRIES,

          itemName: "Industries",
          itemIconCls: "fas fa-warehouse",
        },
      ],
    },
    {
      title: "Tools",
      items: [
        {
          id: "Tools_1",
          label: RedirectTo.UnitConversionToolPageUrl,
          redirectUrl: RedirectTo.UnitConversionToolPageUrl,

          itemName: "Unit Conversions",
          itemIconCls: "fa fa-calculator",
        },
      ],
    },
    {
      title: "Definitions",
      items: [
        {
          id: "Definitions_1",
          label: RedirectTo.MeasurementTypeListPageUrl,
          redirectUrl: RedirectTo.MeasurementTypeListPageUrl,

          itemName: "Measurements",
          itemIconCls: "fas fa-ruler",
        },
        {
          id: "Definitions_2",
          label: RedirectTo.ComponentTypeListPageUrl,
          redirectUrl: RedirectTo.ComponentTypeListPageUrl,

          itemName: "Components Types",
          itemIconCls: "fas fa-project-diagram",
        },
      ],
    },
  ],

  AUDIT: [
    {
      title: "Audit Home",
      items: [
        {
          id: "Audit_Home_1",
          label: RedirectTo.AuditListPageUrl,
          redirectUrl: RedirectTo.AuditListPageUrl,

          itemName: "All Audits",
          itemIconCls: "far fa-list-alt",
        },
        {
          id: "Audit_Home_2",
          label: RedirectTo.AuditListByAuditorPageUrl,
          redirectUrl: RedirectTo.AuditListByAuditorPageUrl,

          itemName: "My Audits",
          itemIconCls: "fa fa-clipboard-list",
        },
      ],
    },
    {
      title: "Industry",
      items: [
        {
          id: "Industry_Home_1",
          label: URL_ORG_LIST_INDUSTRIES,
          redirectUrl: URL_ORG_LIST_INDUSTRIES,

          itemName: "Industries",
          itemIconCls: "fas fa-warehouse",
        },
      ],
    },
    {
      title: "Administration",
      items: [
        {
          id: "Administration_Home_1",
          label: RedirectTo.UserListPageUrl,
          redirectUrl: RedirectTo.UserListPageUrl,

          itemName: "Users",
          itemIconCls: "fas fa-users",
        },
      ],
    },
  ],

  INDUSTRY: [
    {
      title: "Audit Home",
      items: [
        {
          id: "Audit_Home_1",
          label: RedirectTo.AuditListPageUrl,
          redirectUrl: RedirectTo.AuditListPageUrl,

          itemName: "My Audits",
          itemIconCls: "far fa-list-alt",
        },
      ],
    },
    {
      title: "Administration",
      items: [
        {
          id: "Administration_Home_1",
          label: RedirectTo.PlantListPageUrl,
          redirectUrl: RedirectTo.PlantListPageUrl,

          itemName: "Plants",
          itemIconCls: "fas fa-industry",
        },
        {
          id: "Administration_Home_2",
          label: RedirectTo.UserListPageUrl,
          redirectUrl: RedirectTo.UserListPageUrl,

          itemName: "Users",
          itemIconCls: "fas fa-users",
        },
      ],
    },
  ],
};

export const AuditLayoutSideBar = {
  sideBarElements: [
    {
      title: "Audit View",
      items: [
        {
          id: "Audit_View_1",
          label: RedirectTo.AuditOverviewPageUrl,
          redirectUrl: RedirectTo.AuditOverviewPageUrl,

          itemName: "Overview",
          itemIconCls: "fas fa-tachometer-alt",
        },
        {
          id: "Audit_View_2",
          label: RedirectTo.AuditStatusPageUrl,
          redirectUrl: RedirectTo.AuditStatusPageUrl,

          itemName: "Status",
          itemIconCls: "fas fa-spinner",
        },
        {
          id: "Audit_View_3",
          label: RedirectTo.AuditWalkthroughRemarksPageUrl,
          redirectUrl: RedirectTo.AuditWalkthroughRemarksPageUrl,

          itemName: "WalkThrough Remarks",
          itemIconCls: "fas fa-clipboard",
        },
        {
          id: "Audit_View_4",
          label: RedirectTo.AuditMeasurementsPageUrl,
          redirectUrl: RedirectTo.AuditMeasurementsPageUrl,

          itemName: "Measurements & Results",
          itemIconCls: "fas fa-ruler",
        },
        {
          id: "Audit_View_5",
          label: RedirectTo.AuditObservationsPageUrl,
          redirectUrl: RedirectTo.AuditObservationsPageUrl,

          itemName: "Observations & Recommendations",
          itemIconCls: "fas fa-eye",
        },
        {
          id: "Audit_View_6",
          label: RedirectTo.AuditSavingsCalculationPageUrl,
          redirectUrl: RedirectTo.AuditSavingsCalculationPageUrl,

          itemName: "Energy Savings",
          itemIconCls: "fas fa-bolt",
        },
        {
          id: "Audit_View_7",
          label: RedirectTo.AuditDataPageUrl,
          redirectUrl: RedirectTo.AuditDataPageUrl,

          itemName: "Data",
          itemIconCls: "fas fa-file-excel",
        },
        {
          id: "Audit_View_8",
          label: RedirectTo.AuditReportsListPageUrl,
          redirectUrl: RedirectTo.AuditReportsListPageUrl,

          itemName: "Reports",
          itemIconCls: "fas fa-file-pdf",
        },
        {
          id: "Audit_View_9",
          label: RedirectTo.AuditFileListPageUrl,
          redirectUrl: RedirectTo.AuditFileListPageUrl,

          itemName: "Audit Files",
          itemIconCls: "fas fa-file-arrow-up",
        },
      ],
    },
  ],
  backButtonConfig: {
    link: RedirectTo.AuditListPageUrl,
  },
};

// Plant Layout Sidebar Menus
export const PlantLayoutSideBar = {
  sideBarElements: [
    {
      title: "Plant Setup",
      items: [
        {
          id: "Plant_Setup_1",
          label: RedirectTo.PlantOverviewPageUrl,
          redirectUrl: RedirectTo.PlantOverviewPageUrl,

          itemName: "Overview",
          itemIconCls: "fas fa-info",
        },
        {
          id: "Plant_Setup_2",
          label: RedirectTo.PlantComponentsPageUrl,
          redirectUrl: RedirectTo.PlantComponentsPageUrl,

          itemName: "Components",
          itemIconCls: "fas fa-project-diagram",
        },
        {
          id: "Plant_Setup_3",
          label: RedirectTo.PlantElectricityBillPageUrl,
          redirectUrl: RedirectTo.PlantElectricityBillPageUrl,

          itemName: "Electricity Bills",
          itemIconCls: "fas fa-file-invoice-dollar",
        },
        {
          id: "Plant_Setup_4",
          label: RedirectTo.PlantThermalBillPageUrl,
          redirectUrl: RedirectTo.PlantThermalBillPageUrl,

          itemName: "Thermal Bills",
          itemIconCls: "fas fa-file-invoice-dollar",
        },
        {
          id: "Plant_Setup_5",
          label: RedirectTo.PlantUserListPageUrl,
          redirectUrl: RedirectTo.PlantUserListPageUrl,

          itemName: "Users",
          itemIconCls: "fas fa-user",
        },
        {
          id: "Plant_Setup_6",
          label: RedirectTo.PlantFileListPageUrl,
          redirectUrl: RedirectTo.PlantFileListPageUrl,

          itemName: "Plant Files",
          itemIconCls: "fas fa-file-arrow-up",
        },
      ],
    },
    {
      title: "Audit",
      items: [
        {
          id: "Audit_1",
          label: RedirectTo.PlantAuditListPageUrl,
          redirectUrl: RedirectTo.PlantAuditListPageUrl,

          itemName: "Audits",
          itemIconCls: "fas fa-file-pen",
        },
      ],
    },
  ],
  backButtonConfig: {
    link: RedirectTo.PlantListPageUrl,
  },
};

// User Layout Sidebar Menus
export const UserLayoutSideBar = {
  sideBarElements: [
    {
      title: "User Settings",
      items: [
        {
          id: "Audit_1",
          label: RedirectTo.ProfilePageUrl,
          redirectUrl: RedirectTo.ProfilePageUrl,

          itemName: "Profile",
          itemIconCls: "fas fa-user",
        },

        {
          id: "Audit_2",
          label: "logout",
          redirectUrl: "logout",

          itemName: "Log out",
          itemIconCls: "fas fa-power-off",
        },
      ],
    },
  ],
  backButtonConfig: {
    link: RedirectTo.DashboardPageUrl,
  },
};

//Sidebar Header Constants
export const SIDEBAR_HEADER_CONSTANTS = {
  headerTitle: "Energy Audit Tool",

  logoHeight: 40,
  logoURL: LOGO_KISEM_TEXT,

  collapsedLogoHeight: 30,
  collapsedLogoURL: LOGO_KISEM,
};
