import { Switch } from "antd";

// CSS
import "./ToggleButton.scss";

export default function ToggleButton({
  isActive = true,
  disabled = false,
  loading = false,
  onClick = () => {},
  size = "small",
  containerClassName = "",
}) {
  return (
    <div className={containerClassName}>
      <Switch
        className="custom-switch"
        defaultChecked={isActive}
        disabled={disabled}
        loading={loading}
        onChange={onClick}
        size={size}
      />
    </div>
  );
}
