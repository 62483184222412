import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

// Actions
import { UserActions } from "../../../redux-slice/user/UserSlice";

// Constants
import { OrgType } from "../../../constants/OrgTypeConstants";
import { ModalKeys } from "../../../constants/ModalConstants";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";
import URLs from "../../../app/utils/URLs";
import { getItem } from "../../../app/LocalStorage";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TablePagination from "../../../components/table/TablePagination";
import ToggleButton from "../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "First Name",
    },
  },
  {
    title: {
      displayName: "Last Name",
    },
  },
  {
    title: {
      displayName: "Mail",
    },
  },
  {
    title: {
      displayName: "Contact Number",
    },
  },
  {
    title: {
      displayName: "Designation",
    },
  },
  {
    title: {
      displayName: "Status",
    },
  },
  {
    title: {
      displayName: "Actions",
    },
  },
];

function UserStatus({ user, pageSize, pageNumber }) {
  const dispatch = useDispatch();
  const { orgId = "" } = useParams();

  const { id, isActive } = user;
  const status = !isActive;
  const { userId } = JSON.parse(getItem("AUTH_USER"));

  const isToggleDisabled = userId === id ? true : false;

  const loading = useSelector((state) => state.user.updateUserStatusLoadingMap[id]) || false;
  const updateUserStatusSuccess = useSelector((state) => state.user.updateUserStatusSuccessMap[id]) || false;

  function enableDisableUser() {
    dispatch(UserActions.updateUserStatus({ orgId, userId: id, status, pageSize, pageNumber }));
  }

  useEffect(() => {
    if (updateUserStatusSuccess) {
      toast.success("User Status Updated Successfully");
    }
  }, [updateUserStatusSuccess]);

  return (
    <td>
      <ToggleButton
        containerClassName="mt-1"
        isActive={isActive}
        disabled={isToggleDisabled}
        onClick={enableDisableUser}
        size="small"
        loading={loading}
      />
    </td>
  );
}

// Page Components
function UserListTableActionCell({ user, setUserId }) {
  // Navigate
  const navigate = useNavigate();

  // Page Params
  const { orgId = "", plantId = "" } = useParams();

  const { id, isActive } = user;

  // For plant users, different redirect url
  // Because that comes under plant routes
  const url = plantId ? RedirectTo.PlantUserViewPageUrl : RedirectTo.UserViewPageUrl;

  // Navigate to user view page
  function navigateToUserViewPage() {
    const redirectUrl = URLs.format(url, { orgId, userId: id, plantId });
    navigate(redirectUrl);
  }

  // Modal Id
  const modalId = ModalKeys.assignRoleComponentModal;

  return (
    <td className="d-flex">
      <div className="btn-cont">
        <Button label="View" size="sm" className="me-2" onClick={navigateToUserViewPage} disabled={!isActive}>
          <i className="fas fa-eye"></i>
        </Button>

        {/* Assign Role Button */}
        <Button
          className="d-none"
          label="Assign Role"
          color="warning"
          size="sm"
          data-bs-toggle="modal"
          data-bs-target={`#${modalId}`}
          onClick={() => setUserId(id)}
          disabled={!isActive}
        />
      </div>
    </td>
  );
}

function UserListTableRow({ user, serialNo, pageSize, pageNumber, setUserId }) {
  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Organization Type
  const { orgType = "" } = orgInfo || {};

  const { firstName, lastName, emailId, phoneNo, designation, plantName = "" } = user;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{firstName}</td>
      <td>{lastName}</td>
      <td>{emailId}</td>
      <td>{phoneNo}</td>
      <td>{designation}</td>
      <UserStatus user={user} pageNumber={pageNumber} pageSize={pageSize} />

      {orgType === OrgType.INDUSTRY && <td>{plantName}</td>}

      {/* User List Table Action Cell */}
      <UserListTableActionCell user={user} setUserId={setUserId} />
    </tr>
  );
}

function UserListTableBody({ userListLoading, userList, pageSize, pageNumber, setUserId }) {
  // Loader
  if (userListLoading) {
    return <TableLoader colSpan={8} />;
  }

  // If there is no user list
  if (userList.length === 0) {
    return <TableDataNotFound message="No user present" colSpan={8} />;
  }

  // User list table row
  return (
    <>
      {userList.map((user, index) => {
        // Serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return (
          <UserListTableRow
            key={user.id}
            user={user}
            serialNo={serialNo}
            pageNumber={pageNumber}
            pageSize={pageSize}
            setUserId={setUserId}
          />
        );
      })}
    </>
  );
}

/**
 * User List Table
 */
export default function UserListTable({
  userList = [],
  userListLoading = false,
  userListPagination = {},
  pageNumber,
  setUserId,
}) {
  // Organization Info Selector State
  const orgInfo = useSelector((state) => state.organization.organizationInfo);

  // Pagination
  const { totalCount, pageSize, pageNo } = userListPagination;

  // Organization Type
  const { orgType = "" } = orgInfo || {};

  // Table headers
  const updatedTableHeaders = useMemo(() => {
    // Deep clone
    const clonedTableHeaders = structuredClone(tableHeaders);

    // If org type is industry, one more table header is added (Assigned plant)
    if (orgType === OrgType.INDUSTRY) {
      const assignedPlantHeader = {
        title: {
          displayName: "Assigned Plant",
        },
      };

      clonedTableHeaders.splice(tableHeaders.length - 1, 0, assignedPlantHeader);

      return clonedTableHeaders;
    }

    return tableHeaders;
  }, [orgInfo]);

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={updatedTableHeaders} />

        <tbody>
          {/* User List Table Body */}
          <UserListTableBody
            userList={userList}
            userListLoading={userListLoading}
            setUserId={setUserId}
            pageSize={pageSize}
            pageNumber={pageNo}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
