import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { AuditListActions } from "../../redux-slice/audit/AuditListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { getAuditListApi, getAuditListByAuditorApi } from "../../api/audit/AuditListAPI";

// Get Audit List
function* getAuditList(action) {
  try {
    const { orgId, plantId = "", pageNumber = "", pageSize = "", filters = {} } = action.payload;

    const { items, pagination } = yield getAuditListApi(orgId, plantId, pageNumber, pageSize, filters);

    // Dispatching Action
    yield put(
      AuditListActions.getAuditListSuccess({
        auditList: items,
        auditListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditListActions.getAuditListFailure({ error }));
  }
}

// Get Audit List By Auditor
function* getAuditListByAuditor(action) {
  try {
    const { userId = "" } = action.payload;

    const auditListByAuditor = yield getAuditListByAuditorApi(userId);

    // Dispatching Action
    yield put(AuditListActions.getAuditListByAuditorSuccess({ auditListByAuditor }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(AuditListActions.getAuditListByAuditorFailure({ error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(AuditListActions.getAuditList.type, getAuditList),
    takeLatest(AuditListActions.getAuditListByAuditor.type, getAuditListByAuditor),
  ]);
}
