import { toast } from "react-toastify";
import { all, put, takeEvery, takeLatest } from "redux-saga/effects";

// Actions
import { UserActions } from "../../redux-slice/user/UserSlice";
import { UserListActions } from "../../redux-slice/user/UserListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Constants
import { ModalKeys } from "../../constants/ModalConstants";

// APIs
import {
  addUserApi,
  assignRoleToUserByIdApi,
  getUserInfoByIdApi,
  updateUserByIdApi,
  updateUserStatusByIdApi,
} from "../../api/user/UserAPI";
import { getUserListApi } from "../../api/user/UserListAPI";

// Component Actions
import { ModalActions } from "../../components/modal/Modal";

// Add User
function* addUser(action) {
  try {
    const { userData } = action.payload;

    yield addUserApi(userData);

    yield put(UserActions.addUserSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.addUserFailure({ error }));
  }
}

// Assign Role To User By Id
function* assignRoleToUserById(action) {
  try {
    const { orgId, orgType, userId, roleId } = action.payload;

    yield assignRoleToUserByIdApi(userId, roleId);

    const { items, pagination } = yield getUserListApi(orgId);

    // Dispatching Action to refresh user list table
    yield put(
      UserListActions.getUserListSuccess({
        orgType,
        userList: items,
        userListPagination: pagination,
      })
    );

    // Dispatching Action
    yield put(UserActions.assignUserRoleSuccess());

    // Toaster
    toast.success("Role Assigned Successfully");

    // Close modal
    ModalActions.close(ModalKeys.assignRoleComponentModal);
  } catch (error) {
    // Close modal
    ModalActions.close(ModalKeys.assignRoleComponentModal);

    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.assignUserRoleFailure({ error }));
  }
}

// Get User Info By Id
function* getUserInfoById(action) {
  try {
    const { userId } = action.payload;

    const { user } = yield getUserInfoByIdApi(userId);

    // Dispatching Action
    yield put(UserActions.getUserInfoSuccess({ userInfo: user }));
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.getUserInfoFailure({ error }));
  }
}

// Update User By Id
function* updateUserById(action) {
  try {
    const { userData, userId } = action.payload;

    yield updateUserByIdApi(userData, userId);

    const { user } = yield getUserInfoByIdApi(userId);

    yield put(UserActions.getUserInfoSuccess({ userInfo: user }));

    yield put(UserActions.updateUserSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.updateUserFailure({ error }));
  }
}

// Update User Status By Id
function* updateUserStatusById(action) {
  const { orgId, userId, status, pageNumber, pageSize } = action.payload;

  try {
    yield updateUserStatusByIdApi(userId, status);

    const { items, pagination } = yield getUserListApi(orgId, pageNumber, pageSize);

    yield put(
      UserListActions.getUserListSuccess({
        userList: items,
        userListPagination: pagination,
      })
    );

    yield put(UserActions.updateUserStatusSuccess({ userId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(UserActions.updateUserStatusFailure({ userId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(UserActions.addUser.type, addUser),
    takeLatest(UserActions.assignUserRole.type, assignRoleToUserById),
    takeLatest(UserActions.getUserInfo.type, getUserInfoById),
    takeLatest(UserActions.updateUser.type, updateUserById),
    takeEvery(UserActions.updateUserStatus.type, updateUserStatusById),
  ]);
}
