import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";

// Action
import { PlantActions } from "../../../redux-slice/plant/PlantSlice";

// Utils
import tableSerialNumber from "../../../utils/TableUtils";
import URLs from "../../../app/utils/URLs";

// Urls
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";
import TableHeaders from "../../../components/table/TableHeader";
import TableLoader from "../../../components/table/TableLoader";
import TableDataNotFound from "../../../components/table/TableDataNotFound";
import TablePagination from "../../../components/table/TablePagination";
import ToggleButton from "../../../components/antd/button/ToggleButton";

// Page Constants
const tableHeaders = [
  {
    title: {
      displayName: "#",
    },
  },
  {
    title: {
      displayName: "Name",
    },
  },
  {
    title: {
      displayName: "Plant Id",
    },
  },
  {
    title: {
      displayName: "Area",
    },
  },
  {
    title: {
      displayName: "Employee Count",
    },
  },
  {
    title: {
      displayName: "City",
    },
  },
  {
    title: {
      displayName: "Established Year",
    },
  },
  {
    title: {
      displayName: "Action",
    },
  },
];

// Page Components
function PlantStatus({ plant, pageNumber, pageSize }) {
  const { id, orgId, isActive } = plant;

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.plant.updatePlantStatusLoadingMap[id]) || false;
  const updatePlantStatusSuccessMap = useSelector((state) => state.plant.updatePlantStatusSuccessMap[id]) || false;
  const status = !isActive;

  function updatePlantStatus() {
    dispatch(PlantActions.updatePlantStatus({ orgId, plantId: id, status, pageNumber, pageSize }));
  }

  useEffect(() => {
    if (updatePlantStatusSuccessMap) {
      toast.success("Plant Status Updated Successfully");
    }
  }, [updatePlantStatusSuccessMap]);

  return (
    <td>
      {/* Status Toggle */}
      <ToggleButton isActive={isActive} loading={loading} onClick={updatePlantStatus} />
    </td>
  );
}
function PlantListTableActionCell({ plant }) {
  const navigate = useNavigate();

  // Info
  const { id, orgId, isActive } = plant;

  // Action Butttons
  const actionButtons = [
    {
      label: "View",
      color: "primary",
      iconClassName: "fas fa-eye",
      redirectUrl: URLs.format(RedirectTo.PlantViewPageUrl, { orgId, plantId: id }),
    },
    {
      label: "Audits",
      color: "secondary",
      iconClassName: "fas fa-pen",
      redirectUrl: URLs.format(RedirectTo.PlantAuditListPageUrl, { orgId, plantId: id }),
    },
    {
      label: "Manage",
      color: "success",
      iconClassName: "fas fa-eye",
      redirectUrl: URLs.format(RedirectTo.PlantSetupPageUrl, { orgId, plantId: id }),
    },
  ];

  return (
    <td>
      <div className="btn-cont">
        {/* Button */}
        {actionButtons.map((btn, idx) => {
          const { label = "", color = "", iconClassName = "", redirectUrl = "" } = btn || {};

          return (
            <Button
              key={idx}
              label={label}
              color={color}
              size="sm"
              onClick={() => navigate(redirectUrl)}
              disabled={!isActive}
            >
              <i className={iconClassName}></i>
            </Button>
          );
        })}
      </div>
    </td>
  );
}

function PlantListTableRow({ plant, serialNo, pageNumber, pageSize }) {
  const { name, internalId, areaInSqFt, employeesCount, city, estYear } = plant;

  return (
    <tr>
      <td>{serialNo}</td>
      <td>{name}</td>
      <td>{internalId}</td>
      <td>{areaInSqFt} Sq ft</td>
      <td>{employeesCount}</td>
      <td>{city}</td>
      <td>{estYear}</td>

      {/* Plant Table Action Cell */}
      <PlantListTableActionCell plant={plant} pageNumber={pageNumber} pageSize={pageSize} />

      <PlantStatus plant={plant} pageNumber={pageNumber} pageSize={pageSize} />
    </tr>
  );
}

function PlantListTableBody({ plantList, plantListLoading, pageNumber, pageSize }) {
  //Loader
  if (plantListLoading) {
    return <TableLoader colSpan={9} />;
  }

  //if there is no plant list
  if (plantList.length === 0) {
    return <TableDataNotFound message="No plant present" colSpan={9} />;
  }

  //Plant list table row
  return (
    <>
      {plantList.map((plant, index) => {
        //serial number for pagination table
        const serialNo = tableSerialNumber(pageNumber, pageSize, index);

        return (
          <PlantListTableRow
            key={plant.id}
            plant={plant}
            serialNo={serialNo}
            pageNumber={pageNumber}
            pageSize={pageSize}
          />
        );
      })}
    </>
  );
}

/**
 * Plant List Table
 */
export default function PlantListTable({ plantList, plantListLoading, plantListPagination, pageNumber }) {
  const { totalCount, pageSize, pageNo } = plantListPagination;

  return (
    <>
      <table className="table table-bordered border-end">
        {/* Table Headers */}
        <TableHeaders tableHeaders={tableHeaders} />
        <tbody>
          {/* Plant List Table Body */}
          <PlantListTableBody
            plantList={plantList}
            plantListLoading={plantListLoading}
            pageSize={pageSize}
            pageNumber={pageNo}
          />
        </tbody>
      </table>

      {/* Table Pagination */}
      <TablePagination pageNumber={pageNumber} pageSize={pageSize} totalCount={totalCount} />
    </>
  );
}
