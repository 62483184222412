import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.updateReportSuccess = false;
  state.createReportSuccess = {};
  state.deleteReportSuccess = {};
}

// Initial State
const initialState = {
  // Create Report
  createdReportInfo: {},
  createReportLoading: {},
  createReportSuccess: {},
  createReportError: "",

  // Update Report
  updateReportLoading: false,
  updateReportSuccess: false,
  updateReportError: "",

  // Delete Report
  deleteReportLoading: {},
  deleteReportSuccess: {},
  deleteReportError: "",

  // Get Report HTML
  reportHTML: "",
  reportHTMLLoading: false,
  reportHTMLError: "",

  // Download Report PDF
  downloadReportPDFLoading: {},
  downloadReportPDFError: "",

  // Update Report Status
  updateReportStatusLoading: {},
  updateReportStatusError: "",

  // Get Audit Report Data
  reportData: {},
  reportDataLoading: false,
  reportDataError: "",
};

// Audit Reports Slice
const auditReportsSlice = createSlice({
  // Name
  name: "AuditReports",

  // Initial State
  initialState,

  // Reducers
  reducers: {
    // Create Report
    createReport: (state, action) => {
      const { reportType = "" } = action.payload;
      state.createReportSuccess[reportType] = false;
      state.createReportLoading[reportType] = true;
      state.createReportError = "";
    },

    createReportSuccess: (state, action) => {
      const { reportType = "", createdReportInfo = {} } = action.payload;
      state.createdReportInfo = createdReportInfo;
      state.createReportSuccess[reportType] = true;
      delete state.createReportLoading[reportType];
    },

    createReportFailure: (state, action) => {
      const { reportType = "", error = {} } = action.payload;
      delete state.createReportLoading[reportType];
      state.createReportError = error;
    },

    // Update Report
    updateReport: (state) => {
      state.updateReportSuccess = false;
      state.updateReportLoading = true;
    },

    updateReportSuccess: (state) => {
      state.updateReportSuccess = true;
      state.updateReportLoading = false;
      state.updateReportError = "";
    },

    updateReportFailure: (state, action) => {
      state.updateReportLoading = false;
      state.updateReportError = action.payload.error;
    },

    // Delete Report
    deleteReport: (state, action) => {
      const { reportId = "" } = action.payload;
      state.deleteReportSuccess[reportId] = false;
      state.deleteReportLoading[reportId] = true;
      state.deleteReportError = "";
    },

    deleteReportSuccess: (state, action) => {
      const { reportId = "" } = action.payload;
      state.deleteReportSuccess[reportId] = true;
      delete state.deleteReportLoading[reportId];
    },

    deleteReportFailure: (state, action) => {
      const { reportId = "", error = "" } = action.payload;
      delete state.deleteReportLoading[reportId];
      state.deleteReportError = error;
    },

    // Get Report HTML
    getReportHTML: (state) => {
      state.reportHTMLLoading = true;
    },

    getReportHTMLSuccess: (state, action) => {
      state.reportHTML = action.payload.reportHTML;
      state.reportHTMLLoading = false;
      state.reportHTMLError = "";
    },

    getReportHTMLFailure: (state, action) => {
      state.reportHTMLLoading = false;
      state.reportHTMLError = action.payload.error;
    },

    // Download Report PDF
    downloadReportPDF: (state, action) => {
      const { reportId = "" } = action.payload;
      state.downloadReportPDFLoading[reportId] = true;
      state.downloadReportPDFError = "";
    },

    downloadReportPDFSuccess: (state, action) => {
      const { reportId = "" } = action.payload;
      delete state.downloadReportPDFLoading[reportId];
    },

    downloadReportPDFFailure: (state, action) => {
      const { reportId = "", error = "" } = action.payload;
      delete state.downloadReportPDFLoading[reportId];
      state.downloadReportPDFError = error;
    },

    // Update Report Status
    updateReportStatus: (state, action) => {
      const { reportId = "" } = action.payload;

      state.updateReportStatusLoading = {
        ...state.updateReportStatusLoading,
        [reportId]: true,
      };
    },

    updateReportStatusSuccess: (state, action) => {
      const { reportId = "" } = action.payload;

      //To get previous state
      const clonedUpdateReportStatusLoading = { ...state.updateReportStatusLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateReportStatusLoading[reportId];

      state.updateReportStatusLoading = clonedUpdateReportStatusLoading;
      state.updateReportStatusError = "";
    },

    updateReportStatusFailure: (state, action) => {
      const { reportId = "", error = "" } = action.payload;

      //To get previous state
      const clonedUpdateReportStatusLoading = { ...state.updateReportStatusLoading };

      //To remove the id from state after successfull loading
      delete clonedUpdateReportStatusLoading[reportId];

      state.updateReportStatusLoading = clonedUpdateReportStatusLoading;
      state.updateReportStatusError = error;
    },

    // Get Report Data
    getReportData: (state) => {
      state.reportDataLoading = true;
    },

    getReportDataSuccess: (state, action) => {
      state.reportData = action.payload.reportData;
      state.reportDataLoading = false;
      state.reportDataError = "";
    },

    getReportDataFailure: (state, action) => {
      state.reportDataLoading = false;
      state.reportDataError = action.payload.reportDataError;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditReportsReducer = auditReportsSlice.reducer;

// Actions
export const AuditReportsActions = auditReportsSlice.actions;
