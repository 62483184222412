import * as yup from "yup";

// Regex Expression
const phoneRegex = RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/);

// Validation Schema
export const UserFormValidationSchema = {
  firstName: yup.string().required("Please fill the first name"),
  emailId: yup.string().email("Please provide valid email").required("Please fill the email id"),
  phoneNo: yup
    .string()
    .matches(phoneRegex, "Please provide valid contact number")
    .required("Please fill the contact number"),
};

// Form Inputs
export const UserFormSchema = [
  {
    containerClassName: "row",
    inputItems: [
      {
        label: "First Name",
        key: "firstName",
        placeholder: "Enter first name...",
        isMandotary: true,
      },
      {
        label: "Last Name",
        key: "lastName",
        placeholder: "Enter last name...",
      },
    ],
  },
  {
    containerClassName: "row mt-4",
    inputItems: [
      {
        label: "Contact Number",
        key: "phoneNo",
        type: "number",
        placeholder: "Enter contact number...",
        isMandotary: true,
      },
      {
        label: "Designation",
        key: "designation",
        placeholder: "Enter designation...",
      },
    ],
  },
];
