import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.updateComponentTypeStatusSuccessMap = {};
  state.updateObservationCodeStatusSuccessMap = {};
  state.updateRecommendationCodeStatusSuccessMap = {};
  state.updateParameterStatusSuccessMap = {};
}

// Initial State
const initialState = {
  // Get Component Type Info
  componentTypeInfo: {},
  componentTypeInfoLoading: false,
  componentTypeInfoError: "",

  // Update ComponentType Status (Enable/Disable)
  updateComponentTypeStatusLoadingMap: {},
  updateComponentTypeStatusSuccessMap: {},
  updateComponentTypeStatusError: "",

  // Update Observation Code Status (Enable/Disable)
  updateObservationCodeStatusLoadingMap: {},
  updateObservationCodeStatusSuccessMap: {},
  updateObservationCodeStatusError: "",

  // Update Recommnedation Code Status (Enable/Disable)
  updateRecommendationCodeStatusLoadingMap: {},
  updateRecommendationCodeStatusSuccessMap: {},
  updateRecommendationCodeStatusError: "",

  // Update Parameter Status(Enable/Disable)
  updateParameterStatusLoadingMap: {},
  updateParameterStatusSuccessMap: {},
  updateParameterStatusError: "",
};

// Component Type Slice
const componetnTypeSlice = createSlice({
  name: "ComponentType",
  initialState,
  reducers: {
    // Component Type Info
    getComponentTypeInfo: (state) => {
      state.componentTypeInfoLoading = true;
    },

    getComponentTypeInfoSuccess: (state, action) => {
      state.componentTypeInfoLoading = false;
      state.componentTypeInfo = action.payload.componentTypeInfo;
      state.componentTypeInfoError = "";
    },

    getComponentTypeInfoFailure: (state, action) => {
      state.componentTypeInfoLoading = false;
      state.componentTypeInfoError = action.payload.error;
    },

    // Update ComponentType Status (Enable/Disable) By Id
    updateComponentTypeStatus: (state, action) => {
      const { componentTypeId } = action.payload;
      state.updateComponentTypeStatusSuccessMap[componentTypeId] = false;
      state.updateComponentTypeStatusLoadingMap[componentTypeId] = true;
      state.updateComponentTypeStatusError = "";
    },

    updateComponentTypeStatusSuccess: (state, action) => {
      const { componentTypeId } = action.payload;
      state.updateComponentTypeStatusSuccessMap[componentTypeId] = true;
      delete state.updateComponentTypeStatusLoadingMap[componentTypeId];
    },

    updateComponentTypeStatusFailure: (state, action) => {
      const { componentTypeId } = action.payload;
      delete state.updateComponentTypeStatusLoadingMap[componentTypeId];
      state.updateComponentTypeStatusError = action.payload.error;
    },

    // Update Observation Code Status (Enable/Disable) By Id
    updateObservationCodeStatus: (state, action) => {
      const { obsCodeId } = action.payload;
      state.updateObservationCodeStatusSuccessMap[obsCodeId] = false;
      state.updateObservationCodeStatusLoadingMap[obsCodeId] = true;
      state.updateObservationCodeStatusError = "";
    },

    updateObservationCodeStatusSuccess: (state, action) => {
      const { obsCodeId } = action.payload;
      state.updateObservationCodeStatusSuccessMap[obsCodeId] = true;
      delete state.updateObservationCodeStatusLoadingMap[obsCodeId];
    },

    updateObservationCodeStatusFailure: (state, action) => {
      const { obsCodeId } = action.payload;
      delete state.updateObservationCodeStatusLoadingMap[obsCodeId];
      state.updateObservationCodeStatusError = action.payload.error;
    },

    // Update Recommnedation Code Status (Enable/Disable) By Id
    updateRecommendationCodeStatus: (state, action) => {
      const { recCodeId } = action.payload;
      state.updateRecommendationCodeStatusSuccessMap[recCodeId] = false;
      state.updateRecommendationCodeStatusLoadingMap[recCodeId] = true;
      state.updateRecommendationCodeStatusError = "";
    },

    updateRecommendationCodeStatusSuccess: (state, action) => {
      const { recCodeId } = action.payload;
      state.updateRecommendationCodeStatusSuccessMap[recCodeId] = true;
      delete state.updateRecommendationCodeStatusLoadingMap[recCodeId];
    },

    updateRecommendationCodeStatusFailure: (state, action) => {
      const { recCodeId } = action.payload;
      delete state.updateRecommendationCodeStatusLoadingMap[recCodeId];
      state.updateRecommendationCodeStatusError = action.payload.error;
    },

    // Update Parameter Status (Enable/Disable) By Id
    updateParameterTypeStatus: (state, action) => {
      const { parameterId } = action.payload;
      state.updateParameterStatusSuccessMap[parameterId] = false;
      state.updateParameterStatusLoadingMap[parameterId] = true;
      state.updateParameterStatusError = "";
    },

    updateParameterTypeStatusSuccess: (state, action) => {
      const { parameterId } = action.payload;
      state.updateParameterStatusSuccessMap[parameterId] = true;
      delete state.updateParameterStatusLoadingMap[parameterId];
    },

    updateParameterTypeStatusFailure: (state, action) => {
      const { parameterId, error } = action.payload;
      delete state.updateParameterStatusLoadingMap[parameterId];
      state.updateParameterStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const ComponentTypeReducer = componetnTypeSlice.reducer;

// Actions
export const ComponentTypeActions = componetnTypeSlice.actions;
