import { all, put, takeEvery } from "redux-saga/effects";

// Actions
import { MaterialActions } from "../../redux-slice/material/MaterialSlice";
import { MaterialListActions } from "../../redux-slice/material/MaterialListSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// APIs
import { updateMaterialStatusByIdApi } from "../../api/material/MaterialAPI";
import { getMaterialListApi } from "../../api/material/MaterialListAPI";

// Update Material Status By Id
function* updateMaterialStatusById(action) {
  try {
    const { materialId, status, pageNumber, pageSize } = action.payload;

    yield updateMaterialStatusByIdApi(materialId, status);

    const { items, pagination } = yield getMaterialListApi(pageNumber, pageSize);

    yield put(
      MaterialListActions.getMaterialListSuccess({
        materialList: items,
        materialListPagination: pagination,
      })
    );

    yield put(MaterialActions.updateMaterialStatusSuccess({ materialId }));
  } catch (error) {
    const { materialId } = action.payload;

    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(MaterialActions.updateMaterialStatusFailure({ materialId, error }));
  }
}

export default function* root() {
  yield all([takeEvery(MaterialActions.updateMaterialStatus.type, updateMaterialStatusById)]);
}
