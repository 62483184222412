import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouteLoaderData } from "react-router-dom";

// Actions
import { OrganizationActions } from "../../../../redux-slice/organization/OrganizationSlice";
import { PlantActions } from "../../../../redux-slice/plant/PlantSlice";
import { AuditActions } from "../../../../redux-slice/audit/AuditSlice";

// Components
import Address from "../../../../components/address/Address";

// Constants
import { LayoutIds } from "../../../../constants/GeneralConstants";

// Sections
import AuditPlantInfoCard from "./AuditPlantInfoCard";
import AuditAuditorInfoCard from "./AuditAuditorInfoCard";
import AuditDate from "./AuditDate";

// Page Constants
const initialAuditTimeline = {
  AUDIT_INIT: {
    index: 0,
    auditStatus: "AUDIT_INIT",
    auditStatusTitle: "Audit Initiated",
    auditStatusDescription: "New Audit request has been created",
  },
  QUESTIONNAIRE: {
    index: 1,
    auditStatus: "QUESTIONNAIRE",
    auditStatusTitle: "Pre-assessment",
    auditStatusDescription: "To prepare for the audit, pre requisite data gathering happens here",
  },
  PRE_AUDIT: {
    index: 2,
    auditStatus: "PRE_AUDIT",
    auditStatusTitle: "Walkthrough Audit",
    auditStatusDescription: "Penultimate stage to Audit, where Auditors physically inspect the Plant to Plan the Audit",
  },
  AUDIT: {
    index: 3,
    auditStatus: "AUDIT",
    auditStatusTitle: "Audit",
    auditStatusDescription:
      "Actual audit stage, where Auditors setup sensors acroll Plant components and take various readings against various Parameters",
  },
  POST_AUDIT: {
    index: 4,
    auditStatus: "POST_AUDIT",
    auditStatusTitle: "Recommendations and Report",
    auditStatusDescription:
      "Last stage of Audit process, where the final Report with Recommendations is submitted to the Plant.",
  },
};

// Page Components
function PlantInfoCard({ plantInfo = {} }) {
  // Dispatch
  const dispatch = useDispatch();

  // Selector State
  const organizationInfo = useSelector((state) => state.organization.organizationInfo);
  const { name: orgName = "" } = organizationInfo || {};

  // Plant Information
  const {
    name = "",
    internalId = "",
    areaInSqFt = "",
    estYear = "",
    workHrsPerDay = "",
    workDaysPerWeek = "",
    employeesCount = "",
    addrLine1 = "",
    addrLine2 = "",
    city = "",
    state = "",
    pincode = "",
    orgId = "",
  } = plantInfo;

  const addressObj = {
    line1: addrLine1,
    line2: addrLine2,
    city,
    state,
    pincode,
  };

  // use Effect
  useEffect(() => {
    if (orgId) {
      dispatch(OrganizationActions.getOrganizationInfo({ orgId }));
    }
  }, [dispatch, orgId]);

  return (
    <div className="card card-info">
      <div className="card-body">
        <h5 className="card-title">Plant Information</h5>

        <div className="sec-info">
          <div>
            <p className="bold">Organization</p>
            <label>{orgName}</label>
          </div>
          <div>
            <p className="bold">Name</p>
            <label>{name}</label>
          </div>
          <div>
            <p>Internal Id </p>
            <label>{internalId}</label>
          </div>
        </div>

        <div className="sec-info">
          <div>
            <p>Established Year</p>
            <label>{estYear}</label>
          </div>
          <div>
            <p>Area (Sq ft)</p>
            <label>{areaInSqFt}</label>
          </div>
        </div>

        <div className="sec-info">
          <div>
            <p>Working Hours / Day</p>
            <label>{workHrsPerDay}</label>
          </div>
          <div>
            <p>Working Days / Week</p>
            <label>{workDaysPerWeek}</label>
          </div>
          <div>
            <p>Employee Count</p>
            <label>{employeesCount}</label>
          </div>
        </div>

        <div className="sec-info">
          {/* Address */}
          <Address title={"Plant Address"} addressObj={addressObj} />
        </div>
      </div>
    </div>
  );
}

function AuditTimelineCard({ auditTimeline }) {
  return (
    <div className="card card-info">
      <div className="card-body">
        <h5 className="card-title">Audit Status and Timeline</h5>

        <ul className="timeline timeline-vertical list-unstyled">
          {auditTimeline?.map((timeline, index) => {
            const { auditStatusTitle, auditStatusDescription, isCompleted, auditCompletedIndex } = timeline;
            const eventItemClass = `event-item ${isCompleted ? "" : "inactive"}`;
            const textClassName = `event-item-dot ${
              auditCompletedIndex === index ? "text-primary" : isCompleted ? "text-success" : ""
            }`;

            return (
              <li key={index} className={eventItemClass}>
                <div className={textClassName}>
                  <i className="far fa-arrow-alt-circle-right"></i>
                </div>
                <div className="d-flex justify-content-between mb-0">
                  <div>
                    <h5>{auditStatusTitle}</h5>
                    <p className="">{auditStatusDescription}</p>
                  </div>
                  <div>{/** button to next state */}</div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

/**
 * Audit Info Page
 */
export default function AuditInfoPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Get saved info from the loader of AuditLayout
  // There is other way to get this through OutLet
  // TODO: Need to find which is the better way to access the data..
  const { auditInfo = {} } = useRouteLoaderData(LayoutIds.AUDIT);
  const { plantId = "" } = auditInfo;

  // construct auditTimeLine
  const auditTimeline = constructAuditTimeline({ auditInfo, initialAuditTimeline });

  // Selector State
  const plantInfo = useSelector((state) => state.plant.plantInfo);

  // useEffect to get the PlantInfo
  useEffect(() => {
    dispatch(PlantActions.getPlantInfo({ plantId }));
  }, [dispatch, plantId]);

  useEffect(() => {
    return () => dispatch(AuditActions.resetAllApiSuccessState());
  }, [dispatch]);

  // Constructing Audit Time line with completed status
  function constructAuditTimeline({ auditInfo, initialAuditTimeline }) {
    const { auditStatus = "" } = auditInfo;

    // Finding the index of the completed audit time line
    // The index value indicates current status of the audit
    const auditTimelineIndex = initialAuditTimeline[auditStatus]?.index || -1;

    // If index is -1, that means all the audit are completed
    const auditTimeLineValues = Object.values(initialAuditTimeline);
    const auditCompletedTimelineIndex = auditTimelineIndex === -1 ? auditTimeLineValues.length : auditTimelineIndex;

    // Constructing array which has isCompleted key, which indicates whether that audit is completed or not
    const auditCompletedTimeline = Object.values(initialAuditTimeline).reduce((acc, timeline) => {
      // Here audit completed index indicates the current stage of audit
      // So for all the completed audit, isCompleted value is set to true
      const { index } = timeline;
      timeline.isCompleted = index <= auditCompletedTimelineIndex ? true : false;
      timeline.auditCompletedIndex = auditCompletedTimelineIndex;
      acc[index] = { ...timeline };
      return [...acc];
    }, []);

    return auditCompletedTimeline;
  }

  return (
    <div className="page-content">
      <div className="row mt-3">
        <div className="col-md-2">
          {/* Plant Info View */}
          <PlantInfoCard plantInfo={plantInfo} />
        </div>
        <div className="col-md-4">
          <div className="card card-info">
            <div className="card-body">
              <h5 className="card-title mb-3">Audit Information</h5>

              <AuditDate auditInfo={auditInfo} />

              {/** Auditor Side info */}
              <AuditAuditorInfoCard auditInfo={auditInfo} />

              {/* Audit Plant Card */}
              <AuditPlantInfoCard auditInfo={auditInfo} />
            </div>
          </div>
        </div>
        <div className="col-md-6">
          {/** Audit Side info */}
          <AuditTimelineCard auditTimeline={auditTimeline} />
        </div>
      </div>
    </div>
  );
}
