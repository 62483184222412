import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";

// Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { NICCodeListActions } from "../../../redux-slice/nic-code/NICCodeListSlice";

// Constants
import { QueryParamsKeys } from "../../../constants/GeneralConstants";

// Components
import Input from "../../../components/input/Input";
import PageHeader from "../../../components/page-header/PageHeader";

// Sections
import NICCodeListTable from "./NICCodeListTable";

//Page Components
function NICCodeListTableActionsSection() {
  return (
    <div className="my-3">
      {/* Input Component */}
      <Input className="input-search" placeholder={`Search nic codes...`} isSearchInput={true} />
    </div>
  );
}

/**
 * NIC Code List Page
 */
export default function NICCodeListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Query Params
  const [searchParams, updateQueryParams] = useUpdateQueryParams();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Page Number
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const searchText = searchParams.get(QueryParamsKeys.searchText);

  // useEffect
  useEffect(() => {
    dispatch(NICCodeListActions.getNICCodeList({ pageNumber, searchText }));
  }, [dispatch, pageNumber, searchText]);

  useEffect(() => {
    setHeaderComponent(<PageHeader title="NIC Code List" />);
  }, []);

  return (
    <div className="page-content">
      {/* NIC Code List Table Actions Section */}
      <NICCodeListTableActionsSection />

      {/* NIC Code List Table */}
      <NICCodeListTable />
    </div>
  );
}
