import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

// Action
import { JobActions } from "../../../redux-slice/job/JobSlice";

// Constants
import { RedirectTo } from "../../../urls/page-urls/RedirectURL";

// Components
import { Button } from "../../../components/button/Button";

// Sections
import JobSessionsTable from "./JobSessionsTable";
import JobInfoTable from "./JobInfoTable";

// Page Components
function JobInformationCard({ jobInfo, jobInfoLoading }) {
  return (
    <div className="card shadow p-3 mb-5 rounded">
      <div className="card-body">
        <div className="fw-bold mb-2">Job Information</div>

        {/* Job Information Table */}
        <JobInfoTable jobInfo={jobInfo} jobInfoLoading={jobInfoLoading} />
      </div>
    </div>
  );
}

function JobSessionsCard({ jobSessions, jobSessionsLoading }) {
  return (
    <div className="card shadow p-3 mb-5 rounded">
      <div className="card-body">
        <div className="fw-bold">Job Sessions</div>

        {/* Job Sessions Table */}
        <JobSessionsTable jobSessions={jobSessions} jobSessionsLoading={jobSessionsLoading} />
      </div>
    </div>
  );
}

function RunJobCard({ runJobLoading, jobId }) {
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [job, setJobInput] = useState({});

  // Functions
  function handleJobChange(event) {
    setJobInput(event.target.value);
  }

  return (
    <div className="card shadow p-3 mb-5 rounded">
      <div className="card-body">
        <textarea
          rows={6}
          value={job}
          className="form-control border-secondary-subtle"
          onChange={handleJobChange}
          placeholder="Enter JSON Data..."
        ></textarea>

        {/* Button */}
        <Button
          className="mt-3"
          color="primary"
          size="sm"
          label="Run Once Now"
          loading={runJobLoading}
          disabled={job.length === 0 || runJobLoading}
          onClick={() => dispatch(JobActions.runJob({ job, jobId }))}
        />
      </div>
    </div>
  );
}

function JobViewPageHeader({ jobId }) {
  // Dispatch
  const dispatch = useDispatch();

  // Job Info Selector State
  const jobInfo = useSelector((state) => state.job.jobInfo);

  // Resume Job Selector State
  const resumeJobLoading = useSelector((state) => state.job.resumeJobLoading);
  const resumeJobSuccess = useSelector((state) => state.job.resumeJobSuccess);

  // Pause Job Selector State
  const pauseJobLoading = useSelector((state) => state.job.pauseJobLoading);
  const pauseJobSuccess = useSelector((state) => state.job.pauseJobSuccess);

  // Job info
  const { isActive = false } = jobInfo;

  const statusButton = {
    active: {
      buttonLabel: "Pause",
      buttonColor: "danger",
      loading: pauseJobLoading,
      onClick: () => {
        dispatch(JobActions.pauseJob({ jobId }));
      },
    },
    inActive: {
      buttonLabel: "Resume",
      buttonColor: "success",
      loading: resumeJobLoading,
      onClick: () => {
        dispatch(JobActions.resumeJob({ jobId }));
      },
    },
  };

  const status = isActive ? "active" : "inActive";

  const { buttonLabel, buttonColor, loading, onClick } = statusButton[status];

  useEffect(() => {
    if (resumeJobSuccess) {
      toast.success("Run Job Success");
    }
  }, [resumeJobSuccess]);

  useEffect(() => {
    if (pauseJobSuccess) {
      toast.success("Pause Job Success");
    }
  }, [pauseJobSuccess]);

  return (
    <>
      <div className="d-flex justify-content-between w-100">
        <h2 className="title text-truncate mx-4 mt-1">Job :: {jobId}</h2>

        {/* Button */}
        <Button
          color={buttonColor}
          size="sm"
          className="me-3"
          label={buttonLabel}
          loading={loading}
          disabled={loading}
          onClick={onClick}
        />
      </div>
    </>
  );
}

/**
 * Job View Page
 */
export default function JobViewPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Navigate
  const navigate = useNavigate();

  // Params
  const params = useParams();
  const { jobId } = params;

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  // Job Info Selector State
  const jobInfo = useSelector((state) => state.job.jobInfo);
  const jobInfoLoading = useSelector((state) => state.job.jobInfoLoading);

  // Job Sessions Selector State
  const jobSessions = useSelector((state) => state.job.jobSessions);
  const jobSessionsLoading = useSelector((state) => state.job.jobSessionsLoading);

  // Run Job Selector State
  const runJobLoading = useSelector((state) => state.job.runJobLoading);
  const runJobSuccess = useSelector((state) => state.job.runJobSuccess);

  // useEffect
  useEffect(() => {
    dispatch(JobActions.getJobInfo({ jobId }));
    dispatch(JobActions.getJobSession({ jobId }));
  }, [dispatch, jobId]);

  useEffect(() => {
    setHeaderComponent(<JobViewPageHeader jobId={jobId} />);
  }, []);

  useEffect(() => {
    if (runJobSuccess) {
      toast.success("Run Job Success");
    }
  }, [runJobSuccess]);

  useEffect(() => {
    return () => dispatch(JobActions.resetAllApiSuccessState());
  }, [dispatch]);

  return (
    <div className="page-content">
      {/* Redirect Button */}
      <Button label="Back" color="warning" onClick={() => navigate(RedirectTo.JobListPageUrl)}>
        <i className="fa-solid fa-arrow-left"></i>
      </Button>

      <div className="row mt-4">
        <div className="col-md-5">
          {/* Job Information Card */}
          <JobInformationCard jobInfo={jobInfo} jobInfoLoading={jobInfoLoading} />

          {/* Run Job Card */}
          <RunJobCard runJobLoading={runJobLoading} jobId={jobId} />
        </div>
        <div className="col-md-7">
          {/* Job Sessions Card */}
          <JobSessionsCard jobSessions={jobSessions} jobSessionsLoading={jobSessionsLoading} />
        </div>
      </div>
    </div>
  );
}
