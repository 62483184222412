// ALL PAGE URLS
import { OrgTypes } from "../../constants/GeneralConstants";

// Utils
import URLs from "../../app/utils/URLs";

export const RedirectTo = {
  // Login Page
  LoginPageUrl: "/login",
  ForgotPasswordPageUrl: "/forgot-password",

  // Home Page
  DashboardPageUrl: "/",

  // Dev Page
  UnitConversionToolPageUrl: "/unit-conversion-tool",

  // Profile
  ProfilePageUrl: "/profile",

  // Job Page
  JobListPageUrl: "/admin/jobs",
  JobViewPageUrl: "/admin/job/:jobId",

  // Role Page
  RoleListPageUrl: "/admin/role",
  RoleCreatePageUrl: "/admin/role/new",
  RoleEditPageUrl: "/admin/role/:roleId",

  // Component-type
  ComponentTypeListPageUrl: "/defs/component-types",
  ParameterListPageUrl: "/defs/component-type/:componentTypeId/parameters",
  RecommendationCodeListPageUrl: "/defs/component-type/:componentTypeId/recommendation-codes",
  ObservationCodeListPageurl: "/defs/component-type/:componentTypeId/observation-codes",

  // Measurement-type Page
  MeasurementTypeListPageUrl: "/defs/measurement-types",
  MeasurementTypeViewPageUrl: "/defs/measurement-type/:measurementTypeId",

  // Instrument Page
  InstrumentListPageUrl: "/defs/instruments",
  InstrumentViewPageUrl: "/defs/instruments/:instrumentId",

  // Material Page
  MaterialListPageUrl: "/defs/materials",

  // Product Page
  ProductListPageUrl: "/defs/products",

  // Constants Page
  ConstantsListPageUrl: "/defs/constants",

  // HSN Code Page
  HSNCodeListPageUrl: "/defs/hsn-codes",

  // NIC Code Page
  NICCodeListPageUrl: "/defs/nic-codes",

  // Organization Pages
  OrganizationListPageUrl: "/orgs/:orgType",
  OrganizationCreatePageUrl: "/org/:orgType/new",
  OrganizationViewPageUrl: "/org/:orgType/:orgId",

  // Organization - User Pages
  UserListPageUrl: "/org/:orgId/users",
  UserCreatePageUrl: "/org/:orgId/user/new",
  UserViewPageUrl: "/org/:orgId/user/:userId",

  // Organization - Plant Pages
  PlantListPageUrl: "/org/:orgId/plants",
  PlantCreatePageUrl: "/org/:orgId/plant/new",
  PlantViewPageUrl: "/org/:orgId/plant/:plantId",
  PlantSetupPageUrl: "/org/:orgId/plant/:plantId/setup",

  PlantComponentListPageUrl: "/plant-component-list/:plantName/:plantId",
  PlantComponentViewPageUrl: "/plant-component-view/:componentName/:componentId",

  // Audit Pages
  AuditListPageUrl: "/audits",
  AuditListByAuditorPageUrl: "/audits/:userId",
  AuditLibraryPageUrl: "/audits/library",
  AuditCreatePageUrl: "/audit/new",
  AuditViewPageUrl: "/audit/:auditId",

  // Audit SideTabs
  AuditOverviewPageUrl: "/audit/:auditId/overview",
  AuditStatusPageUrl: "/audit/:auditId/status",
  AuditWalkthroughRemarksPageUrl: "/audit/:auditId/walk-through-remarks",
  AuditMeasurementsPageUrl: "/audit/:auditId/measurements",
  AuditObservationsPageUrl: "/audit/:auditId/observations",
  AuditSavingsCalculationPageUrl: "/audit/:auditId/savings-calculation",
  AuditFileListPageUrl: "/audit/:auditId/files",
  AuditDataPageUrl: "/audit/:auditId/data",
  AuditReportsListPageUrl: "/audit/:auditId/reports",
  AuditReportEditorPageUrl: "/audit/:auditId/report/:reportId",

  // Plant SideTabs
  PlantOverviewPageUrl: "/org/:orgId/plant/:plantId/setup/overview",
  PlantComponentsPageUrl: "/org/:orgId/plant/:plantId/setup/components",
  PlantElectricityBillPageUrl: "/org/:orgId/plant/:plantId/setup/elecBill",
  PlantThermalBillPageUrl: "/org/:orgId/plant/:plantId/setup/therBill",
  PlantUserListPageUrl: "/org/:orgId/plant/:plantId/setup/users",
  PlantAuditListPageUrl: "/org/:orgId/plant/:plantId/setup/audits",
  PlantUserViewPageUrl: "/org/:orgId/plant/:plantId/setup/user/:userId/view",
  PlantFileListPageUrl: "/org/:orgId/plant/:plantId/setup/files",

  // Error Page
  ErrorPageUrl: "/error/:errorStatusCode",
};

// Pre Defined URLs

export const URL_ORG_LIST_AUDIT_PARTNERS = URLs.format(RedirectTo.OrganizationListPageUrl, {
  orgType: OrgTypes.AUDIT.nameLowercase,
});
export const URL_ORG_LIST_INDUSTRIES = URLs.format(RedirectTo.OrganizationListPageUrl, {
  orgType: OrgTypes.INDUSTRY.nameLowercase,
});
