import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { Modal } from "antd";

//Hooks
import { useUpdateQueryParams } from "../../../hooks/UpdateQueryParams";

// Actions
import { AuditListActions } from "../../../redux-slice/audit/AuditListSlice";
import { OrganizationListActions } from "../../../redux-slice/organization/OrganizationListSlice";
import { PlantListActions } from "../../../redux-slice/plant/PlantListSlice";
import { AuditActions } from "../../../redux-slice/audit/AuditSlice";

// Constants
import { OrgTypes, QueryParamsKeys } from "../../../constants/GeneralConstants";

// Utils
import { getIndustryOrAuditOrgId, getOrgInfo, getOrgType } from "../../../utils/AuthOrgUtil";

// Components
import { Button, OutlineButton } from "../../../components/button/Button";
import PageHeader from "../../../components/page-header/PageHeader";
import AuditInitiateButton from "../../../components/audit/AuditInitiateButton";
import AuditFilterForm from "./components/AuditFilterForm";

// Page Components
import AuditListTable from "../../../page-components/audit-list/AuditListTable";

// Page Constants
const AUDIT_ORG_KEY = "audit_org";
const INDUSTRY_ORG_KEY = "industry_org";

// Page Components
function PageHeaderSection() {
  return <PageHeader title={"Audits Dashboard"} className="w-100" />;
}

function Tags({ removeAppliedFilter = () => {}, filterKey = "", appliedFilters = {}, isAuditOrg = "" }) {
  const { value = "", text = "" } = appliedFilters[filterKey] || {};

  if (!value) {
    return null;
  }

  if (isAuditOrg && filterKey === AUDIT_ORG_KEY) {
    return null;
  }

  return (
    <OutlineButton color="dark" className="me-1">
      <span>{text || value}</span>{" "}
      <i className="fas fa-close m-0 p-0 ms-1" onClick={() => removeAppliedFilter(filterKey)}></i>
    </OutlineButton>
  );
}

function AppliedFilters({ appliedFilters = {}, setSelectedFilters = () => {}, applyFilters = () => {} }) {
  const { type = "" } = getOrgInfo();
  const isAuditOrg = OrgTypes.AUDIT.id === type;

  function removeAppliedFilter(key) {
    const updatedFilters = { ...appliedFilters, [key]: {} };

    setSelectedFilters(updatedFilters);

    applyFilters(updatedFilters);
  }

  return Object.keys(appliedFilters).map((key) => (
    <Tags
      key={key}
      removeAppliedFilter={removeAppliedFilter}
      filterKey={key}
      appliedFilters={appliedFilters}
      isAuditOrg={isAuditOrg}
    />
  ));
}

function AuditListTableActionsSection({
  setShowModal = () => {},
  authOrgType = "",
  appliedFilters = {},
  setSelectedFilters = () => {},
  applyFilters = () => {},
}) {
  function openFilterModal() {
    setShowModal(true);
  }

  if (authOrgType === OrgTypes.INDUSTRY.id) {
    return null;
  }

  return (
    <div className="d-flex justify-content-between my-3">
      <div>
        <Button label="Filters" color="secondary" className="me-2" onClick={openFilterModal}>
          <i className="fas fa-sliders"></i>
        </Button>

        <AppliedFilters
          appliedFilters={appliedFilters}
          setSelectedFilters={setSelectedFilters}
          applyFilters={applyFilters}
        />
      </div>

      <AuditInitiateButton />
    </div>
  );
}

/**
 * Audit List Page
 */
export default function AuditListPage() {
  // Dispatch
  const dispatch = useDispatch();

  // Page Params
  const [searchParams] = useUpdateQueryParams();

  const [showModal, setShowModal] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState({});

  const { value: industryOrgId = "" } = selectedFilters[INDUSTRY_ORG_KEY] || {};

  // Selector State
  const auditList = useSelector((state) => state.auditList.auditList);
  const auditListLoading = useSelector((state) => state.auditList.auditListLoading);
  const auditListSuccess = useSelector((state) => state.auditList.auditListSuccess) || false;
  const auditListPagination = useSelector((state) => state.auditList.auditListPagination);

  // query params
  const orgId = getIndustryOrAuditOrgId();
  const authOrgType = getOrgType();

  // Outlet Context
  const [setHeaderComponent] = useOutletContext();

  //Page number from url
  const pageNumber = searchParams.get(QueryParamsKeys.pageNumber);
  const pageSize = searchParams.get(QueryParamsKeys.pageSize);

  function closeModal() {
    setShowModal(false);
  }

  function applyFilters(selectedFiltersObj = {}) {
    const filters = Object.keys(selectedFiltersObj).reduce((acc, key) => {
      const { value = "" } = selectedFiltersObj[key] || {};

      acc[key] = value;

      return acc;
    }, {});

    dispatch(AuditListActions.getAuditList({ orgId, filters }));
  }

  //use Effect
  useEffect(() => {
    dispatch(AuditListActions.getAuditList({ orgId, pageNumber, pageSize }));
  }, [dispatch, orgId, pageNumber, pageSize]);

  // useEffect
  useEffect(() => {
    dispatch(OrganizationListActions.getOrganizationList({ orgType: OrgTypes.INDUSTRY.id, pageSize: 1000 }));
    dispatch(OrganizationListActions.getOrganizationList({ orgType: OrgTypes.AUDIT.id, pageSize: 1000 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(PlantListActions.getPlantList({ pageSize: 1000, orgId: industryOrgId }));
  }, [dispatch, industryOrgId]);

  useEffect(() => {
    if (auditListSuccess) {
      setAppliedFilters(selectedFilters);
      setShowModal(false);
    }
  }, [auditListSuccess]);

  useEffect(() => {
    return () => dispatch(AuditActions.resetAllApiSuccessState());
  }, [dispatch, pageNumber]);

  useEffect(() => {
    setHeaderComponent(<PageHeaderSection />);
  }, []);

  return (
    <div className="page-content">
      {/* audit list page actions section */}
      <AuditListTableActionsSection
        setShowModal={setShowModal}
        authOrgType={authOrgType}
        appliedFilters={appliedFilters}
        setSelectedFilters={setSelectedFilters}
        applyFilters={applyFilters}
      />

      {/* audit list table */}
      <AuditListTable
        items={auditList}
        pagination={auditListPagination}
        loading={auditListLoading}
        showPagination={true}
      />

      <Modal title="Filter" open={showModal} width={600} onCancel={closeModal} footer={null}>
        <AuditFilterForm
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          applyFilters={applyFilters}
        />
      </Modal>
    </div>
  );
}
