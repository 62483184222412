import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.upsertFileSuccess = false;
  state.deleteFileSuccess = {};
}

// Initial State
const initialState = {
  // Get Audit File List
  fileList: [],
  fileListPagination: {},
  fileListLoading: false,
  fileListError: "",

  // Create Audit File
  createFileLoading: false,
  createFileError: {},

  // Update Audit File By Id
  updateFileLoading: false,
  updateFileError: {},

  upsertFileSuccess: false,

  // Download Audit File By Id
  downloadFileLoading: {},
  downloadFileError: {},

  // Delete Audit File By Id
  deleteFileLoading: {},
  deleteFileSuccess: {},
  deleteFileError: {},
};

// Audit File Slice
const auditFileSlice = createSlice({
  name: "AuditFile",
  initialState,
  reducers: {
    // Get Audit File List
    getAuditFileList: (state) => {
      state.fileListLoading = true;
    },

    getAuditFileListSuccess: (state, action) => {
      state.fileListLoading = false;
      state.fileList = action.payload.fileList;
      state.fileListPagination = action.payload.fileListPagination;
    },

    getAuditFileListFailure: (state, action) => {
      state.fileListLoading = false;
      state.fileListError = action.payload.error;
    },

    // Create Audit File
    createAuditFile: (state) => {
      state.upsertFileSuccess = false;
      state.createFileLoading = true;
    },

    createAuditFileSuccess: (state) => {
      state.upsertFileSuccess = true;
      state.createFileLoading = false;
    },

    createAuditFileFailure: (state, action) => {
      state.createFileLoading = false;
      state.createFileError = action.payload.error;
    },

    // Update Audit File By Id
    updateAuditFileById: (state) => {
      state.upsertFileSuccess = false;
      state.updateFileLoading = true;
    },

    updateAuditFileByIdSuccess: (state) => {
      state.upsertFileSuccess = true;
      state.updateFileLoading = false;
    },

    updateAuditFileByIdFailure: (state, action) => {
      state.updateFileLoading = false;
      state.updateFileError = action.payload.error;
    },

    // Download Audit File By Id
    downloadAuditFileById: (state, action) => {
      const { fileId = "" } = action.payload;
      state.downloadFileLoading[fileId] = true;
      state.downloadFileError = "";
    },

    downloadAuditFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;
      delete state.downloadFileLoading[fileId];
    },

    downloadAuditFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;
      delete state.downloadFileLoading[fileId];
      state.downloadFileError = error;
    },

    // Delete Audit File By Id
    deleteAuditFileById: (state, action) => {
      const { fileId = "" } = action.payload;
      state.deleteFileSuccess[fileId] = false;
      state.deleteFileLoading[fileId] = true;
      state.deleteFileError = "";
    },

    deleteAuditFileByIdSuccess: (state, action) => {
      const { fileId = "" } = action.payload;
      state.deleteFileSuccess[fileId] = true;
      delete state.deleteFileLoading[fileId];
    },

    deleteAuditFileByIdFailure: (state, action) => {
      const { fileId = "", error = {} } = action.payload;
      delete state.deleteFileLoading[fileId];
      state.deleteFileError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const AuditFileReducer = auditFileSlice.reducer;

// Actions
export const AuditFileActions = auditFileSlice.actions;
