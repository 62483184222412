import { all, put, takeLatest } from "redux-saga/effects";

// Actions
import { PlantElectricityBillActions } from "../../redux-slice/plant/ElectricityBillSlice";
import { ErrorActions } from "../../app/error/ErrorSlice";

// Utils
import { saveFile } from "../../utils/FileUtils";

// APIs
import {
  createPlantElectricityBillApi,
  downloadPlantElectricityBillByIdApi,
  getPlantElectricityBillListByIdApi,
  updatePlantElectricityBillByIdApi,
  uploadPlantElectricityBillByIdApi,
} from "../../api/plant/ElectricityBillAPI";

// Get Plant Electricity Bill List By Id
function* getPlantElectricityBillListById(action) {
  try {
    const { pageNumber, pageSize, searchText, plantId } = action.payload;

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId, pageNumber, pageSize, searchText);

    // Dispatching Action
    yield put(
      PlantElectricityBillActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );
  } catch (error) {
    // Show error toaster or redirect to error page
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.getPlantElectricityBillListFailure({ error }));
  }
}

// Create Plant Electricity Bill
function* createPlantElectricityBill(action) {
  try {
    const { formData, plantId } = action.payload;

    yield createPlantElectricityBillApi(formData, plantId);

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId);

    yield put(
      PlantElectricityBillActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );

    yield put(PlantElectricityBillActions.createPlantElectricityBillSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.createPlantElectricityBillFailure({ error }));
  }
}

// Update Plant Electricity Bill
function* updatePlantElectricityBillById(action) {
  try {
    const { formData, plantId, electricityBillId } = action.payload;

    yield updatePlantElectricityBillByIdApi(formData, plantId, electricityBillId);

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId);

    yield put(
      PlantElectricityBillActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );

    yield put(PlantElectricityBillActions.updatePlantElectricityBillSuccess());
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.updatePlantElectricityBillFailure({ error }));
  }
}

// Upload Plant Electricity Bill By Id
function* uploadPlantElectricityBillById(action) {
  const { formData, plantId, electricityBillId } = action.payload;

  try {
    yield uploadPlantElectricityBillByIdApi(formData, plantId, electricityBillId);

    const { items, pagination } = yield getPlantElectricityBillListByIdApi(plantId);

    yield put(
      PlantElectricityBillActions.getPlantElectricityBillListSuccess({
        plantElectricityBillList: items,
        plantElectricityBillListPagination: pagination,
      })
    );

    yield put(PlantElectricityBillActions.uploadPlantElectricityBillSuccess({ electricityBillId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.uploadPlantElectricityBillFailure({ electricityBillId, error }));
  }
}

// Download Plant Electricity Bill By Id
function* downloadPlantElectricityBillById(action) {
  const { apiUrl = "", electricityBillId = "" } = action.payload;

  try {
    const data = yield downloadPlantElectricityBillByIdApi(apiUrl);

    saveFile("Electricity Bill", data);

    yield put(PlantElectricityBillActions.downloadPlantElectricityBillSuccess({ electricityBillId }));
  } catch (error) {
    yield put(ErrorActions.setErrorInfo({ errorInfo: error, showToaster: true }));

    yield put(PlantElectricityBillActions.downloadPlantElectricityBillFailure({ electricityBillId, error }));
  }
}

export default function* root() {
  yield all([
    takeLatest(PlantElectricityBillActions.getPlantElectricityBillList.type, getPlantElectricityBillListById),
    takeLatest(PlantElectricityBillActions.createPlantElectricityBill.type, createPlantElectricityBill),
    takeLatest(PlantElectricityBillActions.updatePlantElectricityBill.type, updatePlantElectricityBillById),
    takeLatest(PlantElectricityBillActions.uploadPlantElectricityBill.type, uploadPlantElectricityBillById),
    takeLatest(PlantElectricityBillActions.downloadPlantElectricityBill.type, downloadPlantElectricityBillById),
  ]);
}
