import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.addPlantComponentPartSuccess = false;
  state.updatePlantComponentPartSuccess = false;
  state.deletePlantComponentPartSuccess = {};
}

// Initial Slice
const initialState = {
  // Add plant component part
  addPlantComponentPartLoading: false,
  addPlantComponentPartSuccess: false,
  addPlantComponentPartError: "",

  // Update plant component part
  updatePlantComponentPartLoading: false,
  updatePlantComponentPartSuccess: false,
  updatePlantComponentPartError: "",

  // Delete plant component part
  deletePlantComponentPartLoading: {},
  deletePlantComponentPartSuccess: {},
  deletePlantComponentPartError: "",
};

// Plant Component Parts
const plantComponentParts = createSlice({
  name: "PlantComponentParts",
  initialState,
  reducers: {
    // Add plant component part
    addPlantComponentPart: (state) => {
      state.addPlantComponentPartSuccess = false;
      state.addPlantComponentPartLoading = true;
    },

    addPlantComponentPartSuccess: (state) => {
      state.addPlantComponentPartSuccess = true;
      state.addPlantComponentPartLoading = false;
    },

    addPlantComponentPartFailure: (state, action) => {
      state.addPlantComponentPartLoading = false;
      state.addPlantComponentPartError = action.payload.error;
    },

    // Update plant component part
    updatePlantComponentPart: (state) => {
      state.updatePlantComponentPartSuccess = false;
      state.updatePlantComponentPartLoading = true;
    },

    updatePlantComponentPartSuccess: (state) => {
      state.updatePlantComponentPartSuccess = true;
      state.updatePlantComponentPartLoading = false;
    },

    updatePlantComponentPartFailure: (state, action) => {
      state.updatePlantComponentPartLoading = false;
      state.updatePlantComponentPartError = action.payload.error;
    },

    // Delete plant component part
    deletePlantComponentPart: (state, action) => {
      const { partId } = action.payload;
      state.deletePlantComponentPartSuccess[partId] = false;
      state.deletePlantComponentPartLoading[partId] = true;
      state.deletePlantComponentPartError = "";
    },

    deletePlantComponentPartSuccess: (state, action) => {
      const { partId } = action.payload;
      state.deletePlantComponentPartSuccess[partId] = true;
      delete state.deletePlantComponentPartLoading[partId];
    },

    deletePlantComponentPartFailure: (state, action) => {
      const { partId } = action.payload;
      delete state.deletePlantComponentPartLoading[partId];
      state.deletePlantComponentPartError = action.payload.error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const PlantComponentPartsReducer = plantComponentParts.reducer;

// Actions
export const PlantComponentPartsActions = plantComponentParts.actions;
