import { createSlice } from "@reduxjs/toolkit";

function resetApiSuccessState(state) {
  state.updateMeasurementTypeStatusSuccessMap = {};
}

// Initial Slice
const initialState = {
  // Get Measurement type information
  measurementTypeInfo: {},
  measurementTypeInfoLoading: false,
  measurementTypeInfoError: "",

  // Update Measurement Type Status (Enable/Disable)
  updateMeasurementTypeStatusLoadingMap: {},
  updateMeasurementTypeStatusSuccessMap: {},
  updateMeasurementTypeStatusError: "",
};

// Measurement Type Slice
const measurementTypeSlice = createSlice({
  name: "MeasurementType",
  initialState,
  reducers: {
    // Get Measurement Info
    getMeasurementTypeInfo: (state) => {
      state.measurementTypeInfoLoading = true;
    },

    getMeasurementTypeInfoSuccess: (state, action) => {
      state.measurementTypeInfoLoading = false;
      state.measurementTypeInfo = action.payload.measurementTypeInfo;
    },

    getMeasurementTypeInfoFailure: (state, action) => {
      state.measurementTypeInfoLoading = false;
      state.measurementTypeInfoError = action.payload.error;
    },

    // Update Measurement Type Status (Enable/Disable)
    updateMeasurementTypeStatus: (state, action) => {
      const { measurementTypeId } = action.payload;
      state.updateMeasurementTypeStatusSuccessMap[measurementTypeId] = false;
      state.updateMeasurementTypeStatusLoadingMap[measurementTypeId] = true;
      state.updateMeasurementTypeStatusError = "";
    },

    updateMeasurementTypeStatusSuccess: (state, action) => {
      const { measurementTypeId } = action.payload;
      state.updateMeasurementTypeStatusSuccessMap[measurementTypeId] = true;
      delete state.updateMeasurementTypeStatusLoadingMap[measurementTypeId];
    },

    updateMeasurementTypeStatusFailure: (state, action) => {
      const { measurementTypeId, error } = action.payload;
      delete state.updateMeasurementTypeStatusLoadingMap[measurementTypeId];
      state.updateMeasurementTypeStatusError = error;
    },

    // Reset All API Success States
    resetAllApiSuccessState: (state) => {
      resetApiSuccessState(state);
    },
  },
});

// Reducer
export const MeasurementTypeReducer = measurementTypeSlice.reducer;

// Actions
export const MeasurementTypeActions = measurementTypeSlice.actions;
